import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL } from "firebase/storage";
import '../../style/VideoUploader.css'
import AlertStack from '../helpers/AlertStack';

const FileSelector = (props) => {
  const { lesson, changeLessonValue } = props;
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      const storage = getStorage();
      const filesRef = ref(storage, 'videos'); // Adjust the path as needed

      try {
        const res = await listAll(filesRef);
        setFiles(res.items);
        console.log('all files: ', res.items);
        console.log('all files: ', files.map(file => file.name));
      } catch (error) {
        console.error("Error fetching files: ", error);
      }
    };

    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmSelection = async () => {
    try {
      const url = await getDownloadURL(selectedFile);

      const newLesson = JSON.parse(JSON.stringify(lesson)); // deep copy
      console.log(newLesson);
      newLesson[1].src = url;

      changeLessonValue(Object.values(newLesson));

      setIsModalOpen(false); // Close the modal after selecting a file
    } catch (error) {
      console.error("Error fetching file URL: ", error);
    }
  };

  const handleFileClick = (fileRef) => {
    setSelectedFile(fileRef);
  };

  return (
    <div>
      <button className='upload-button' onClick={() => setIsModalOpen(true)}>Select Video</button>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <div className="file-grid">
              {files.map(fileRef => (
                <div
                  key={fileRef.name}
                  className={`file-card ${selectedFile === fileRef ? 'selected' : ''}`}
                  onClick={() => handleFileClick(fileRef)}
                >
                  {fileRef.name}
                </div>
              ))}
            </div>
            <button
              className="confirm-button"
              onClick={handleConfirmSelection}
              disabled={!selectedFile}
            >
              Confirm Selection
            </button>
          </div>
        </div>
      )}

      {/* {selectedFileUrl && <video src={selectedFileUrl} controls />} */}
    </div>
  );
};

function VideoUploader({ lesson, changeLessonValue }) {
  console.log('lesson when passed to video uploader: ', lesson);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  // Error state is handled here
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());

  const triggerNewErrorMessage = (newMessage) => {
    setTimestamp(Date.now());
    setErrorMessage(newMessage);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  const triggerNewSuccessMessage = (newMessage) => {
    setTimestamp(Date.now());
    setSuccessMessage(newMessage);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setUploading(true);

      const storage = getStorage();
      const storageRef = ref(storage, `videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Handle progress
        },
        (error) => {
          triggerNewErrorMessage('Failed to upload video. Please contact support if this error persists.');
          setUploading(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // const newLesson = {...lesson}; // shallow copy
            const newLesson = JSON.parse(JSON.stringify(lesson)); // deep copy
            newLesson[1].src = downloadURL;

            changeLessonValue(Object.values(newLesson));
            triggerNewSuccessMessage('Video uploaded successfully.');
          } catch (err) {
            triggerNewErrorMessage('Failed to retrieve download URL. Please contact support.');
          }

          setUploading(false);
        }
      );
    } catch (error) {
      console.error(error);
      triggerNewErrorMessage('An unexpected error occurred.');
      setUploading(false);
    }
  };

  return (
    <div>
      <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
      <input type="file" accept=".mp4, .mov" onChange={handleFileChange} />
      <button className='upload-button' onClick={handleUpload} disabled={!file || uploading}>
        {uploading ? 'Uploading...' : 'Upload Video'}
      </button>
      <FileSelector lesson={lesson} changeLessonValue={changeLessonValue} />
    </div>
  );
}

export default VideoUploader;
