import React, { useContext, useState } from 'react';
import UpdateCardComponent from './UpdateCardComponent';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../../style/PaymentComponent.css'; // Ensure you have appropriate styling
import LoggedInContext from '../helpers/LoggedInContext';
import AvatarSelect from './AvatarSelect';
import AlertStack from '../helpers/AlertStack';

// firebase
import { doc, updateDoc } from "@firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function AccountPage() {
    const { userRole, setAvatar } = useContext(LoggedInContext);
    const [selectedAvatar, setSelectedAvatar] = useState(null);


    // Error state is handled here
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());

    const triggerNewErrorMessage = (newMessage) => {
        setTimestamp(Date.now());
        setErrorMessage(newMessage);
        setTimeout(() => {
            setErrorMessage(null);
        }, 5000);
    };

    const triggerNewSuccessMessage = (newMessage) => {
        setTimestamp(Date.now());
        setSuccessMessage(newMessage);
        setTimeout(() => {
            setSuccessMessage(null);
        }, 5000);
    };

    const handleAvatarSelect = (avatar) => {
        setSelectedAvatar(avatar);
    };

    const saveUserState = async () => {
        // data to save
        const userData = { avatar: selectedAvatar };

        const user = auth.currentUser;

        if (!user) {
            triggerNewErrorMessage('Error finding user account. Please relogin.');
            return;
        }
    
        const userUID = user.uid;

        if (!userUID) {
            triggerNewErrorMessage('Error finding user ID. Please relogin.');
            return;
        }

        try {
            await updateDoc(doc(firestore, "users", userUID), userData);
            // This code will run after the document has been successfully set in Firestore
            setAvatar(selectedAvatar);
            triggerNewSuccessMessage('Successfully updated account details.')
        } catch (error) {
            // TODO handle errors here!
            triggerNewErrorMessage('Error updating account details.')
            console.error("Error setting document:", error);
        }
    }

    return (
        <div className='account-container'>
            <AlertStack successMessage={successMessage} errorMessage={errorMessage}  timestamp={timestamp}/>
            <h1 className="main-header">Account Settings</h1>

            {userRole === 'parent' && (
                <div className='parents-section'>
                    <Elements stripe={stripePromise}>
                        <UpdateCardComponent />
                    </Elements>
                </div>
            )}

            {/* <div class="wave-group">
                <h4>Name:</h4>
                <input
                    type="text"
                    className="input"
                    // value={name}
                    onChange={handleNameChange}
                />
                <span class="bar"></span>
            </div> */}

            <div className='avatar-container'>
                <div className="main-header-2">Select your avatar</div>
                {/* AvatarSelect should be a component allowing user to select an avatar */}
                {/* handleAvatarSelect is a function that handles avatar selection */}
                {/* Ensure you import or create AvatarSelect component */}
                <AvatarSelect onSelect={handleAvatarSelect} />
                <button className='confirm-button' onClick={saveUserState}>Save Changes</button>
            </div>

        </div>
    );
}

export default AccountPage;
