import React, { useEffect, useState } from 'react';
import './../../style/Subjects.css';
import Subject from './subject/Subject';

import { collection, getDocs, query, where } from "@firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase';

const Subjects = () => {
  const [subjects, setSubjects] = useState([]);

  const docToJSON = (doc) => {
    // function to convert docs from firestore to JSON
    if (!doc.exists()) return null;

    const data = doc.data();
    return {
      [doc.id]: data,
    };
  };

  useEffect(() => {
    // on component mount, we pull in the subject data into our app
    const fetchSubjectsAndSubscriptions = async () => {
      try {
        // Fetching subjects
        const querySnapshot = await getDocs(collection(firestore, "subjects"));
        let subjectsList = [];

        querySnapshot.forEach((doc) => {
          const subjectJSON = docToJSON(doc);
          if (subjectJSON) {
            subjectsList.push(subjectJSON);
          }
        });

        // Fetching active subscriptions
        const userUID = auth.currentUser?.uid;
        const subscriptionsQuery = query(
          collection(firestore, 'subscriptions'),
          where('student_id', '==', userUID),
          where('active', '==', true)
        );
        const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
        const activeSubscriptionsSet = new Set(subscriptionsSnapshot.docs.map(doc => doc.data().subject_id));
        // Adding 'subscribed' flag to subjects
        subjectsList = subjectsList.map(subject => ({
          ...subject,
          subscribed: activeSubscriptionsSet.has(subject.id)
        }));

        setSubjects(subjectsList);
      } catch (error) {
        console.error('Error combining subject data and subscription status: ', error);
      }
    };

    fetchSubjectsAndSubscriptions();
  }, []);

  return (
    <div>
      <main>
        <section >
          <h1 className='main-header'>Our Subjects</h1>
          {subjects && (
            <div>
              {Object.entries(subjects).map(([id, subject]) => (
                <Subject key={id} subject={subject} />
              ))}
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Subjects;
