import { React, useState, useEffect } from 'react';
import StudentProgress from "./StudentProgress";
import { auth, firestore } from '../../firebase_setup/firebase';
import { doc, getDocs, getDoc, collection, query, where } from 'firebase/firestore';
// import LoggedInContext from '../helpers/LoggedInContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ComponentLoadingScreen from "../helpers/ComponentLoadingScreen";

// import ScoreChart from './ScoreChart';

const AdminStudentOverview = () => {
    // const { name } = useContext(LoggedInContext);
    const [studentAccounts, setStudentAccounts] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [componentLoading, setComponentLoading] = useState(true);

    const handlePreviousClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < studentAccounts.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleRowClick = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const fetchStudentAccounts = async () => {
            try {
                const userUID = auth.currentUser?.uid;
                if (!userUID) return;

                // First, retrieve the currently selected user's document.
                const usersCollectionRef = collection(firestore, 'users');
                const queryRef = query(usersCollectionRef, where('role', '==', 'student'));

                const querySnapshot = await getDocs(queryRef);

                if (querySnapshot.empty) {
                    console.error('No student accounts.');
                    return;
                }

                // Collect linkedAccount UIDs from the query result.
                const studentAccountUIDs = querySnapshot.docs.map(doc => doc.id);
                console.log('studentAccountUIDs: ', studentAccountUIDs);

                // Retrieve details for each linked account.
                const studentAccountsData = [];

                for (const studentAccountUID of studentAccountUIDs) {
                    const studentAccountDocRef = doc(firestore, 'users', studentAccountUID);
                    const studentAccountDocSnapshot = await getDoc(studentAccountDocRef);
                
                    if (!studentAccountDocSnapshot.exists()) {
                        console.error(`student account document not found for UID: ${studentAccountUID}`);
                        continue;
                    }
                
                    // Query the subscriptions DB for the current student.
                    const subscriptionsCollectionRef = collection(firestore, 'subscriptions');
                    const subscriptionsQuery = query(subscriptionsCollectionRef, where('student_id', '==', studentAccountUID));
                    const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
                
                    // Iterate over each subscription to get the subject_id and then query the subjects collection.
                    const subscriptionDetails = [];
                    for (const subscriptionDoc of subscriptionsSnapshot.docs) {
                        const subjectId = subscriptionDoc.data().subject_id;
                
                        // Query the subjects collection to get the subject name.
                        const subjectDocRef = doc(firestore, 'subjects', subjectId);
                        const subjectDocSnapshot = await getDoc(subjectDocRef);
                
                        if (subjectDocSnapshot.exists()) {
                            subscriptionDetails.push(subjectDocSnapshot.data().name); // Assuming the subject's name is stored under the 'name' field.
                        } else {
                            console.error(`Subject not found for ID: ${subjectId}`);
                        }
                    }
                
                    // Add the student account and their subscription details to the array.
                    studentAccountsData.push({
                        accountId: studentAccountDocSnapshot.id,
                        name: studentAccountDocSnapshot.data().name,
                        // Include the subscription details which now contain the subject names.
                        subscriptions: subscriptionDetails
                    });
                }

                setStudentAccounts(studentAccountsData);

            } catch (error) {
                console.error('Error fetching linked accounts:', error);
            } finally {
                setComponentLoading(false);
            }
        };

        fetchStudentAccounts();
    }, []);

    const selectorStyle = {
        position: 'fixed',   /* Keeps the div in a fixed position */
        top: '7.5%',          /* Moves the top edge of the div to the center of the viewport */
        left: '50%',         /* Moves the left edge of the div to the center of the viewport */
        transform: 'translate(-50%, -50%)', /* Shifts the div back by half of its own width and height, ensuring it's centered */
        zIndex: '1000'
    }

    const backStyle = {
        position: 'fixed',   /* Keeps the div in a fixed position */
        top: '5%',          /* Moves the top edge of the div to the center of the viewport */
        left: '20%',         /* Moves the left edge of the div to the center of the viewport */
        transform: 'translate(-50%, -50%)', /* Shifts the div back by half of its own width and height, ensuring it's centered */
        zIndex: '1000'
    }


    if (componentLoading) {
        return (<ComponentLoadingScreen />)
    }

    if (!studentAccounts || studentAccounts.length === 0) {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <h2 className='main-header'>No Student Accounts Found</h2>
                <p className='main-text'>Could not find any student accounts.</p>
                {/* You can also add a button/link here to redirect the user to a page or open a modal where they can link accounts. */}
            </div>
        );
    }

    console.log(studentAccounts);

    if (currentIndex === null) {
        return (
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Account ID</th>
                            <th>Name</th>
                            <th>Subscriptions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentAccounts && studentAccounts.map((student, index) => (
                            <tr
                                key={student.accountId}
                                className="clickable-row card-row" /* Add 'card-row' class here */
                                onClick={() => handleRowClick(index)}
                            >
                                <td>{student.accountId}</td>
                                <td>{student.name}</td>
                                <td>{student.subscriptions.length > 0 ? student.subscriptions.join(', ') : 'None'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    // TODO update this so that we can have an outer layer which will allow us to switch between the linked accounts
    return (
        <div>
            {studentAccounts && <>
                <div style={backStyle}>
                    <ArrowBackIcon onClick={() => setCurrentIndex(null)} style={{ cursor: 'pointer', color: 'black' }}></ArrowBackIcon>
                </div>
                <div style={selectorStyle}>
                    <ArrowBackIcon onClick={handlePreviousClick} style={{ cursor: 'pointer', color: 'grey', marginRight: '7.5px' }}></ArrowBackIcon>

                    <span className='main-header'>{studentAccounts[currentIndex].name}</span>

                    <ArrowForwardIcon onClick={handleNextClick} style={{ cursor: 'pointer', color: 'grey', marginLeft: '7.5px' }}></ArrowForwardIcon>
                </div>

                <StudentProgress
                    key={studentAccounts[currentIndex].accountId}
                    studentId={studentAccounts[currentIndex].accountId}
                    studentName={studentAccounts[currentIndex].name}
                />
            </>}
        </div>
        // TODO add link new accounts here
    );
};

export default AdminStudentOverview;
