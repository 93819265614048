// FirebaseUI
import { doc, getDoc } from "@firebase/firestore";
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { firestore } from '../../firebase_setup/firebase';
import '../../style/LoginPage.css';

// React stuff
import { useContext, useEffect } from 'react';

// Auth service
import { auth } from '../../firebase_setup/firebase';

// LoggedInContext
import LoggedInContext from '../helpers/LoggedInContext'; // Make sure to import the context

const LoginPage = () => {
    const { setIsLoggedIn, setUserRole, setUserUID } = useContext(LoggedInContext); // Get the setIsLoggedIn and setUserRole functions from the context

    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

        ui.start('#firebaseui-auth-container', {
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    // Action if the user is authenticated successfully
                    setUserUID(authResult.user.uid);

                    const loadUser = async () => {
                        const userDocRef = doc(firestore, 'users', authResult.user.uid);
                        const userSnapshot = await getDoc(userDocRef);

                        if (userSnapshot.exists()) {
                            // TODO ensure this works, first we will need to go through our 
                            // let data = userSnapshot.data();
                            setUserRole(userSnapshot.get('role'));
                            setIsLoggedIn(true); // Set the isLoggedIn state to true
                        } else {
                            // User document not found
                            setUserRole(null);
                            setIsLoggedIn(true); // Set the isLoggedIn state to true
                        }
                    }

                    try {
                        loadUser();
                    } catch (error) {
                        // TODO display error to login page if this happens
                        console.error('Error fetching user data:', error);
                        setUserRole(null);
                        setIsLoggedIn(false);
                    }
                    return false;
                },
                uiShown: function () {
                    // This is what should happen when the form is fully loaded. In this example, I hide the loader element.
                    const loader = document.getElementById('loader');
                    if (loader) {
                        loader.style.display = 'none';
                    }
                },
            },
            // signInSuccessUrl: 'https://google.com', // This is where should redirect if the sign in is successful.
            signInOptions: [{
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false
            }],
            tosUrl: 'https://www.example.com/terms-conditions', // URL to your terms and conditions.
            privacyPolicyUrl: function () {
                // URL to your privacy policy
                window.location.assign('https://www.example.com/privacy-policy');
            },
            
        });
        
    }, [setIsLoggedIn, setUserRole, setUserUID]); // Add setIsLoggedIn, setUserRole to the dependency array to avoid any warnings

    return (
        <div className='login-container'>
            <h1 className="login-header">Login or Register</h1>
            <div id="firebaseui-auth-container"></div>
            <div id="loader" className="text-center">
                Loading form
            </div>
        </div>
    );
};

export default LoginPage;


/*
// FirebaseUI
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// React stuff
import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

// Auth service
import { auth } from '../../firebase_setup/firebase'

// LoggedInContext
import LoggedInContext from '../helpers/LoggedInContext';

const HOST = 'http://127.0.0.1:3000'

const LoginPage = () => {
    const { setIsLoggedIn } = useContext(LoggedInContext);

    useEffect(() => {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
  
      ui.start('#firebaseui-auth-container', {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // Action if the user is authenticated successfully
            setIsLoggedIn(true);
          },
          uiShown: function () {
            // This is what should happen when the form is fully loaded. In this example, I hide the loader element.
            const loader = document.getElementById('loader');
            if (loader) {
              loader.style.display = 'none';
            }
          },
        },
        signInSuccessUrl: 'http://127.0.0.1:3000/dashboard', // This is where should redirect if the sign in is successful.
        signInOptions: [
          // This array contains all the ways a user can authenticate in your application. For this example, is only by email.
        //   {
        //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        //     requireDisplayName: true,
        //     disableSignUp: {
        //       status: true,
        //     },
            
        //   },
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // add google signup/signon
        // {
        //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID, // Example: Adding Google sign-in
        //   scopes: ['https://www.googleapis.com/auth/plus.login'], // Additional scopes for Google sign-in
        //   customParameters: {
        //     // Additional custom parameters for Google sign-in
        //     prompt: 'select_account', // Prompt user to select an account for Google sign-in
        //   },
        // },
        ],
        tosUrl: 'https://www.example.com/terms-conditions', // URL to your terms and conditions.
        privacyPolicyUrl: function () {
          // URL to your privacy policy
          window.location.assign('https://www.example.com/privacy-policy');
        },
      });
    }, []);
  
    return (
      <>
        <h1 className="text-center my-3 title">Login Page</h1>
        <div id="firebaseui-auth-container"></div>
        <div id="loader" className="text-center">
          Loading form
        </div>
      </>
    );
  };
  
  export default LoginPage;

  */