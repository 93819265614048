import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import LoadingScreen from '../helpers/LoadingScreen';
import '../../style/Quiz.css';
import AvatarPopup from '../components/AvatarPopup';
import CalculateIcon from '@mui/icons-material/Calculate';

const Quiz = ({ resetQuiz, setShouldShowLearning, questions, windowSize, saveResults, handleWindowSize, handleNextActivity, type, showLearning }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [userAnswer, setUserAnswer] = useState('')
    const [showResult, setShowResult] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [userResponses, setUserResponses] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [finalScreen, setFinalScreen] = useState(false);
    const [failed, setFailed] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [specialCharsVisible, setSpecialCharsVisible] = useState(false);


    const toggleCalculator = () => {
        setSpecialCharsVisible(!specialCharsVisible);
    };

    const showFailedMessage = (results) => {
        if (results < 80 && type !== 'learningExperience') {
            if (type === 'endOfWeek') {
                setPopupText(`
                    Almost There! \n\n
                    
                    Instead of racing ahead, I think we would benefit from doing this quiz again (with some new questions of course). \n\n
                    
                    I am tired and to be honest you’re going far too fast for me, give me a second to catch my breath whilst you give it another go. \n\n
                    
                    But before you do, it might be a good idea to have a super quick recap of what we have learnt this week.
                `);
            } else if (type === 'endOfModule') {
                setPopupText(`
                    Good job on making it this far \n\n
                    
                    Whilst we could move on … I think we should take another look at this module. \n\n
                    
                    The content in this module forms the basis for how we ‘beat’ maths GCSE. \n\n
                    
                    So, let’s take another look so we can get the information locked in before giving this quiz another go.\n\n
                    
                    Not to mention, it took me 1,468 tries to pass this quiz so you can give it one more!
                `);
            }
            setShowPopup(true);
        }
    };


    const showPassedMessage = (results) => {
        if (results >= 80 && type !== 'learningExperience') {
            if (type === 'endOfWeek') {
                setPopupText(`
                    Congratulations, you've passed this weeks quiz! Well done for all your hard work.
                `);
            } else if (type === 'endOfModule') {
                setPopupText(`
                    Awesome work! You've completed a module! That's a serious chunk of the course done, you should be proud of yourself!
                `);
            }
            setShowPopup(true);
        }
    };

    // set current question
    const currentQuestion = questions[currentQuestionIndex];

    useEffect(() => {
        const handleSpacebarPress = (event) => {
            if (event.key === ' ') {
                if (showResult) {
                    handleNextQuestion();
                }
            }
        };

        window.addEventListener('keydown', handleSpacebarPress);

        return () => {
            window.removeEventListener('keydown', handleSpacebarPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showResult]);

    const handleArrowKeyPress = (event) => {
        if (currentQuestion.type !== 'multi') {
            return;
        }
        let selectedIndex;
        switch (event.key) {
            case 'ArrowUp':
                selectedIndex = 0;  // Corresponds to currentQuestion.options.slice(0, 1)
                break;
            case 'ArrowDown':
                // this is left
                selectedIndex = 3;  // Corresponds to currentQuestion.options.slice(1, 2)
                break;
            case 'ArrowLeft':
                selectedIndex = 1;  // Corresponds to currentQuestion.options.slice(2, 3)
                break;
            case 'ArrowRight':
                selectedIndex = 2;  // Corresponds to currentQuestion.options.slice(3, 4)
                break;
            // case ' ':  // for older browsers
            //     console.log('spacebar');
            //     console.log(showResult);
            //     if (showResult) {
            //         handleNextQuestion();
            //     }
            //     return;  // Exit the function early
            default:
                return;  // Exit the function early
        }
        const optionToSelect = currentQuestion.options[selectedIndex];
        console.log('selectedOption: ', selectedOption);
        if (optionToSelect && !selectedOption) {
            handleOptionSelect(optionToSelect);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleArrowKeyPress);
        return () => {
            window.removeEventListener('keydown', handleArrowKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion]);  // Recreate the event listener if currentQuestion changes

    const calculateScore = () => {
        let score = 0;
        userResponses.forEach((response) => {
            if (response && response.isCorrect) {
                score += 1;
            }
        })
        return score;
    };

    const renderResults = () => {
        // TODO we need to do something if the user passes the quiz! 
        // find the code for a fail, and then modify it such that we return different code for a pass
        if (failed) {
            // TODO Here we return two buttons - one called learn and one called retry quiz
            return (
                <AnimatePresence>
                    <motion.div
                        key={currentQuestion.id}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="quiz-container fill-screen">
                            <div className='result-card'>
                                <button className='quiz-button' onClick={() => setShouldShowLearning(true)}>Learn</button>
                                <button className='quiz-button' onClick={() => resetQuiz()}>Retry Quiz</button>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            )
        }
        const score = calculateScore();
        const maxScore = questions.length;
        const percentage = Math.round((score / maxScore) * 100);
        let emoji = '😢'; // Default emoji for scores below 20%

        if (percentage >= 20) {
            emoji = '😄'; // Emoji for scores between 20% and 39%
        }

        if (percentage >= 40) {
            emoji = '🎉'; // Emoji for scores between 40% and 59%
        }

        if (percentage >= 60) {
            emoji = '👏'; // Emoji for scores between 60% and 79%
        }

        if (percentage >= 80) {
            emoji = '🌟'; // Emoji for scores between 80% and 100%
        }

        return (
            <AnimatePresence>
                <motion.div
                    key={currentQuestion.id}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="quiz-container fill-screen">
                        {/* <h1 className="main-header">Quiz Results</h1> */}
                        <div className="result-card">
                            <div className="result-card-content">
                                {type === 'endOfWeekQuiz' || type === 'endOfModuleQuiz' ? <p>{type === 'endOfWeekQuiz' ? 'Week Passed' : 'Module Passed'}</p> : null}
                                <p className="score">Your score: {score}/{maxScore}</p>
                                <p className="percentage">Percentage: {percentage}%</p>
                                <div className="result-emoji">{emoji}</div>
                            </div>
                            <button className='quiz-button' onClick={handleNextActivity}>Continue</button>
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        );
    };


    const handleOptionSelect = (option) => {
        setSelectedOption(Object.keys(option)[0]);
        setShowResult(true);
        setIsCorrect(Object.keys(option)[0] === 'correctAnswer');
        setShowConfetti(Object.keys(option)[0] === 'correctAnswer');
        recordResponse(Object.keys(option)[0] === 'correctAnswer');
        setAnsweredQuestions((prevAnsweredQuestions) => [...prevAnsweredQuestions, currentQuestionIndex]);
    };

    const indexCharacters = ["⁰", "¹", "²", "³", "⁴", "⁵", "⁶", "⁷", "⁸", "⁹",];
    const specialCharacters = ["π", "√"];

    const handleCharacterClick = (character) => {
        setUserAnswer((prevAnswer) => prevAnswer + character);
    };


    const handleUserAnswerChange = (event) => {
        setUserAnswer(event.target.value);
    };

    const handleSubmit = () => {
        let correctAnswer = null;
        // Find the option with the correctAnswer property
        if (currentQuestion.type === 'multi') {
            const correctAnswerOption = currentQuestion.options.find(option => option.correctAnswer !== undefined);

            // Extract the correctAnswer value and convert it to a string
            correctAnswer = correctAnswerOption ? String(correctAnswerOption.correctAnswer) : '';
        } else {
            // Convert the correct_answer to a string
            correctAnswer = String(currentQuestion.correct_answer);
        }

        // Convert correctAnswer to lowercase and replace units
        correctAnswer = correctAnswer.toLowerCase();
        correctAnswer = correctAnswer.replace(/(\s)mg(\s|$)/g, '$1milligrams$2');
        correctAnswer = correctAnswer.replace(/(\s)g(\s|$)/g, '$1grams$2');
        correctAnswer = correctAnswer.replace(/(\s)kg(\s|$)/g, '$1kilograms$2');
        correctAnswer = correctAnswer.replace(/(\s)ml(\s|$)/g, '$1milliliters$2');
        correctAnswer = correctAnswer.replace(/(\s)cl(\s|$)/g, '$1centiliters$2');
        correctAnswer = correctAnswer.replace(/(\s)l(\s|$)/g, '$1liters$2');
        correctAnswer = correctAnswer.replace(/(\s)mm(\s|$)/g, '$1millimeters$2');
        correctAnswer = correctAnswer.replace(/(\s)cm(\s|$)/g, '$1centimeters$2');
        correctAnswer = correctAnswer.replace(/(\s)m(\s|$)/g, '$1meters$2');
        correctAnswer = correctAnswer.replace(/(\s)km(\s|$)/g, '$1kilometers$2');
        correctAnswer = correctAnswer.replace(/(\s)sec(\s|$)/g, '$1seconds$2');
        correctAnswer = correctAnswer.replace(/(\s)min(\s|$)/g, '$1minutes$2');
        correctAnswer = correctAnswer.replace(/(\s)hr(\s|$)/g, '$1hours$2');
        correctAnswer = correctAnswer.replace(/(\s)°c(\s|$)/g, '$1degrees Celsius$2');
        correctAnswer = correctAnswer.replace(/(\s)°f(\s|$)/g, '$1degrees Fahrenheit$2');
        correctAnswer = correctAnswer.replace(/(\s)meters?²(\s|$)/g, '$1m²$2');
        correctAnswer = correctAnswer.replace(/(\s)meters?³(\s|$)/g, '$1m³$2');
        correctAnswer = correctAnswer.replace(/(\s)kilometers?²(\s|$)/g, '$1km²$2');
        correctAnswer = correctAnswer.replace(/(\s)kilometers?³(\s|$)/g, '$1km³$2');
        correctAnswer = correctAnswer.replace(/(\s)centimeters?²(\s|$)/g, '$1cm²$2');
        correctAnswer = correctAnswer.replace(/(\s)centimeters?³(\s|$)/g, '$1cm³$2');
        correctAnswer = correctAnswer.replace(/(\s)millimeters?²(\s|$)/g, '$1mm²$2');
        correctAnswer = correctAnswer.replace(/(\s)millimeters?³(\s|$)/g, '$1mm³$2');
        // Add similar lines for other units as needed

        let tmpUserAnswer = userAnswer;
        tmpUserAnswer = tmpUserAnswer.toLowerCase();
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)mg(\s|$)/g, '$1milligrams$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)g(\s|$)/g, '$1grams$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)kg(\s|$)/g, '$1kilograms$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)ml(\s|$)/g, '$1milliliters$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)cl(\s|$)/g, '$1centiliters$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)l(\s|$)/g, '$1liters$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)mm(\s|$)/g, '$1millimeters$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)cm(\s|$)/g, '$1centimeters$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)m(\s|$)/g, '$1meters$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)km(\s|$)/g, '$1kilometers$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)sec(\s|$)/g, '$1seconds$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)min(\s|$)/g, '$1minutes$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)hr(\s|$)/g, '$1hours$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)°c(\s|$)/g, '$1degrees Celsius$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)°f(\s|$)/g, '$1degrees Fahrenheit$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)meters?²(\s|$)/g, '$1m²$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)meters?³(\s|$)/g, '$1m³$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)kilometers?²(\s|$)/g, '$1km²$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)kilometers?³(\s|$)/g, '$1km³$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)centimeters?²(\s|$)/g, '$1cm²$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)centimeters?³(\s|$)/g, '$1cm³$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)millimeters?²(\s|$)/g, '$1mm²$2');
        tmpUserAnswer = tmpUserAnswer.replace(/(\s)millimeters?³(\s|$)/g, '$1mm³$2');

        setShowResult(true);
        setIsCorrect(tmpUserAnswer.toLowerCase().trim() === correctAnswer.trim());
        setShowConfetti(tmpUserAnswer.toLowerCase().trim() === correctAnswer.trim());
        recordResponse(tmpUserAnswer.toLowerCase().trim() === correctAnswer.trim());
        setAnsweredQuestions((prevAnsweredQuestions) => [...prevAnsweredQuestions, currentQuestionIndex]);
    };

    const handleNextQuestion = () => {
        showConfetti && setShowConfetti(false);
        setSelectedOption(null);
        setUserAnswer('');

        setShowResult(false);
        setIsCorrect(false);

        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questions.length) {
            setCurrentQuestionIndex(nextQuestionIndex);
        } else {
            // here we goto a final screen
            setFinalScreen(true);
            // Cannot fail a learning experience!
            if (type === 'learningExperience') {
                setFailed(false);
            } else {
                // TODO here we need to instead check if we actually failed rather than just failing everyone
                const score = calculateScore();
                const maxScore = questions.length;
                const percentage = Math.round((score / maxScore) * 100);
                if (percentage < 80) {
                    showFailedMessage(percentage);
                    setFailed(true);
                } else {
                    showPassedMessage(percentage);
                    setFailed(false);
                }
            }

        }
    };

    const recordResponse = (isCorrect) => {
        const response = {
            question: currentQuestion.question,
            isCorrect: isCorrect,
            lesson: currentQuestion.lesson_id,
        };

        setUserResponses((prevResponses) => {
            const filteredResponses = prevResponses.filter((item) => item.question !== currentQuestion.question);
            return [...filteredResponses, response];
        });
    };

    const getButtonClassName = (option) => {
        let className = '';
        if (type === 'learningExperience') {
            className = 'quiz-button';
        } else if (type === 'endOfWeek') {
            className = 'week-quiz-button';
        } else if (type === 'endOfModule') {
            className = 'module-quiz-button';
        }


        if (showResult) {
            if (Object.keys(option)[0] === 'correctAnswer') {
                className += ' correct';
            } else {
                className += ' incorrect';
            }

            if (Object.keys(option) === selectedOption) {
                className += ' selected';
            }
        } else {
            if (Object.keys(option) === selectedOption) {
                className += ' selected';
            }
        }
        return className;
    };

    const getNextQButtonClassName = (isCorrect) => {
        if (showResult) {
            if (isCorrect) {
                return `quiz-button ${isCorrect ? 'selected correct' : 'correct'}`;
            } else {
                return `quiz-button selected incorrect`;
            }
        }
        return `quiz-button ${isCorrect ? 'selected' : ''}`;
    };

    useEffect(() => {
        window.onresize = () => handleWindowSize();
        showConfetti &&
            setTimeout(() => {
                setShowConfetti(false);
            }, 5000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showConfetti]);

    const renderProgressBar = () => {
        return (
            <div className="quiz-progress-bar">
                {questions.map((question, index) => (
                    <motion.div
                        key={question.id}
                        layoutId={`quiz-progress-box-${index}`}
                        className={`quiz-progress-box ${answeredQuestions.includes(index) ? (userResponses[index]?.isCorrect ? 'correct' : 'incorrect') : (index === currentQuestionIndex ? 'current' : '')}`}
                    />
                ))}
            </div>
        );
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    if (Array.isArray(questions) && (questions.length === 0)) {
        return <LoadingScreen />
    }
    else if (finalScreen) {
        saveResults(userResponses);
        return (
            <div>
                {renderResults()}
                {showPopup &&
                    <AvatarPopup
                        onClose={handleClosePopup}
                        text={popupText}
                    />
                }
            </div>);
    } else if (currentQuestion.type === 'multi') {
        return (
            <AnimatePresence>
                <div className="quiz-container fill-screen">
                    {showConfetti ? (
                        <Confetti
                            drawShape={(ctx) => {
                                ctx.beginPath();
                                for (let i = 0; i < 22; i++) {
                                    const angle = 0.35 * i;
                                    const x = (0.2 + 1.5 * angle) * Math.cos(angle);
                                    const y = (0.2 + 1.5 * angle) * Math.sin(angle);
                                    ctx.lineTo(x, y);
                                }
                                ctx.stroke();
                                ctx.closePath();
                            }}
                            width={windowSize.width}
                            height={windowSize.height}
                            tweenDuration={5000}
                        />
                    ) : (
                        <div />
                    )}
                    {renderProgressBar()}
                    <motion.div
                        key={currentQuestion.id}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        {/* <h1 className="main-header">Multiple Choice Quiz</h1> */}
                        <div className='top-question-container'>
                            {
                                (() => {
                                    let option = currentQuestion.options.slice(0, 1)[0];

                                    const optionValue = option.alt_answer_1 || option.correctAnswer || option.alt_answer_2 || option.alt_answer_3;

                                    return (
                                        <button
                                            key={Object.keys(option)[0]}
                                            className={getButtonClassName(option)}
                                            onClick={selectedOption ? null : () => handleOptionSelect(option)}
                                        >
                                            {optionValue}
                                        </button>
                                    );
                                })()
                            }
                        </div>
                        <div className='central-question-container'>
                            {
                                (() => {
                                    let option = currentQuestion.options.slice(1, 2)[0];
                                    const optionValue = option.alt_answer_1 || option.correctAnswer || option.alt_answer_2 || option.alt_answer_3;

                                    return (
                                        <button
                                            key={Object.keys(option)[0]}
                                            className={getButtonClassName(option)}
                                            onClick={selectedOption ? null : () => handleOptionSelect(option)}
                                        >
                                            {optionValue}
                                        </button>
                                    );
                                })()
                            }
                            <h1 className="main-header">Question {currentQuestionIndex + 1}: {currentQuestion.question}</h1>
                            {
                                (() => {
                                    let option = currentQuestion.options.slice(2, 3)[0];
                                    const optionValue = option.alt_answer_1 || option.correctAnswer || option.alt_answer_2 || option.alt_answer_3;

                                    return (
                                        <button
                                            key={Object.keys(option)[0]}
                                            className={getButtonClassName(option)}
                                            onClick={selectedOption ? null : () => handleOptionSelect(option)}
                                        >
                                            {optionValue}
                                        </button>
                                    );
                                })()
                            }
                        </div>
                        <div className='bottom-question-container'>
                            {
                                (() => {
                                    let option = currentQuestion.options.slice(3, 4)[0];
                                    const optionValue = option.alt_answer_1 || option.correctAnswer || option.alt_answer_2 || option.alt_answer_3;

                                    return (
                                        <button
                                            key={Object.keys(option)[0]}
                                            className={getButtonClassName(option)}
                                            onClick={selectedOption ? null : () => handleOptionSelect(option)}
                                        >
                                            {optionValue}
                                        </button>
                                    );
                                })()
                            }
                        </div>
                        <div className="options-container">
                            {showResult && (
                                <button className="submit-button" onClick={handleNextQuestion}>
                                    Next Question
                                </button>
                            )}
                        </div>
                    </motion.div>
                </div>
            </AnimatePresence>
        );
    } else if (currentQuestion.type === 'open') {
        return (
            <AnimatePresence>
                <div className="quiz-container fill-screen">
                    {showConfetti ? (
                        <Confetti
                            drawShape={(ctx) => {
                                ctx.beginPath();
                                for (let i = 0; i < 22; i++) {
                                    const angle = 0.35 * i;
                                    const x = (0.2 + 1.5 * angle) * Math.cos(angle);
                                    const y = (0.2 + 1.5 * angle) * Math.sin(angle);
                                    ctx.lineTo(x, y);
                                }
                                ctx.stroke();
                                ctx.closePath();
                            }}
                            width={windowSize.width}
                            height={windowSize.height}
                            tweenDuration={5000}
                        />
                    ) : (
                        <div />
                    )}
                    {renderProgressBar()}
                    <motion.div
                        key={currentQuestion.id}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        {/* <h1 className="main-header">Open-Ended Quiz</h1> */}
                        <h1 className="main-header">Question {currentQuestionIndex + 1}: {currentQuestion.question}</h1>

                        <div className="options-container">
                            <input
                                type="text"
                                value={userAnswer}
                                onChange={handleUserAnswerChange}
                                className="open-input"
                                placeholder="Enter your answer..."
                            />
                        </div>

                        {/* <div className="special-characters-container">
                            {indexCharacters.map((char, index) => (
                                <button className='mini-button' key={index} onClick={() => handleCharacterClick(char)}>
                                    x{char}
                                </button>
                            ))}
                            {specialCharacters.map((char, index) => (
                                <button className='mini-button' key={index} onClick={() => handleCharacterClick(char)}>
                                    {char}
                                </button>
                            ))}
                        </div> */}




                        <div id="popupContainer" className={`popup-container ${specialCharsVisible ? 'popup-visible' : ''}`}>
                            <div className="special-characters-container">
                                {indexCharacters.map((char, index) => (
                                    <button className='mini-button' key={index} onClick={() => handleCharacterClick(char)}>
                                        x{char}
                                    </button>
                                ))}
                                {specialCharacters.map((char, index) => (
                                    <button className='mini-button' key={index} onClick={() => handleCharacterClick(char)}>
                                        {char}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="options-container">
                            <button id="showPopupButton" onClick={toggleCalculator} className="card-button">
                                <CalculateIcon className='calc-icon' />
                            </button>
                            {showResult ? <button className={getNextQButtonClassName(isCorrect)} onClick={handleNextQuestion}>
                                Next Question
                            </button> : <button className="submit-button" onClick={handleSubmit} disabled={!userAnswer.trim()}>
                                Submit
                            </button>}

                        </div>
                    </motion.div>
                </div>
            </AnimatePresence>
        );
    }
};

export default Quiz;
