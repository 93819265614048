import React, { useEffect, useState } from 'react';
import Quiz from '../pages/NewQuiz';
import StandaloneWeek from './subject/StandaloneWeek';
import StandaloneModule from './subject/StandaloneModule';

import { getDoc, doc } from "@firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase';

const QuizManager = ({ subjectId, lessonId, weekId, moduleId, type, handleNextActivity, saved, setSaved }) => {
    /*
    the quiz managers job is to take the information of current quiz progress
    It will then load a quiz based on the inputs
    It will use stored Leitner state to help influence decision
    This will most likely be handled in Python as it makes more sense

    takes 3 types, and builds quiz based on type
    learningExperience, endOfWeek and endOfModule
    
    essentially they are all the same, but they will be built differently

    First we will start with the learningExperience as this is the simplest
    The questions are 100% generated automatically
    
    We will need to write a simple backend call which generates the quiz without considering Leitner state

    we can call the backend with currentUser.uid, lessonId, weekId, moduleId
    */

    const [questions, setQuestions] = useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [attempts, setAttempts] = useState(0);
    console.log(weekId, moduleId);

    const [week, setWeek] = useState(null);
    const [module, setModule] = useState(null);
    const [shouldShowLearning, setShouldShowLearning] = useState(false);

    const resetQuiz = () => {
        // reset all quiz related states, and pull new questions
        fetchQuestions();
        setShouldShowLearning(false);
        setAttempts(attempts + 1);
        setModule(null);
        setWeek(null);
    }

    const showLearning = () => {
        const docToJSON = (doc) => {
            // function to convert docs from firestore to JSON
            if (!doc.exists()) return null;

            const data = doc.data();
            return {
                [doc.id]: data,
            };
        };

        // in here we basically want to 
        if (weekId) {

            const fetchSpecificWeek = async () => {
                try {
                    // Fetch the specific subject using subjectId
                    const subjectDoc = await getDoc(doc(firestore, "subjects", subjectId));
                    const subjectJSON = docToJSON(subjectDoc);

                    if (!subjectJSON) {
                        console.error('No subject found with the provided subjectId.');
                        return null;
                    }

                    // Extract the module from the subject using moduleId
                    const moduleData = subjectJSON[subjectId].modules[moduleId];

                    if (!moduleData) {
                        console.error('No module found with the provided moduleId.');
                        return null;
                    }

                    // Extract the week from the module using weekId
                    const weekData = moduleData.weeks[weekId];

                    if (!weekData) {
                        console.error('No week found with the provided weekId.');
                        return null;
                    }

                    setWeek(weekData);

                } catch (error) {
                    console.error('Error fetching specific week: ', error);
                }
            };

            fetchSpecificWeek();
        } else if (moduleId) {
            console.log('moduleId: ', moduleId);
            const fetchSpecificModule = async () => {
                try {
                    // Fetch the specific subject using subjectId
                    const subjectDoc = await getDoc(doc(firestore, "subjects", subjectId));
                    const subjectJSON = docToJSON(subjectDoc);

                    if (!subjectJSON) {
                        console.error('No subject found with the provided subjectId.');
                        return null;
                    }

                    // Extract the module from the subject using moduleId
                    const moduleData = subjectJSON[subjectId].modules[moduleId];

                    if (!moduleData) {
                        console.error('No module found with the provided moduleId.');
                        return null;
                    }

                    // Adding 'subscribed' flag to the module
                    setModule(moduleData);

                } catch (error) {
                    console.error('Error fetching specific module: ', error);
                }
            };

            fetchSpecificModule();  // Make sure this is called inside an async function or use .then()
        }
    }

    useEffect(() => {
        
        if (shouldShowLearning) {
            showLearning();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekId, moduleId, shouldShowLearning]);

    const fetchQuestions = async () => {
        let endpoint = process.env.REACT_APP_LEARNING_EXPERIENCE_ENDPOINT;
        if (type === 'learningExperience') {
            endpoint = process.env.REACT_APP_LEARNING_EXPERIENCE_ENDPOINT;
        } else if (type === 'endOfWeek' || type === 'endOfModule') {
            endpoint = process.env.REACT_APP_QUIZ_ENDPOINT;
        }

        const user = auth.currentUser;

        if (!user) {
            throw new Error('User not authenticated');
        }

        // Get ID token of the user
        const idToken = await user.getIdToken();

        // Construct query parameters
        const queryParams = new URLSearchParams({
            'lessonId': lessonId,
            'weekId': weekId,
            'moduleId': moduleId,
            'subjectId': subjectId,
            'type': type
        });

        // Append query parameters to the endpoint
        const fullEndpoint = `${endpoint}?${queryParams.toString()}`;

        try {
            const response = await fetch(fullEndpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
            });

            const questionsResponse = await response.json();

            if (questionsResponse.error) {
                console.error('Fetching questions failed:', questionsResponse.error);
            } else {
                let formattedQuestions = [];
                // TODO if we are handling t/f questions here, we may need to modify
                questionsResponse.forEach((questionResponse) => {
                    if (questionResponse.type !== 'multi') {
                        formattedQuestions.push(questionResponse);
                    } else {
                        // extract options and convert to an array of dicts
                        let options = [{ 'correctAnswer': questionResponse.correct_answer }, { 'alt_answer_1': questionResponse.alt_answer_1 }, { 'alt_answer_2': questionResponse.alt_answer_2 }, { 'alt_answer_3': questionResponse.alt_answer_3 }];
                        // shuffle array and replace all options
                        let question = { 'type': questionResponse.type, 'question': questionResponse.question, 'lesson_id': questionResponse.lesson_id, 'options': options.sort(() => Math.random() - 0.5) }
                        formattedQuestions.push(question);
                    }
                });
                console.log('formattedQuestions: ', formattedQuestions);
                if (formattedQuestions === []) {
                    
                }
                setQuestions(formattedQuestions);
            }
        } catch (error) {
            console.log('Error:', error.message);
        }
    };

    useEffect(() => {
        fetchQuestions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveResults = async (userResponses) => {
        console.log('quiz manager - subjectId: ', subjectId);
        // TODO can we reduce this to a single API call and handle the logic on the backend?
        // TODO we seem to call this endpoint twice - I think this is bad for leitner state?
        // TODO wrong place, but can we prevent default answers on quiz, to avoid empty string submit
        if (!saved) {
            console.log('Attempting to save results!');
            let endpoint = process.env.REACT_APP_RESULTS_ENDPOINT;
            if (type === 'learningExperience') {
                // Construct the data payload to be sent in POST request
                // TODO we want to add the subject ID here
                const payload = JSON.stringify({
                    userResponses: userResponses,
                    eventId: lessonId,
                    weekId: weekId,
                    moduleId: moduleId,
                    subjectId: subjectId,
                    type: type,
                });

                console.log('saving results with payload: ', payload);

                try {

                    const user = auth.currentUser;

                    if (!user) {
                        throw new Error('User not authenticated');
                    }

                    // Get ID token of the user
                    const idToken = await user.getIdToken();

                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                        body: payload // Data to send in the request
                    });

                    await response.json();

                    // we set the saved result here to true
                    setSaved(true);
                    // Handle response
                } catch (error) {
                    // TODO Handle error
                    // TODO confirm if we need this saved const
                    setSaved(false);
                }
            } else if (type === 'endOfWeek') {
                // use different endpoint here for end of week quiz
                // Construct the data payload to be sent in POST request
                const payload = JSON.stringify({
                    userResponses: userResponses,
                    eventId: weekId,
                    moduleId: moduleId,
                    subjectId: subjectId,
                    type: type,
                });

                console.log('weekId: ', weekId);

                console.log('saving results with payload: ', payload);

                try {

                    const user = auth.currentUser;

                    if (!user) {
                        throw new Error('User not authenticated');
                    }

                    // Get ID token of the user
                    const idToken = await user.getIdToken();

                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                        body: payload // Data to send in the request
                    });

                    await response.json();

                    // we set the saved result here to true
                    setSaved(true);
                    // Handle response
                } catch (error) {
                    // TODO Handle error
                    // TODO confirm if we need this saved const
                    setSaved(false);
                }
            } else if (type === 'endOfModule') {
                // use different endpoint here for end of module
                // Construct the data payload to be sent in POST request
                const payload = JSON.stringify({
                    userResponses: userResponses,
                    eventId: moduleId,
                    type: type,
                    subjectId: subjectId,
                });

                console.log('saving results with payload: ', payload);

                try {

                    const user = auth.currentUser;

                    if (!user) {
                        throw new Error('User not authenticated');
                    }

                    // Get ID token of the user
                    const idToken = await user.getIdToken();

                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                        body: payload // Data to send in the request
                    });

                    await response.json();

                    // we set the saved result here to true
                    setSaved(true);
                    // Handle response
                } catch (error) {
                    // TODO Handle error
                    // TODO confirm if we need this saved const
                    setSaved(false);
                }
            }
        }

    };

    const handleWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.onresize = () => handleWindowSize();
    }, []);
    console.log('week', 'module');
    console.log(week, module);
    if (week) {
        return (
            <>
                <button className='week-retry-button' onClick={() => resetQuiz()}>Retry Quiz</button>
                <StandaloneWeek
                    week={week}
                    subscribed={week.subscribed}
                />
            </>
        );
    } else if (module) {
        return (
            <>
                <button className='module-retry-button' onClick={() => resetQuiz()}>Retry Quiz</button>
                <StandaloneModule
                    module={module}
                    subscribed={module.subscribed}
                />
            </>
        );
    } else {
        console.log('returning quiz!');
        return (
            <>
                <Quiz
                    key={attempts}
                    resetQuiz={resetQuiz}
                    setShouldShowLearning={setShouldShowLearning}
                    questions={questions}
                    windowSize={windowSize}
                    saveResults={saveResults}
                    handleWindowSize={handleWindowSize}
                    handleNextActivity={handleNextActivity}
                    type={type}
                    showLearning={showLearning}
                />
            </>
        )
    };
};

export default QuizManager;