import React, { useEffect, useState } from 'react';
import { getDocs, collection, query, where, doc, getDoc, orderBy, limit } from '@firebase/firestore';
import { auth, firestore } from '../../firebase_setup/firebase';
import '../../style/Payment.css';
import { CSSTransition } from 'react-transition-group';
import PaymentComponent from './PaymentComponent';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMessage } from '../helpers/MessageContext';
import { motion, easeInOut } from 'framer-motion';
import ComponentLoadingScreen from '../helpers/ComponentLoadingScreen';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Payment = () => {
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [basket, setBasket] = useState([]);
  const [displayedBasket, setDisplayedBasket] = useState(basket);
  const [payment, setPayment] = useState(false);
  const [existingSubscriptions, setExistingSubscriptions] = useState(false);
  const [paymentRequired, setPaymentRequired] = useState(true);
  const { newErrorMessage, newSuccessMessage } = useMessage();
  const [loading, setLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(true);


  useEffect(() => {
    if (basket.length > 0) {
      setDisplayedBasket(basket);
    } else {
      setTimeout(() => {
        setDisplayedBasket([]);
      }, 300);
    }
    // this code gets the value of the basket and checks if it is 0
    // if (basket.reduce((sum, item) => sum + item.price, 0) === 0) {
    if (basket.every(item => item.action === 'Unsubscribe')) {
      setPaymentRequired(false);
    } else {
      setPaymentRequired(true);
    }
  }, [basket]);

  const fetchLinkedAccounts = async () => {
    const userUID = auth.currentUser?.uid;
    if (!userUID) return;
    let accountUIDs = [];
    let pendingLinkedAccounts = [];

    // Fetching existing linked accounts
    const requestQuery = query(
      collection(firestore, 'users'),
      where('linkedAccount', '==', userUID)
    );
    const requestSnapshot = await getDocs(requestQuery);
    requestSnapshot.docs.forEach(doc => {
      if (doc.data().linkedAccount) {
        accountUIDs.push(doc.id);
      }
    });

    // Fetching pending linked accounts
    const pendingRequestQuery = query(
      collection(firestore, 'linkRequests'),
      where('from', '==', userUID),
      where('status', '==', 'pending')
    );

    const pendingRequestSnapshot = await getDocs(pendingRequestQuery);
    console.log('userUID: ', userUID);
    console.log('pendingRequestSnapshot.docs: ', pendingRequestSnapshot.docs);
    pendingRequestSnapshot.docs.forEach(doc => {
      const data = doc.data();
      pendingLinkedAccounts.push({
        accountId: doc.id,  // Or any unique identifier
        name: data.toEmail || 'Not Set',
        linkRequest: true,
        // Add any other relevant data here
      });
    });

    // Processing existing linked accounts
    let accounts = [];
    if (accountUIDs.length > 0) {
      const accountsPromises = accountUIDs.map(async uid => {
        const accountDoc = await getDoc(doc(firestore, 'users', uid));
        return {
          accountId: accountDoc.id,
          name: accountDoc.data().name,
          subscriptions: accountDoc.data().subscriptions || []
        };
      });
      accounts = await Promise.all(accountsPromises);
    }
    console.log('detected accounts: ', accounts);
    console.log('detected linkRequests: ', pendingLinkedAccounts);
    // Combining existing and pending linked accounts
    setLinkedAccounts([...accounts, ...pendingLinkedAccounts]);
  };


  // const fetchLinkedAccounts = async () => {
  //   const userUID = auth.currentUser?.uid;
  //   if (!userUID) return;
  //   let accountUIDs = [];
  //   const requestQuery = query(
  //     collection(firestore, 'users'),
  //     where('linkedAccount', '==', userUID)
  //   );
  //   const requestSnapshot = await getDocs(requestQuery);
  //   requestSnapshot.docs.forEach(doc => {
  //     if (doc.data().linkedAccount) {
  //       accountUIDs.push(doc.id);
  //     }
  //   });
  //   if (accountUIDs.length > 0) {
  //     const accountsPromises = accountUIDs.map(async uid => {
  //       const accountDoc = await getDoc(doc(firestore, 'users', uid));
  //       return {
  //         accountId: accountDoc.id,
  //         name: accountDoc.data().name,
  //         subscriptions: accountDoc.data().subscriptions || []
  //       };
  //     });
  //     const accounts = await Promise.all(accountsPromises);
  //     setLinkedAccounts(accounts);
  //   }
  // };

  // const fetchActiveSubscriptions = async () => {
  //   try {
  //     const userUID = auth.currentUser?.uid;
  //     const subscriptionsQuery = query(
  //       collection(firestore, 'subscriptions'),
  //       where('parent_id', '==', userUID),
  //       where('active', '==', true)
  //     );
  //     const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
  //     const subscriptions = subscriptionsSnapshot.docs.map(doc => doc.data());
  //     return subscriptions;
  //   } catch (error) {
  //     console.error("Error fetching active subscriptions:", error);
  //     return [];
  //   }
  // };

  const fetchActiveSubscriptions = async () => {
    console.log('calling fetchActiveSubscriptions');
    try {
      const userUID = auth.currentUser?.uid;
      const subscriptionsQuery = query(
        collection(firestore, 'subscriptions'),
        where('parent_id', '==', userUID),
        where('active', '==', true),
        orderBy('timestamp', 'desc') // Assuming 'timestamp' is the field you want to sort by
      );
      const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
      const latestSubscriptions = {};
      console.log('Showing active subscriptions..');
      subscriptionsSnapshot.forEach((doc) => {
        console.log('doc: ', doc);
        const data = doc.data();
        const key = `${data.student_id}_${data.subject_id}`;

        if (!latestSubscriptions[key] || latestSubscriptions[key].timestamp < data.timestamp) {
          latestSubscriptions[key] = data;
        }
      });

      return Object.values(latestSubscriptions);
    } catch (error) {
      console.error("Error fetching active subscriptions:", error);
      return [];
    }
  };

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'subjects'));
      const subjectsList = [];
      querySnapshot.forEach(doc => {
        const subjectJSON = doc.data();
        if (subjectJSON) {
          subjectsList.push({
            ...subjectJSON,
            id: doc.id
          });
        }
      });
      setSubjects(subjectsList);
    } catch (error) {
      console.error('Error fetching subject data:', error);
    }
  };

  const initializeData = async () => {
    try {
      await fetchLinkedAccounts();
      const activeSubscriptions = await fetchActiveSubscriptions();
      setExistingSubscriptions(activeSubscriptions);
      // Incorporate logic to adjust your state based on fetched active subscriptions
      fetchData();
      // we set the basket to empty here - to allow for resuse of this function after a purchase
      setBasket([]);
    } catch {
      console.log('Error loading data (Payment.js).')
      newErrorMessage('Error loading data.')
    } finally {
      console.log(linkedAccounts)
      setComponentLoading(false);
    }

  }

  useEffect(() => {
    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  async function getPriceFromPlans(subjectId) {
    if (!subjectId) {
      throw new Error("Subject ID is required");
    }

    try {
      const plansQuery = query(
        collection(firestore, "subject_plans"),
        where("subject_id", "==", subjectId),
        orderBy("timestamp", "desc"), // Assuming 'timestamp' is the field name
        limit(1)
      );

      const plansSnapshot = await getDocs(plansQuery);

      if (!plansSnapshot.empty) {
        const plan = plansSnapshot.docs[0].data(); // Get the first (and only) doc
        return plan.amount; // Assuming 'price' is the field name for the price
      } else {
        throw new Error("No plans found for this subject");
      }
    } catch (error) {
      console.error("Error fetching price from plans:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  const addToBasket = async (account, subject) => {
    const basketItemIndex = basket.findIndex(item => item.account.name === account.name && item.subject.id === subject.id);

    if (basketItemIndex !== -1) {
      // If the item already exists in the basket, remove it
      const updatedBasket = [...basket];
      updatedBasket.splice(basketItemIndex, 1);
      setBasket(updatedBasket);
    } else {
      // Otherwise, fetch the price and then add the Subscribe action to the basket
      try {
        const price = await getPriceFromPlans(subject.id);
        console.log(`Setting up payment for ${account.name} for subject ${subject.name}, ${subject.id} with price $${price}`);
        setBasket(prevBasket => [...prevBasket, { account, subject, action: 'Subscribe', price }]);
      } catch (error) {
        console.error('Error fetching price:', error);
        // Handle the error appropriately
      }
    }
  };

  const removeFromBasket = (account, subject) => {
    const basketItemIndex = basket.findIndex(item => item.account.name === account.name && item.subject.id === subject.id);

    if (basketItemIndex !== -1) {
      // If the item already exists in the basket, remove it
      const updatedBasket = [...basket];
      updatedBasket.splice(basketItemIndex, 1);
      setBasket(updatedBasket);
    } else {
      // Otherwise, add the Unsubscribe action to the basket
      setBasket(prevBasket => [...prevBasket, { account, subject, action: 'Unsubscribe', price: 0 }]);
    }
  };

  if (componentLoading) {
    return <ComponentLoadingScreen />
  }

  if (payment) {
    return (
      <motion.div
        key='card-screen'
        initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
        style={{ position: 'relative' }}
      >
        <Elements stripe={stripePromise}>
          <PaymentComponent basket={basket} setPayment={setPayment} reload={initializeData} />
        </Elements>
      </motion.div>
    )
  }

  console.log('linkedAccounts: ', linkedAccounts);

  const handleUnsubscription = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_UNSUBSCRIBE_ENDPOINT;

      if (!url) {
        throw new Error('Unsubscribe endpoint is not set.');
      }

      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      // Get ID token of the user
      const idToken = await user.getIdToken();

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          basket: basket
        }),
      });

      if (response.status !== 200) {
        const errorData = await response.json();
        console.error('Unsubscription failed:', errorData);
        newErrorMessage('Unsubscription failed. Please try again later.');
      } else {
        console.log('Unsubscription successful.');
        newSuccessMessage('Unsubscription successful.');
        initializeData();
      }
    } catch (error) {
      console.error('Error during unsubscription:', error.message);
      newErrorMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const confirmUnsubscribe = () => {
    const formattedBasketItems = basket.map(item => {
      return `
        ${item.account.name} - ${item.subject.name} (${item.action})
        `;
    }).join('\n');

    const confirmationMessage = `
Are you sure you want to make the following changes?
${formattedBasketItems}
    `;

    if (window.confirm(confirmationMessage)) {
      // here we call a cloud function which will cancel all subscriptions
      handleUnsubscription();
    }
  };

  // if (existingSubscription && (!basketAction || basketAction.action === "Subscribe")) {
  //   const startDate = new Date(existingSubscription.timestamp.seconds * 1000).toLocaleDateString().replace(/\//g, '-');
  //   const nextBillingDate = new Date(existingSubscription.next_payment_date).toLocaleDateString().replace(/\//g, '-');
  //   return (
  //     <div className='subject-row'>
  //       <p className='main-text'>{subject.name}</p>
  //       <span> {existingSubscription.unsubscribed ? 'Unsubscribed' : 'Subscribed'} </span>
  //       <span> Start Date: {startDate}</span>
  //       <span> {existingSubscription.unsubscribed ? 'Valid Until' : 'Next Billing:'} {nextBillingDate}</span>
  //       <button className='unsub-button' onClick={() => removeFromBasket(account, subject)}>Unsubscribe</button>
  //     </div>
  //   );
  // }

  return (
    <motion.div
      key='card-screen'
      initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
      style={{ position: 'relative' }}
    >
      <h1 className='main-header'>Parent Dashboard</h1>
      <CSSTransition
        in={basket.length !== 0}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div>
          <h2 className='main-header-1'>Subscription Changes</h2>
          <div className='account-container'>
            <table>
              <thead>
                <tr>
                  <th>Change</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {displayedBasket.map(item => (
                  <tr key={`${item.account.name}-${item.subject.id}`}>
                    <td>{item.action} {item.account.name} to {item.subject.name}</td>
                    <td>£{item.price}/month</td>
                    <td><button className='mini-unsub-button' onClick={() => removeFromBasket(item.account, item.subject)}>Remove</button></td>
                  </tr>
                ))}
              </tbody>
            </table>

            {displayedBasket.length > 0 && (
              paymentRequired ?
                <>
                  <button className='sub-button' onClick={() => setPayment(true)}>Checkout</button>
                </>
                :
                <>
                  {loading ?
                    <button className='sub-button' disabled={true}>Loading...</button>
                    :
                    <button className='sub-button' onClick={() => confirmUnsubscribe(true)}>Confirm Changes</button>}
                </>

            )}
          </div>
        </div>
      </CSSTransition>
      <h2 className='main-header-1'>Linked Accounts</h2>
      {linkedAccounts.map(account => (
        <div key={account.name} className='account-container'>
          <h3 className='main-header-1'>{account.name}{account.linkRequest ? ' (pending)' : ''}</h3>
          <h4 className='main-text'>Subjects</h4>
          {subjects.map(subject => {
            // const existingSubscription = existingSubscriptions.find(sub => sub.subject_id === subject.id && sub.student_id === account.accountId);
            console.log('existingSubscriptions: ', existingSubscriptions);
            console.log('account: ', account);
            const existingSubscription = existingSubscriptions.find(sub =>
              sub.subject_id === subject.id &&
              (sub.student_id === account.accountId || (account.linkRequest && sub.student_email === account.name))
            );
            console.log('existingSubscription: ', existingSubscription);
            let startDate = null;
            let nextBillingDate = null;
            if (existingSubscription) {
              startDate = new Date(existingSubscription.timestamp.seconds * 1000).toLocaleDateString().replace(/\//g, '-');
              nextBillingDate = new Date(existingSubscription.next_payment_date).toLocaleDateString().replace(/\//g, '-');
            }
            const basketAction = basket.find(item => item.account.name === account.name && item.subject.id === subject.id);


            if (existingSubscription && existingSubscription.unsubscribed) {
              const basketActionResubscribe = basket.find(item => item.account.name === account.name && item.subject.id === subject.id && item.action === "Subscribe");
              return (
                <div className='subject-row'>
                  <table className='subscription-details'>
                    <tbody>
                      <tr>
                        <td className='main-text'>Subject</td>
                        <td className='main-text'>{subject.name}</td>
                      </tr>
                      <tr>
                        <td className='main-text'>Status</td>
                        <td className='main-text'>Unsubscribed</td>
                      </tr>
                      <tr>
                        <td className='main-text'>Start Date</td>
                        <td className='main-text'>{startDate}</td>
                      </tr>
                      <tr>
                        <td className='main-text'>Active Until</td>
                        <td className='main-text'>{nextBillingDate}</td>
                      </tr>
                      <td colSpan="2">
                        {basketActionResubscribe ? (
                          <button className='unsub-button' onClick={() => removeFromBasket(account, subject)}>Cancel Resubscribe</button>
                        ) : (
                          <button className='sub-button' onClick={() => addToBasket(account, subject)}>Resubscribe</button>
                        )}
                      </td>
                    </tbody>
                  </table>
                </div>
              );
            }

            if (existingSubscription && (!basketAction || basketAction.action === "Subscribe")) {
              return (
                <div className='subject-row'>
                  <table className='subscription-details'>
                    <tbody>
                      <tr>
                        <td>Subject</td>
                        <td>{subject.name}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>Subscribed</td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td>{startDate}</td>
                      </tr>
                      <tr>
                        <td>Next Billing</td>
                        <td>{nextBillingDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button className='unsub-button' onClick={() => removeFromBasket(account, subject)}>Unsubscribe</button>
                </div>
              );
            }

            if (!existingSubscription && (!basketAction || basketAction.action === "Unsubscribe")) {
              return (
                <div className='subject-row'>
                  <p className='main-text'>{subject.name}</p>
                  <button className='sub-button' onClick={() => addToBasket(account, subject)}>Subscribe</button>
                </div>
              );
            }

            if (existingSubscription && basketAction && basketAction.action === "Unsubscribe") {
              return (
                <div className='subject-row'>
                  <table className='subscription-details'>
                    <tbody>
                      <tr>
                        <td>Subject</td>
                        <td>{subject.name}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>Subscribed (Unsubscribing...)</td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td>{startDate}</td>
                      </tr>
                      <tr>
                        <td>Next Billing</td>
                        <td>{nextBillingDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button className='sub-button' onClick={() => removeFromBasket(account, subject)}>Cancel Unsubscribe</button>
                </div>
              );
            }

            if (!existingSubscription && basketAction && basketAction.action === "Subscribe") {
              return (
                <div className='subject-row'>
                  <p className='main-text'>{subject.name}</p>
                  <button className='unsub-button' onClick={() => addToBasket(account, subject)}>Cancel Subscribe</button>
                </div>
              );
            }
            return null
          })}
        </div>
      ))}
    </motion.div>
  );

};

export default Payment;