import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// I think this is correct, but will verify later
// import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const fireBaseApp = initializeApp(firebaseConfig);

// Export Firestore
export const firestore = getFirestore(fireBaseApp);
// export auth
export const auth = getAuth(fireBaseApp);
// i think this is correct but will verify later
// export const functions = getFunctions(fireBaseApp);
export const storage = getStorage(fireBaseApp);