import React, { useState } from 'react';
import '../../style/AvatarSelect.css';
import avatar_1 from './../../images/avatar_1.png';
import avatar_2 from './../../images/avatar_2.png';
import avatar_3 from './../../images/avatar_3.png';
import avatar_4 from './../../images/avatar_4.png';
import avatar_5 from './../../images/avatar_5.png';
import avatar_6 from './../../images/avatar_6.png';
import avatar_7 from './../../images/avatar_7.png';

const avatars = [
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU',
    avatar_1,
    avatar_2,
    avatar_3,
    avatar_4,
    avatar_5,
    avatar_6,
    avatar_7,
    // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU',
    // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU',
    // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU',
    // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU',
    // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU',
];

const AvatarSelect = ({ onSelect }) => {
    const [selectedAvatar, setSelectedAvatar] = useState(avatars[0]);

    const handleAvatarClick = (avatar) => {
        setSelectedAvatar(avatar);
        onSelect(avatar);
    };
    // TODO add colouration to avatar through classes
    return (
        <div className="avatar-select">
            <div className="avatar-container">
                {avatars.map((avatar) => (
                    <div
                        key={avatar}
                        className={`avatar-item ${selectedAvatar === avatar ? 'selected' : ''}`}
                        onClick={() => handleAvatarClick(avatar)}
                    >
                        <img className='avatar-image' src={avatar} alt="User Avatar" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AvatarSelect;
