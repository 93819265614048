import React, { useState, useEffect } from 'react';
import './../../style/AppLoadingScreen.css';
import ILSLogo from './../../images/ils_logo.webp';

const AppLoadingScreen = () => {
    const [showHelpMessage, setShowHelpMessage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowHelpMessage(true);
        }, 5000); // 5000ms = 5 seconds

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);


    return (
        <div className='flex-column-app'>
            <div className='flex-row'>
                <div className="logo-container">
                    <img src={ILSLogo} alt="ILS Logo" />
                </div>
            </div>
            <div className="spacer"></div> {/* Spacer div */}
            <div className="lds-dual-ring" />

            {
                showHelpMessage &&
                <div className="help-message">
                    If the page is not loading, please try again later or contact support.
                </div>
            }
        </div>
    );
};

export default AppLoadingScreen;