import React, { useState, useEffect } from 'react';
import './../../style/ContactUs.css';
import { firestore, auth } from '../../firebase_setup/firebase';  // Adjust the path
import { addDoc, collection, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useMessage } from '../helpers/MessageContext';
import { motion, easeInOut } from 'framer-motion';

const ContactUs = () => {
    const [message, setMessage] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const { newErrorMessage, newSuccessMessage } = useMessage();
    const [isLoading, setIsLoading] = useState(false);

    // Subscribe to auth changes and fetch user details
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(firestore, 'users', user.uid);
                const userSnapshot = await getDoc(userRef);
                if (userSnapshot.exists()) {
                    setUserDetails({
                        email: user.email, // Fetch email from Firebase Auth
                        name: userSnapshot.data().name, // Fetch name from Firestore
                        role: userSnapshot.data().role
                    });
                }
            }
        });

        return () => unsubscribe();
    }, []);

    

    const handleSubmit = async (event) => {

        console.log('in handle submit!');
        event.preventDefault();
        if (!message || !userDetails) {
            return;
        }
        setIsLoading(true);

        try {
            await addDoc(collection(firestore, 'messages'), {
                content: message,
                userEmail: userDetails.email,
                userName: userDetails.name,
                userRole: userDetails.role,
                timestamp: serverTimestamp(), // Add server timestamp
            });

            // After successfully adding the document, call the endpoint
            const url = process.env.REACT_APP_MESSAGE_RECEIVED;
            const user = auth.currentUser;

            if (!user) {
                newErrorMessage('User not authenticated'); // Using custom error popup here
                return;
            }

            // Get ID token of the user
            const idToken = await user.getIdToken();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    content: message,
                    userEmail: userDetails.email,
                    userName: userDetails.name,
                    userRole: userDetails.role,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            if (responseData.error) {
                console.error('Message submission failed:', responseData.error);
                newErrorMessage('Failed to notify about the message submission.'); // Using custom error popup here
            } else {
                console.log('Message notification sent:', responseData);
                newSuccessMessage('Message successfully submitted and notification sent.'); // Using custom popup here
            }

            setMessage('');

        } catch (error) {
            newErrorMessage('Failed to submit the message.'); // Using custom error popup here
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <motion.div
            key='student-support'
            initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
            style={{ position: 'relative' }}
        >
            <div className="contact-container">
                <form class="form">
                    <div class="title">Contact Us</div>
                    <p>
                        Have a question or need assistance? <br /><br />
                        Contact us using the form below or drop us an email at:&nbsp;
                        <a href="mailto:info@ilscoaching.co.uk">info@ilscoaching.co.uk</a>
                    </p>
                    <textarea
                        placeholder="Your message"
                        value={message} // Controlled component
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button onClick={handleSubmit}>{isLoading ? 'Loading...' : 'Submit'}</button>
                </form>
            </div>
        </motion.div>
    );
};

export default ContactUs;
