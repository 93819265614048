import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const LoadingScreen = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const texts = [
        "Loading awesomeness...",
        "Unicorns are being assembled...",
        "Counting to infinity...",
        "Summoning the funny cats...",
        "Preparing mind-blowing content...",
        "Finding the meaning of life...",
        "Just a few more seconds...",
        "Searching for the lost socks of eternal mystery...",
        "Training a fleet of dancing robots for the ultimate performance...",
        "Bringing dinosaurs back to life, one DNA strand at a time...",
        "Convincing vegetables to taste like ice cream... almost there!",
        "Building a secret chocolate factory under the moonlight...",
        "Teaching penguins to fly for an upcoming Arctic Airshow...",
        "Unleashing a squadron of ticklish butterflies in the wild...",
        "Creating a potion to turn homework into a pile of cookies...",
        "Training monkeys to juggle bananas and tell knock-knock jokes...",
        "Perfecting the art of ninja paper folding with origami masters...",
        "Brewing a storm in a teacup...",
        "Constructing a bridge to the fantasy realm...",
        "Growing an orchard of chocolate trees...",
        "Catching shooting stars for dream fuel...",
        "Inventing a new color for extra creativity...",
        "Assembling a choir of opera-singing frogs...",
        "Designing a hat that can think for itself...",
        "Hiring squirrels as acorn stock managers...",
        "Installing rainbows in every cloud...",
        "Programming fireflies to write poetry in the dark...",
        "Forming a band with time-traveling minstrels...",
        "Knitting sweaters for chilly dragons...",
        "Organizing a flash mob of undercover unicorns...",
        "Cultivating a garden of laughing sunflowers...",
        "Collaborating with bees on the ultimate honey recipe...",
        "Tuning the orchestra of crickets for a nightly symphony...",
        "Stirring up a whirlwind of fallen autumn leaves...",
        "Arranging a meeting with fictional characters...",
        "Choreographing a ballet for squirrels and chipmunks...",
        "Optimizing the purr engine of contented kittens...",
        "Deciphering the secrets of ancient pizza recipes...",
        "Building a silly goose...",
        "Deploying ninjas to ensure Internet safety...",

    ];

    const [currentTextIndex, setCurrentTextIndex] = useState(Math.floor(Math.random() * texts.length));
    const [currentText, setCurrentText] = useState(texts[currentTextIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * texts.length);
            setCurrentTextIndex(randomIndex);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [texts.length]);

    useEffect(() => {
        setCurrentText(texts[currentTextIndex]);
    }, [currentTextIndex, texts]);

    return (
        <div style={{ height: '100vh' }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingTop: "200px",
                }}>
                <motion.div
                    className="box"
                    animate={{
                        scale: [1, 2, 2, 1, 1],
                        rotate: [0, 0, 180, 180, 0],
                        borderRadius: ["0%", "0%", "50%", "50%", "0%"]
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        repeat: Infinity,
                        repeatDelay: 0
                    }}
                />
            </div>
            <div style={{ textAlign: "center", paddingTop: '50px' }}>
                <h2>{currentText}</h2>
            </div>
        </div>
    );
};

export default LoadingScreen;
