import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import '../../style/StudentPortal.css';
import StudentSideNav from '../components/StudentSideNav';
import StudentSubjects from '../components/StudentSubjects';
import StudentSupport from '../components/StudentSupport';
import YourProgress from '../components/YourProgress';
import LinkAccount from '../components/LinkAccount';
import AccountPage from '../components/AccountPage';
import SubjectSelector from './SubjectSelector';
import LessonManager from './LessonManager';
import StudentTour from '../helpers/StudentTour';
import { useMessage } from '../helpers/MessageContext';

// firebase
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase_setup/firebase';


const CentralBox = ({ activeItem, setActiveItemWithContext, context }) => {
    // TODO move attached accounts to LoginContextProvider
    // TODO ensure that you also reset attached accounts on logout

    const renderContent = (selection) => {
        console.log('rendering: ', selection);
        console.log('with context: ', context);
        switch (selection) {
            case 'yourProgress':
                return <YourProgress setActiveItemWithContext={setActiveItemWithContext} />
            case 'learn':
                // TODO if we need to pass more context, turn the context object into a dict
                if (context) {
                    return <LessonManager subjectId={context} />
                }
                return <SubjectSelector />
            case 'subjects':
                return <StudentSubjects />;
            case 'linkAccount':
                return <LinkAccount />;
            case 'support':
                return <StudentSupport />;
            case 'account':
                return <AccountPage />
            default:
                return <YourProgress setActiveItemWithContext={setActiveItemWithContext} />
        }
    };
    return (
        <AnimatePresence>
            <div className='central-box'>
                {activeItem && (
                    renderContent(activeItem)
                )}
            </div>

        </AnimatePresence>
    );
};

const StudentPortal = ({ handleLogout }) => {
    const [activeItem, setActiveItem] = useState('overview');
    const [context, setContext] = useState(null);
    const { newErrorMessage } = useMessage();

    const [isFirstVisit, setIsFirstVisit] = useState(null);
    // Add a state to control the tour
    const [isTourActive, setIsTourActive] = useState(false);  // set to false if you don't want the tour to start automatically

    useEffect(() => {
        checkFirstVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this checks if this is the first visit
    const checkFirstVisit = async () => {
        const user = auth.currentUser;

        if (!user) {
            // Handle user not logged in
            return;
        }

        const userUID = user.uid;

        if (!userUID) {
            // Handle user ID not found
            return;
        }

        try {
            const userDoc = await getDoc(doc(firestore, "users", userUID));
            
            if (userDoc.exists()) {
                const firstVisit = userDoc.data().hasOwnProperty('firstVisit') ? userDoc.data().firstVisit : true;
                console.log('setting tour active to: ', firstVisit);
                setIsFirstVisit(firstVisit);
                setIsTourActive(firstVisit);
            } else {
                // If the document doesn't exist, assume it's the user's first visit
                setIsFirstVisit(true);
                setIsTourActive(true);
                console.log('setting tour active')
            }
        } catch (error) {
            newErrorMessage('Error checking if tour complete. Starting anyways.');
            setIsFirstVisit(true);
            // Handle error
            console.log('err')
        }
        
    }

    // const handleNavItemClick = (item) => {
    //     setActiveItem(item);
    // };

    const handleTourCallback = async (data) => {
        // Handle the end of the tour or if the user skips it
        const { status } = data;
        if (status === 'finished' || status === 'skipped') {
            setIsTourActive(false);
        }

        if (status === 'finished' && isFirstVisit) {
            const user = auth.currentUser;
            const userUID = user?.uid;
    
            if (!user || !userUID) {
                // Handle user not logged in or user ID not found
                return;
            }
    
            try {
                await updateDoc(doc(firestore, "users", userUID), { firstVisit: false });
                setIsFirstVisit(false);
                // Handle successful update, e.g., show a success message
            } catch (error) {
                newErrorMessage('Error saving your progress.')
                // Handle error
            }
        }
    };

    // const handleNavItemClick = (item) => {
    //     setActiveItem(item);
    // };


    const setActiveItemWithContext = (newActiveItem, context = null) => {
        setContext(context);
        setActiveItem(newActiveItem);
    }

    if (context) {
        return (
            <div className="fill-screen fill-screen-h">
                <StudentTour run={isTourActive} callback={handleTourCallback} />
                <StudentSideNav handleNavItemClick={setActiveItemWithContext} handleLogout={handleLogout} setIsTourActive={setIsTourActive} />
                <CentralBox activeItem={activeItem} setActiveItemWithContext={setActiveItemWithContext} context={context} />
            </div>
        );
    }

    return (
        <div className="fill-screen fill-screen-h">
            <StudentTour run={isTourActive} callback={handleTourCallback} />
            <StudentSideNav handleNavItemClick={setActiveItemWithContext} handleLogout={handleLogout} setIsTourActive={setIsTourActive} />
            <CentralBox activeItem={activeItem} setActiveItemWithContext={setActiveItemWithContext} />
        </div>
    );
};

export default StudentPortal;
