import React from 'react';
import mascot from '../../images/mascot.png';

const AvatarPopup = ({ onClose, text }) => {
    const handleOuterDivClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    // Convert text with newline characters to JSX
    const textJSX = text.split('\n').map((str, index, array) => (
        <React.Fragment key={index}>
            {str}
            {index !== array.length - 1 && <br />}
        </React.Fragment>
    ));

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}
                onClick={handleOuterDivClick}
            >
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={speechBubbleStyle}>
                        <button
                            onClick={onClose}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                border: 'none',
                                background: 'transparent',
                                cursor: 'pointer',
                                padding: '10px',
                                fontSize: '1.5rem'
                            }}
                        >
                            ×
                        </button>
                        <p className='main-text'>{textJSX}</p>
                        <div style={tailStyle}></div>
                        <br/>
                        <button className='register-button' onClick={onClose}>Next</button>
                    </div>
                    {mascot && <img src={mascot} alt="The ILS Mascot" style={{ maxWidth: '350px', margin: '20px 0' }} />}
                    
                </div>
            </div>
            
        </>
    );
}

const speechBubbleStyle = {
    position: 'relative',
    padding: '20px',
    paddingBottom: '30px',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    margin: '0 20px',
};

const tailStyle = {
    position: 'absolute',
    top: '50%',  // Center the tail vertically
    right: -15,  // Place it on the right side
    transform: 'translateY(-50%) rotate(180deg)', // Centers the tail vertically
    width: '0',
    height: '0',
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    borderRight: '20px solid #FFF',  // This creates the triangle pointing left
};

export default AvatarPopup;
