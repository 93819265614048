import React, { useState } from 'react';
import { CardElement, AddressElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { auth } from '../../firebase_setup/firebase';
import '../../style/PaymentComponent.css';
import { useMessage } from '../helpers/MessageContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function UpdateCardComponent() {
    const { newErrorMessage, newSuccessMessage } = useMessage();
    const stripe = useStripe();
    const elements = useElements();
    const [detailsFilled, setDetailsFilled] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        name: "",
        address: {
            line1: "",
            city: "",
            state: "",
            postal_code: "",
            country: ""
        }
    });
    const [expanded, setExpanded] = useState(false);

    const handleUpdatePaymentDetails = async () => {
        const url = process.env.REACT_APP_UPDATE_BILLING_INFO_ENDPOINT;

        const user = auth.currentUser;
        if (!user) {
            throw new Error('User not authenticated');
        }

        // Get ID token of the user
        const idToken = await user.getIdToken();

        if (!stripe || !elements) {
            newErrorMessage('Failed to connect to stripe. Please try again later.');
            return;
        }

        const { token } = await stripe.createToken(elements.getElement(CardElement));

        if (token) {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                    body: JSON.stringify({
                        token: token.id,
                        billing_details: billingDetails
                    }),
                });

                const responseData = await response.json();

                if (response.ok) {
                    newSuccessMessage('Billing details updated successfully.');
                    console.log("Card updated successfully", responseData);
                } else {
                    newErrorMessage('Billing details failed to update. Please try again later.');
                    console.error("Error updating card", responseData);
                }
            } catch (error) {
                console.error('Error updating details: ', error);
                newErrorMessage('Billing details failed to update. Please try again later.');
            }

        }
    };



    return (
        <div className='payment-box'>
                <button className="module-header" onClick={() => setExpanded(!expanded)}>
                    Update Payment Details
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <div className={`collapsible-content ${expanded ? 'expanded' : ''}`}>
                        <>
                            <form>
                                <h3>Billing Address</h3>
                                <AddressElement
                                    options={{ mode: 'billing' }}
                                    onChange={(event) => {
                                        console.log(event);
                                        if (event.complete) {
                                            setDetailsFilled(true);
                                            setBillingDetails(prevState => ({
                                                address: event.value,
                                                name: event.name
                                            }));
                                        } else {
                                            // if the details are filled and they have become unfilled, revoke the flag
                                            if (detailsFilled === true) {
                                                setDetailsFilled(false);
                                            }
                                        }
                                    }}
                                />

                                <h3>Card Details</h3>
                                <CardElement />
                            </form>

                            {detailsFilled ? (
                                <button className='pay-button' onClick={handleUpdatePaymentDetails}>
                                    Update Details
                                </button>
                            ) : (
                                <button
                                    className='x-pay-button'
                                    style={{ opacity: '0.5', cursor: 'not-allowed' }}
                                >
                                    Update Details
                                </button>
                            )}
                        </>
                </div>
        </div>
    );
}

export default UpdateCardComponent;
