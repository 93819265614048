import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { React, useEffect, useRef, useState, useCallback } from 'react';
import EditWeek from './EditWeek';
import { v4 as uuidv4 } from 'uuid';
import { useMessage } from '../../helpers/MessageContext';
import isEqual from 'lodash/isEqual';

const EditModule = ({ module, expanded, toggleModule, changeModuleValue, deleteModule, subjectId }) => {
    // for expanding items
    const [contentHeight, setContentHeight] = useState(0);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const contentRef = useRef(null);
    const { newSuccessMessage, newErrorMessage } = useMessage();

    const [moduleState, setModuleState] = useState({
        module: module,
        weeks: module[1].weeks,
        isEditing: false,
        editedModule: module,
        week_order: module[1].week_order
    });

    // this handles the changes being passed down into the object, when we add a new module
    useEffect(() => {
        // this code is what is causing the issue - I think we need to somehow put a blocker on this
        const newModuleValue = {
            ...moduleState,
            module: module,
            weeks: module[1].weeks,
            week_order: module[1].week_order,
        }
        if (!isEqual(newModuleValue, moduleState)) {
            setModuleState(newModuleValue);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [module]);

    useEffect(() => {
        let newModule = JSON.parse(JSON.stringify(moduleState.module)); // here we need to make a deep copy

        newModule[Object.keys(moduleState.module)[1]].weeks = moduleState.weeks;
        newModule[Object.keys(moduleState.module)[1]].week_order = moduleState.week_order;
        if (JSON.stringify(newModule) !== JSON.stringify(moduleState.module)) {
            // update subjects and then push them to the DB
            changeModuleValue(newModule);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleState.weeks, moduleState.week_order, moduleState.module]);

    useEffect(() => {
        if (!expanded) {
            setExpandedWeeks({});
        }
        setContentHeight(expanded ? contentRef.current.scrollHeight : 0);
    }, [expanded, moduleState.isEditing]);

    const updateHeight = useCallback((height) => {
        const newHeight = contentHeight + height;
        setContentHeight(newHeight);
    }, [contentHeight]);

    // here we return nothing if the object is null
    // if (module[1] === undefined) {
    //     return null;
    // }

    const handleEditClick = () => {
        setModuleState({
            ...moduleState,
            isEditing: true,
            editedModule: moduleState.module
        });
    };

    const changeWeekValue = (updatedValue) => {
        const newWeeks = { ...moduleState.weeks };
        newWeeks[updatedValue[0]] = updatedValue[1];

        const newModule = {
            ...moduleState,
            weeks: newWeeks,
        };
        
        setModuleState(newModule);
    };

    const deleteWeek = (weekId) => {
        const newWeeks = { ...moduleState.weeks };
        const newWeekOrder = moduleState.week_order;

        if (window.confirm(`Are you sure you want to delete this week? \n ${newWeeks[weekId].name}. \n This action cannot be undone, and will delete all lessons inside the week.`)) {
            delete newWeeks[weekId]; // This will remove the week from the copied object.

            var index = newWeekOrder.indexOf(weekId);
            if (index > -1) {
                newWeekOrder.splice(index, 1); // Removes the item at index
            }

            setModuleState(prevState => ({
                ...prevState,
                weeks: newWeeks,
                week_order: newWeekOrder
            }));
            newSuccessMessage('Week deleted successfully.');
        } else {
            // If the user cancels, do nothing
            newErrorMessage('Week deletion cancelled.');
        }
    }

    const handleModuleSubmit = (editedModule) => {
        changeModuleValue(Object.values(editedModule));
        setModuleState({
            ...moduleState,
            isEditing: false
        });
    };

    const toggleWeek = (weekId) => {
        setExpandedWeeks((prevExpandedWeeks) => ({
            // ...prevExpandedWeeks,
            [weekId]: !prevExpandedWeeks[weekId],
        }));
    };


    const handleToggle = () => {
        toggleModule(module[1].name);
    };

    const addNewWeek = () => {
        // debugger
        // when we get to this, both moduleState.weeks and moduleState.week_order are null
        const lessonId = uuidv4();
        const weekId = uuidv4();
        const newWeek = {
            [weekId]: { description: 'a new week', name: 'new week', lesson_order: [lessonId], lessons: { [lessonId]: { description: 'a new lesson', name: 'new lesson', src: '' } } }
        };
        setModuleState({
            ...moduleState,
            weeks: { ...newWeek, ...moduleState.weeks },
            week_order: [...moduleState.week_order, weekId],
        });
        updateHeight();
    }

    if (moduleState.isEditing) {
        // return <ModuleForm module={editedModule} onSubmit={handleModuleSubmit} setModule={setEditedModule} />;
        return <li className='module-container'>
            <div className="module-header">
                <input
                    className='module-input'
                    type="text"
                    // value={editedModule[1].name}
                    placeholder={moduleState.editedModule[1].name}
                    onChange={(e) => setModuleState(prevState => ({
                        ...prevState,
                        editedModule: {
                            ...prevState.editedModule,
                            "1": {
                                ...prevState.editedModule["1"],
                                name: e.target.value
                            }
                        }
                    }))}
                    disabled={!moduleState.isEditing} // Disable the input field when not in editing mode
                />
                <button className="module-button" onClick={handleToggle}>
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <button className="module-edit" onClick={() => handleModuleSubmit(moduleState.editedModule)}>Save</button>
            </div>
            {expanded &&
                <div
                    className={`module-content ${expanded ? 'expanded' : ''}`}
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    <input
                        className='module-input'
                        type="text"
                        // value={editedModule[1].description}
                        placeholder={moduleState.editedModule[1].description}
                        onChange={(e) => setModuleState(prevState => ({
                            ...prevState,
                            editedModule: {
                                ...prevState.editedModule,
                                "1": {
                                    ...prevState.editedModule["1"],
                                    description: e.target.value
                                }
                            }
                        }))}
                        disabled={!moduleState.isEditing} // Disable the input field when not in editing mode
                    />
                    <ul className={`module-content ${expanded ? 'expanded' : ''}`}>
                        {/* {Object.entries(module[1].weeks) && Object.entries(module[1].weeks).map((week) => (
                        <EditWeek
                            key={week[0]}
                            week={week}
                            // TODO need to change this to use the ID, but when i changed it it broke and I'm tired !
                            expanded={expandedWeeks[week[1].name]}
                            toggleWeek={() => toggleWeek(week[1].name)}
                            propagateHeight={updateHeight}
                            changeWeekValue={changeWeekValue}
                        />

                    ))} */}
                        {Object.entries(module[1].weeks) &&
                            moduleState.week_order.map((weekId) => (
                                <EditWeek
                                    key={weekId}
                                    week={Object.entries({ [weekId]: moduleState.weeks[weekId] })[0]}
                                    expanded={expandedWeeks[weekId]}
                                    toggleWeek={() => toggleWeek(weekId)}
                                    propagateHeight={updateHeight}
                                    changeWeekValue={changeWeekValue}
                                    deleteWeek={deleteWeek}
                                    subjectId={subjectId}
                                    moduleId={module[0]}
                                />
                            ))}
                    </ul>
                    <button className='add-subject' onClick={addNewWeek}>Add new week</button>
                </div>}
        </li>
    }

    return (
        <li className='module-container'>
            <div>
                <button className="module-header" onClick={handleToggle}>
                    {module[1].name}
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <button className="module-edit" onClick={handleEditClick}>Edit</button>
                <button className="module-edit" onClick={() => deleteModule(module[0])}>Delete</button>
            </div>
            {expanded &&
                <div
                    className={`module-description ${expanded ? 'expanded' : ''}`}
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    {module[1].description}
                    <ul className={`module-content ${expanded ? 'expanded' : ''}`}>
                        {/* {module[1].weeks && Object.entries(module[1].weeks).map((week) => (
                        <EditWeek
                            key={week[0]}
                            week={week}
                            expanded={expandedWeeks[week[1].name]}
                            toggleWeek={() => toggleWeek(week[1].name)}
                            propagateHeight={updateHeight}
                            changeWeekValue={changeWeekValue}
                        />
                    ))} */}
                        {Object.entries(module[1].weeks) &&
                            moduleState.week_order.map((weekId) => (
                                <EditWeek
                                    key={weekId}
                                    week={Object.entries({ [weekId]: moduleState.weeks[weekId] })[0]}
                                    expanded={expandedWeeks[weekId]}
                                    toggleWeek={() => toggleWeek(weekId)}
                                    propagateHeight={updateHeight}
                                    deleteWeek={deleteWeek}
                                    changeWeekValue={changeWeekValue}
                                    subjectId={subjectId}
                                    moduleId={module[0]}
                                />
                            ))}
                    </ul>
                    <button className='add-subject' onClick={addNewWeek}>Add new week</button>

                </div>}
        </li>
    );
};

export default EditModule;