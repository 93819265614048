import React, { useState, useEffect } from 'react';
import '../../style/MessagingInterface.css';
import { collection, query, orderBy, startAfter, limit, getDocs, deleteDoc, doc } from "firebase/firestore";
import { firestore } from '../../firebase_setup/firebase';

const PAGE_SIZE = 10; // Adjust based on your preference

const MessagingInterface = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastVisible, setLastVisible] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);

    const deleteMessageFromDB = async (messageId) => {
        const messageRef = doc(firestore, 'messages', messageId);
        await deleteDoc(messageRef);
    };

    const fetchMessages = async () => {
        setLoading(true);

        let messagesQuery = query(
            collection(firestore, 'messages'),
            orderBy('timestamp', 'desc'), // Sorting by timestamp
            limit(PAGE_SIZE)
        );

        if (lastVisible) {
            messagesQuery = query(
                collection(firestore, 'messages'),
                orderBy('timestamp', 'desc'),
                startAfter(lastVisible),
                limit(PAGE_SIZE)
            );
        }

        const messageSnapshot = await getDocs(messagesQuery);
        const newMessages = messageSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        if (newMessages.length < PAGE_SIZE) {
            setHasMore(false);
        }

        if (newMessages.length > 0) {
            setLastVisible(newMessages[newMessages.length - 1].timestamp);
        }

        setMessages(prevMessages => [...prevMessages, ...newMessages]);
        setLoading(false);
    };

    useEffect(() => {
        setSelectedMessage(messages[0]);
    }, [messages]);

    useEffect(() => {
        const checkScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) {
                return;
            }
            fetchMessages();
        };

        window.addEventListener('scroll', checkScroll);
        return () => window.removeEventListener('scroll', checkScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, hasMore]);

    useEffect(() => {
        fetchMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    console.log(selectedMessage);
    return (
        <div className="messaging-container">
            <div className="message-list">
                {messages.map(message => (
                    <div className='message-preview' key={message.id} onClick={() => setSelectedMessage(message)} style={{ cursor: 'pointer' }}>
                        <h4 className='message-header'>{message.userName} - {message.userRole}</h4>
                        <h4 className='message-header'>{message.timestamp.toDate().toLocaleString()}</h4>
                        <p className='message-body'>{message.content.substring(0, 100)}...</p>
                        <button className='message-delete-button'onClick={() => {
                            if (window.confirm('Are you sure you want to delete this message?')) {
                                deleteMessageFromDB(message.id);
                            }
                        }}>Delete</button>
                    </div>
                ))}
                {loading && <p>Loading more messages...</p>}
            </div>
            {/* The preview component remains unchanged */}
            <div className="message-view">
                {selectedMessage && (
                    <div>
                        <h3 className='message-header'>Name: {selectedMessage.userName}</h3>
                        <h3 className='message-header'>Email: {selectedMessage.userEmail}</h3>
                        <h3 className='message-header'>Role: {selectedMessage.userRole}</h3>
                        <h3 className='message-header'>Date: {selectedMessage.timestamp.toDate().toLocaleString()}</h3>
                        <br></br>
                        <p className='message-body'>{selectedMessage.content}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessagingInterface;