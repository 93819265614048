import AdminPortal from "./AdminPortal";
import ParentalPortal from "./ParentalPortal";
import StudentPortal from "./StudentPortal";
import { getAuth, signOut } from 'firebase/auth';
import { useContext } from "react";
import LoggedInContext from "../helpers/LoggedInContext";

const Dashboard = ({ userType }) => {
    // we need to reset all these values to their default on logout
    const { setIsLoggedIn, setUserRole, setUserUID, setAvatar, setName } = useContext(LoggedInContext);

    const handleLogout = async () => {
        try {
            const auth = getAuth();
            await signOut(auth);
            // we set all of the shared user context here to default values
            setIsLoggedIn(false);
            setUserRole(null);
            setUserUID('');
            setAvatar(null);
            setName(null);
        } catch (error) {
            console.error('Error while logging out:', error);
        }
    };

    return (
        <div className="scrollable-container">
            {(() => {
                switch (userType) {
                    case 'student':
                        return <StudentPortal handleLogout={handleLogout} />;
                    case 'parent':
                        return <ParentalPortal handleLogout={handleLogout} />;
                    case 'admin':
                        return <AdminPortal handleLogout={handleLogout} />;
                    default:
                        return (
                            <div>Issues displaying dashboard, please contact support.
                                <button style={{color: 'black'}} onClick={handleLogout}>Logout</button>
                            </div>);
                }
            })()}
        </div>
    );
};

export default Dashboard;