import React from 'react';
import './../../style/EventHistory.css';

const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    // Example: "September 13, 2023, 10:15 AM"
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleDateString(undefined, options);
};

const EventHistory = ({ events }) => {
    if (!events) {
        return null;
    }
    return (
        <div className="event-history">
            <h3 className='event-title'>Lesson History</h3>
            {events.length > 0 ? events.map((event, index) => {
                const humanFriendlyDate = formatDate(event.timestamp?.seconds * 1000);

                if (event.type === 'learningExperience') {
                    return (
                        <div key={index} className="event-card">
                            <p className='event-subtitle'><strong>Completed lesson:</strong> {event.name}</p>
                            <p className='event-subtitle'><strong>Time:</strong> {humanFriendlyDate}</p>
                        </div>
                    );
                } else if (event.type === 'endOfWeek') {
                    return (
                        <div key={index} className="event-card">
                            <p className='event-subtitle'><strong>Completed end of week quiz:</strong> {event.name}</p>
                            <p className='event-subtitle'><strong>Time:</strong> {humanFriendlyDate}</p>
                        </div>
                    );
                } else if (event.type === 'endOfModule') {
                    return (
                        <div key={index} className="event-card">
                            <p className='event-subtitle'><strong>Completed end of module quiz:</strong> {event.name}</p>
                            <p className='event-subtitle'><strong>Time:</strong> {humanFriendlyDate}</p>
                        </div>
                    );
                }
                return null; // Return null for other cases so React doesn't complain
            }) :
            <div>Press learn to start completing lessons...</div>}
        </div>
    );
};

export default EventHistory;
