import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import '../../style/ParentPortal.css';
import ContactUs from '../components/ContactUs';
import LinkedAccounts from '../components/LinkedAccounts';
import OurSupport from '../components/OurSupport';
import Payment from '../components/Payment';
import SideNav from '../components/SideNav';
import StudentSubjects from '../components/StudentSubjects';
import LinkAccount from '../components/LinkAccount';
import AccountPage from '../components/AccountPage';
import ParentTour from '../helpers/ParentTour';
import { useMessage } from '../helpers/MessageContext';

// firebase
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase_setup/firebase';


const CentralBox = ({ activeItem, changeContent }) => {
    useEffect(() => { });

    const renderContent = () => {
        console.log('activeItem: ', activeItem);
        switch (activeItem) {
            case 'linkedAccount':
                return <LinkedAccounts />
            case 'subjects':
                // TODO confirm this is ok
                // return <Subjects />;
                return <StudentSubjects/>
            case 'support':
                return <OurSupport setPageContent={changeContent} />;
            case 'linkAccount':
                return <LinkAccount setPageContent={changeContent} />;
            case 'payment':
                return <Payment />;
            case 'contact':
                return <ContactUs />;
            case 'account':
                return <AccountPage/>;
            default:
                return <LinkedAccounts />
        }
    };

    return (
        <AnimatePresence>
            <div className='central-box'>
                {renderContent()}
            </div>
            {/* <motion.div
                key={activeItem}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                // exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
                className="central-box"
            >
                {renderContent()}
            </motion.div> */}
        </AnimatePresence>
    );
};

const ParentalPortal = ({ handleLogout }) => {
    const [activeItem, setActiveItem] = useState('overview');
    const { newErrorMessage } = useMessage();

    const [isFirstVisit, setIsFirstVisit] = useState(null);
    // Add a state to control the tour
    const [isTourActive, setIsTourActive] = useState(false);  // set to false if you don't want the tour to start automatically

    useEffect(() => {
        checkFirstVisit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this checks if this is the first visit
    const checkFirstVisit = async () => {
        const user = auth.currentUser;

        if (!user) {
            // Handle user not logged in
            return;
        }

        const userUID = user.uid;

        if (!userUID) {
            // Handle user ID not found
            return;
        }

        try {
            const userDoc = await getDoc(doc(firestore, "users", userUID));
            
            if (userDoc.exists()) {
                const firstVisit = userDoc.data().hasOwnProperty('firstVisit') ? userDoc.data().firstVisit : true;
                console.log('setting tour active to: ', firstVisit);
                setIsFirstVisit(firstVisit);
                setIsTourActive(firstVisit);
            } else {
                // If the document doesn't exist, assume it's the user's first visit
                setIsFirstVisit(true);
                setIsTourActive(true);
                console.log('setting tour active')
            }
        } catch (error) {
            newErrorMessage('Error checking if tour complete. Starting anyways.');
            setIsFirstVisit(true);
            // Handle error
            console.log('err')
        }
        
    }

    const handleNavItemClick = (item) => {
        setActiveItem(item);
    };

    const handleTourCallback = async (data) => {
        // Handle the end of the tour or if the user skips it
        const { status } = data;
        if (status === 'finished' || status === 'skipped') {
            setIsTourActive(false);
        }

        if (status === 'finished' && isFirstVisit) {
            const user = auth.currentUser;
            const userUID = user?.uid;
    
            if (!user || !userUID) {
                // Handle user not logged in or user ID not found
                return;
            }
    
            try {
                await updateDoc(doc(firestore, "users", userUID), { firstVisit: false });
                setIsFirstVisit(false);
                // Handle successful update, e.g., show a success message
                // Here we want to also put the parent directly onto the payment page
                setActiveItem('payment');
            } catch (error) {
                newErrorMessage('Error saving tour progress.')
                // Handle error
            }
        } else {
            setActiveItem('linkedAccount');
        }
    };

    return (
        <div className="fill-screen fill-screen-h">
            <ParentTour run={isTourActive} callback={handleTourCallback} setActiveItem={setActiveItem} />
            <SideNav handleNavItemClick={handleNavItemClick} handleLogout={handleLogout} setIsTourActive={setIsTourActive}/>
            <CentralBox activeItem={activeItem} changeContent={handleNavItemClick} />
        </div>
    );
};

export default ParentalPortal;
