import { React, useState, useRef, useEffect } from 'react';
import StudentProgress from "./StudentProgress";
import { auth, firestore } from '../../firebase_setup/firebase';
import { doc, getDocs, getDoc, collection, query, where } from 'firebase/firestore';
// import LoggedInContext from '../helpers/LoggedInContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ComponentLoadingScreen from "../helpers/ComponentLoadingScreen";

// import ScoreChart from './ScoreChart';

const LinkedAccounts = () => {
    console.log('here!');
    // const { name } = useContext(LoggedInContext);
    const [linkedAccounts, setLinkedAccounts] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [componentLoading, setComponentLoading] = useState(true);

    const handlePreviousClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < linkedAccounts.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };


    useEffect(() => {
        const fetchLinkedAccounts = async () => {
            try {
                const userUID = auth.currentUser?.uid;
                if (!userUID) return;

                // First, retrieve the currently selected user's document.
                const usersCollectionRef = collection(firestore, 'users');
                const queryRef = query(usersCollectionRef, where('linkedAccount', '==', userUID));

                const querySnapshot = await getDocs(queryRef);

                if (querySnapshot.empty) {
                    console.error('No linked accounts found for the user.');
                    return;
                }

                // Collect linkedAccount UIDs from the query result.
                const linkedAccountUIDs = querySnapshot.docs.map(doc => doc.id);
                console.log('linkedAccountUIDs: ', linkedAccountUIDs);

                // Retrieve details for each linked account.
                const linkedAccountsData = [];
                for (const linkedAccountUID of linkedAccountUIDs) {
                    const linkedAccountDocRef = doc(firestore, 'users', linkedAccountUID);
                    const linkedAccountDocSnapshot = await getDoc(linkedAccountDocRef);

                    if (!linkedAccountDocSnapshot.exists()) {
                        console.error(`Linked account document not found for UID: ${linkedAccountUID}`);
                        continue;
                    }

                    linkedAccountsData.push({
                        accountId: linkedAccountDocSnapshot.id,
                        name: linkedAccountDocSnapshot.data().name,
                        subscriptions: linkedAccountDocSnapshot.data().subscriptions || []
                    });
                }

                setLinkedAccounts(linkedAccountsData);

            } catch (error) {
                console.error('Error fetching linked accounts:', error);
            } finally {
                setComponentLoading(false);
            }
        };

        fetchLinkedAccounts();
    }, []);

    const selectorStyle = {
        position: 'fixed',   /* Keeps the div in a fixed position */
        top: '7.5%',          /* Moves the top edge of the div to the center of the viewport */
        left: '50%',         /* Moves the left edge of the div to the center of the viewport */
        transform: 'translate(-50%, -50%)', /* Shifts the div back by half of its own width and height, ensuring it's centered */
        zIndex: '1000'     
    }
 
    

    const containerRef = useRef(null);

    if (componentLoading) {
        return (<ComponentLoadingScreen />)
    }

    if (!linkedAccounts || linkedAccounts.length === 0) {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <h2 className='main-header'>No Linked Accounts Found</h2>
                <p className='main-text'>You need to link to a student account to view their progress. Click 'Link Account' to begin the process.</p>
                {/* You can also add a button/link here to redirect the user to a page or open a modal where they can link accounts. */}
            </div>
        );
    }

    // TODO update this so that we can have an outer layer which will allow us to switch between the linked accounts
    return (
        <div ref={containerRef}>
            {linkedAccounts && <>
                <div style={selectorStyle}>
                    <ArrowBackIcon onClick={handlePreviousClick} style={{cursor: 'pointer', color: 'grey', marginRight: '7.5px'}}></ArrowBackIcon>
                    {/* <button onClick={handlePreviousClick} disabled={currentIndex === 0}>←</button> */}

                    <span className='main-header'>{linkedAccounts[currentIndex].name}</span>

                    <ArrowForwardIcon onClick={handleNextClick} style={{cursor: 'pointer', color: 'grey', marginLeft: '7.5px'}}></ArrowForwardIcon>
                </div>

                <StudentProgress
                    key={linkedAccounts[currentIndex].accountId}
                    studentId={linkedAccounts[currentIndex].accountId}
                    studentName={linkedAccounts[currentIndex].name}
                />
            </>}
        </div>
        // TODO add link new accounts here
    );
};

export default LinkedAccounts;
