import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { React, useEffect, useRef, useState, useCallback } from 'react';
import EditLesson from './EditLesson';
import { v4 as uuidv4 } from 'uuid';
import { useMessage } from '../../helpers/MessageContext';
import isEqual from 'lodash/isEqual';

const EditWeek = ({ week, expanded, toggleWeek, propagateHeight, changeWeekValue, deleteWeek, subjectId, moduleId }) => {
    const { newSuccessMessage, newErrorMessage } = useMessage();

    // for dealing with expanding things
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const [expandedLessons, setExpandedLessons] = useState({});

    const [weekState, setWeekState] = useState({
        week: week,
        lessons: week[1] ? week[1].lessons : null,
        isEditing: false,
        editedWeek: week,
        lesson_order: week[1] ? week[1].lesson_order : null
    });

    // this handles the changes being passed down into the object, when we add a new module
    useEffect(() => {
        setWeekState((prevState) => ({
            ...prevState,
            lessons: week[1] ? week[1].lessons : null,
            week: week,
            lesson_order: week[1] ? week[1].lesson_order : null
        }));
    }, [week]);

    useEffect(() => {
        if (!expanded) {
            setExpandedLessons({});
        }
        const height = expanded ? contentRef.current.scrollHeight : 0;
        setContentHeight(height);
        propagateHeight(height); // Pass the expanded height to the parent component
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded, weekState.isEditing]);

    useEffect(() => {
        let newWeek = JSON.parse(JSON.stringify(weekState.week)); // here we need to make a deep copy

        newWeek[Object.keys(weekState.week)[1]].lessons = weekState.lessons;
        newWeek[Object.keys(weekState.week)[1]].lesson_order = weekState.lesson_order;

        if (!isEqual(newWeek, weekState.week)) {
            // update subjects and then push them to the DB
            changeWeekValue(newWeek);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekState.lessons, weekState.lesson_order, weekState.week]);

    const deleteLesson = (lessonId) => {
        const newLessons = { ...weekState.lessons };

        if (window.confirm(`Are you sure you want to delete this lesson? \n ${newLessons[lessonId].name}. \n This action cannot be undone.`)) {
            delete newLessons[lessonId]; // This will remove the week from the copied object.

            const newLessonOrder = weekState.lesson_order.filter(id => id !== lessonId);

            setWeekState(prevState => ({
                ...prevState,
                lessons: newLessons,
                lesson_order: newLessonOrder
            }));

            newSuccessMessage('Lesson deleted successfully.');
        } else {
            // If the user cancels, do nothing
            newErrorMessage('Lesson deletion cancelled.');
        }

    }

    const updateHeight = useCallback((childHeight) => {
        const newHeight = contentHeight + childHeight;
        setContentHeight(newHeight);
        propagateHeight(contentHeight + childHeight);
    }, [contentHeight, propagateHeight]);

    // here we return nothing if the object is null
    if (week[1] === undefined) {
        return null;
    }

    const handleEditClick = () => {
        setWeekState((prevState) => ({
            ...prevState,
            isEditing: true,
            editedWeek: week
        }));
    };

    const handleWeekSubmit = (editedWeek) => {
        // change week value to new value and set editing to false
        // somehow when we call this, the upstream object is edited also
        changeWeekValue(Object.values(editedWeek));
        setWeekState((prevState) => ({
            ...prevState,
            isEditing: false
        }));
    };

    const changeLessonValue = (updatedValue) => {
        const newLessons = { ...weekState.lessons };
        newLessons[updatedValue[0]] = updatedValue[1];
        setWeekState((prevState) => ({
            ...prevState,
            lessons: newLessons,
        }));
    };


    const toggleLesson = (lessonId) => {
        setExpandedLessons((prevExpandedLessons) => ({
            // ...prevExpandedLessons,
            [lessonId]: !prevExpandedLessons[lessonId],
        }));
    };


    const handleToggle = () => {
        toggleWeek(week[1].name);
    };

    // const updateHeight = (childHeight) => {
    //     // this takes the height and adds the child height to it
    //     propagateHeight(contentHeight + childHeight);
    //     setContentHeight(contentHeight + childHeight);
    // };


    const addNewLesson = () => {
        const lessonId = uuidv4();
        const newLesson = {
            [lessonId]: { description: 'a new lesson', name: 'new lesson', src: '' }
        };
        setWeekState((prevState) => ({
            ...prevState,
            lessons: { ...newLesson, ...weekState.lessons },
            lesson_order: [...weekState.lesson_order, lessonId]
        }));
        updateHeight();
    }

    if (weekState.isEditing) {
        return <li className='week-container'>
            <div>
                <input
                    className='week-input'
                    type="text"
                    placeholder={weekState.editedWeek[1].name}
                    onChange={(e) => setWeekState(prevState => ({
                        ...prevState,
                        editedWeek: {
                            ...prevState.editedWeek,
                            "1": {
                                ...prevState.editedWeek["1"],
                                name: e.target.value
                            }
                        }
                    }))}
                    disabled={!weekState.isEditing} // Disable the input field when not in editing mode
                />
                <button className="week-header" onClick={handleToggle}>
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <button className="week-edit" onClick={() => handleWeekSubmit(weekState.editedWeek)}>Save</button>
            </div>
            {expanded &&
                <div
                    className={`week-description ${expanded ? 'expanded' : ''}`}
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    <input
                        className='week-input'
                        type="text"
                        // value={editedModule[1].description}
                        placeholder={weekState.editedWeek[1].description}
                        onChange={(e) => setWeekState(prevState => ({
                            ...prevState,
                            editedWeek: {
                                ...prevState.editedWeek,
                                "1": {
                                    ...prevState.editedWeek["1"],
                                    description: e.target.value
                                }
                            }
                        }))}
                        disabled={!weekState.isEditing} // Disable the input field when not in editing mode
                    />
                    <ul className={`week-content ${expanded ? 'expanded' : ''}`}>
                        {Object.entries(week[1].lessons) &&
                            weekState.lesson_order.map((lessonId) => (
                                <EditLesson
                                    key={lessonId}
                                    lesson={Object.entries({ [lessonId]: weekState.lessons[lessonId] })[0]}
                                    expanded={expandedLessons[lessonId]}
                                    toggleLesson={() => toggleLesson(lessonId)}
                                    propagateHeight={updateHeight}
                                    changeLessonValue={changeLessonValue}
                                    deleteLesson={deleteLesson}
                                    subjectId={subjectId}
                                    moduleId={moduleId}
                                    weekId={week[0]}
                                />
                            ))}
                    </ul>
                    <button className='add-subject' onClick={addNewLesson}>Add new Lesson</button>
                </div>}
        </li>;
    }
    return (
        <li className='week-container'>
            <div>
                <button className="week-header" onClick={handleToggle}>
                    {week[1].name}
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <button className="week-edit" onClick={handleEditClick}>Edit</button>
                <button className="week-edit" onClick={() => deleteWeek(week[0])}>Delete</button>
            </div>
            {expanded &&
                <div
                    className={`week-description ${expanded ? 'expanded' : ''}`}
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    {week[1].description}
                    <ul className={`week-content ${expanded ? 'expanded' : ''}`}>
                        {Object.entries(week[1].lessons) &&
                            weekState.lesson_order.map((lessonId) => (
                                <EditLesson
                                    key={lessonId}
                                    lesson={Object.entries({ [lessonId]: weekState.lessons[lessonId] })[0]}
                                    expanded={expandedLessons[lessonId]}
                                    toggleLesson={() => toggleLesson(lessonId)}
                                    propagateHeight={updateHeight}
                                    changeLessonValue={changeLessonValue}
                                    deleteLesson={deleteLesson}
                                    subjectId={subjectId}
                                    moduleId={moduleId}
                                    weekId={week[0]}
                                />
                            ))}
                    </ul>
                    <button className='add-subject' onClick={addNewLesson}>Add new Lesson</button>
                </div>}
        </li>
    );
};

export default EditWeek;