// App.js
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import LoggedInContext from './components/helpers/LoggedInContext';
import ConfigureAccount from './components/pages/ConfigureAccount';
import Dashboard from './components/pages/Dashboard';
import LoginPage from './components/pages/LoginPage';
import AlertStack from './components/helpers/AlertStack';
import { useMessage } from './components/helpers/MessageContext';
import { auth, firestore } from './firebase_setup/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import AppLoadingScreen from './components/helpers/AppLoadingScreen';
import Payment from './components/components/Payment';

const darkTheme = createTheme({
  palette: {
    type: 'light',
  },
});

// exports main function called app
function App() {
  const { userRole, setUserRole } = useContext(LoggedInContext);
  const { errorMessage, successMessage, timestamp } = useMessage();
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [appLoading, setAppLoading] = useState(true);
  const [isFirstVisit, setIsFirstVisit] = useState(null);

  console.log('app is starting');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setFirebaseUser(user);

      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserRole(userData.role);
          setIsFirstVisit(userData.firstVisit || false);
        } else {
          console.error('User document does not exist or has not been created yet!');
        }
      } else {
        setUserRole(null); // Clear user role if not logged in
      }
      setAppLoading(false);
    });

    return () => unsubscribe();  // Cleanup on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (appLoading) {
    console.log('app is loading');
    return <AppLoadingScreen />;
  }

  if (!firebaseUser) {
    console.log('app is logging in.');
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Container disableGutters maxWidth={false}>
          <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
          <LoginPage />
        </Container>
      </ThemeProvider>
    )
  } else if (!userRole) {
    console.log('app is configuring account');
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Container disableGutters maxWidth={false}>
          <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
          <ConfigureAccount />
        </Container>
      </ThemeProvider>
    )
  } else if (userRole === 'parent' && isFirstVisit === true ) {
    <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Container disableGutters maxWidth={false}>
          <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
          <Payment />
        </Container>
      </ThemeProvider>
  } else {
    console.log('app is showing dashboard for: ', userRole);
    return (<ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container disableGutters maxWidth={false}>
        <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
        <Dashboard userType={userRole} />
      </Container>
    </ThemeProvider>)
  }
}

export default App;
