import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { React, useEffect, useRef, useState } from 'react';
import Lesson from './Lesson';


const Week = ({ week, expanded, toggleWeek, propagateHeight, subscribed }) => {
    console.log('week at start of week: ');
    console.log(week);
    console.log(week[0]);
    console.log(week[1]);

    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const [expandedLessons, setExpandedLessons] = useState({});

    const toggleLesson = (lessonId) => {
        setExpandedLessons((prevExpandedLessons) => ({
            [lessonId]: !prevExpandedLessons[lessonId],
        }));
    };

    useEffect(() => {
        if (!expanded) {
            setExpandedLessons({});
        }
        const height = expanded ? contentRef.current.scrollHeight : 0;
        setContentHeight(height);
        propagateHeight(height); // Pass the expanded height to the parent component
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const handleToggle = () => {
        toggleWeek(week[1].name);
    };

    const updateHeight = (height) => {
        const newContentHeight = contentHeight + height;
        setContentHeight(newContentHeight);
        propagateHeight(newContentHeight); // Pass the updated content height to the parent component
    };

    return (
        <li className='week-container'>
            <button className="week-header" onClick={handleToggle}>
                {week[1].name}
                {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </button>
            {expanded &&
                <div
                    className={`week-description ${expanded ? 'expanded' : ''}`}
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    {week[1].description}
                    <ul className={`module-content ${expanded ? 'expanded' : ''}`}>
                        {Object.entries(week[1].lessons) &&
                            // module[1].week_order.map((weekId) => (
                            Object.values(week[1].lesson_order).map((lessonId) => (
                                <Lesson
                                    key={lessonId}
                                    lesson={[lessonId, week[1].lessons[lessonId]]}
                                    expanded={expandedLessons[lessonId]}
                                    toggleLesson={() => toggleLesson(lessonId)}
                                    propagateHeight={updateHeight}
                                    subscribed={subscribed}
                                />
                            ))}
                        {/* {Object.entries(week[1].lessons) && Object.entries(week[1].lessons).map((lesson) => (
                            <Lesson
                                key={lesson[1].name}
                                lesson={lesson}
                                expanded={expandedLessons[lesson[1].name]}
                                toggleLesson={() => toggleLesson(lesson[1].name)}
                                propagateHeight={updateHeight}
                                subscribed={subscribed}
                            />
                        ))} */}
                    </ul>
                </div>}
        </li>
    );
};

export default Week;