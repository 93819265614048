// Styles
import '../../style/ConfigureAccount.css';

// React stuff
import { useContext, useState, useEffect } from 'react';

// firebase
import { doc, setDoc, getDoc, getDocs, collection, query, where } from "@firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase';

// LoggedInContext
import LoggedInContext from '../helpers/LoggedInContext'; // Make sure to import the context

// Avatar Select
import AvatarSelect from '../components/AvatarSelect';

import ComponentLoadingScreen from '../helpers/ComponentLoadingScreen';
import LogoutComponent from '../helpers/LogoutComponent';
import { useMessage } from '../helpers/MessageContext';

const ConfigureAccount = () => {
    const { setUserRole, setAvatar, name, setName } = useContext(LoggedInContext); // Get the setIsLoggedIn and setUserRole functions from the context
    const [selectedRole, setSelectedRole] = useState('parent'); // Default selection is parent
    const [avatar, setSelectedAvatar] = useState('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYPRLSISP2uoEdGxNPVFrz02gI2KWiJ_VwNA&usqp=CAU');

    const [hasLinkRequest, setHasLinkRequest] = useState(false);
    const [inputEmail, setInputEmail] = useState("");
    const [linkRequestId, setLinkRequestId] = useState(null);
    const [requestedRole, setRequestedRole] = useState(null);
    const [requestingUserName, setRequestingUserName] = useState("");
    const [isRoleLocked, setIsRoleLocked] = useState(false);

    const [componentLoading, setComponentLoading] = useState(true);
    const { newErrorMessage, newSuccessMessage } = useMessage();

    const handleAvatarSelect = (avatar) => {
        setSelectedAvatar(avatar);
    };

    // UpdateSubscription function defined elsewhere in your code
    const updateSubscription = async () => {
        const user = auth.currentUser;
        const idToken = await user.getIdToken();

        const payload = JSON.stringify({
            userEmail: user.email,
            userId: user.uid
        });

        const endpoint = process.env.REACT_APP_ENABLE_SUBSCRIPTION; // Replace with your actual endpoint

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`  // Use Bearer for standard auth format
                },
                body: payload
            });

            const data = await response.json();
            if (response.ok) {
                if (data === 'No active subscription found for the given email') {
                    // do nothing as no subscription was found to update, either not subscribed or already updated
                } else {
                    newSuccessMessage(data.message || 'Subscription added to account successfully!');
                }
                
            } else {
                // console.log('Didn\'t update subscription');
                newErrorMessage(data.error || 'Error updating subscription.');
            }
        } catch (error) {
            console.log('Failed to update subscription.')
            console.log(error);
            newErrorMessage('Failed to update subscription.');
        }
    };

    const checkForLinkRequest = async () => {
        const userEmail = auth.currentUser.email;
        console.log('search db for: ', userEmail);
        try {
            const emailQuerySnapshot = await getDocs(query(collection(firestore, "linkRequests"), where("toEmail", "==", userEmail)));

            if (!emailQuerySnapshot.empty) {
                const linkRequest = emailQuerySnapshot.docs[0].data();
                console.log('linkRequest id: ', emailQuerySnapshot.docs[0].id);
                setLinkRequestId(emailQuerySnapshot.docs[0].id);
                // Fetching the name of the requesting user
                const requestingUserDoc = await getDoc(doc(firestore, "users", linkRequest.from));

                const requestingUserName = requestingUserDoc.data().name;

                setRequestingUserName(requestingUserName);
                if (requestingUserDoc.data().role === 'parent') {
                    setRequestedRole('student')
                    setSelectedRole('student')
                    // here we want to check for an active subscription
                    await updateSubscription();
                } else {
                    setRequestedRole('parent')
                    setSelectedRole('parent')
                }
                setIsRoleLocked(true);
                setHasLinkRequest(true);
            }
        } catch (error) {
            newErrorMessage('Error loading data, please refresh the page.')
            console.error("Error checking link request:", error);
        } finally {
            setComponentLoading(false);
        }
    };

    useEffect(() => {
        checkForLinkRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to handle the form selection change
    const handleSelectionChange = (event) => {
        if (event.target.checked) {
            console.log('setting to student');
            setRequestedRole('student');
        } else {
            console.log('setting to parent');
            setRequestedRole('parent');
        }
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleLinkAccount = async () => {
        const user = auth.currentUser;

        if (!user) {
            throw new Error('User not authenticated');
        }

        // Get ID token of the user
        const idToken = await user.getIdToken();

        // Define your request payload
        const payload = JSON.stringify({
            from: user.uid,
            email: inputEmail
        });

        // Define your cloud function endpoint
        const endpoint = process.env.REACT_APP_GENERATE_LINK_REQUEST;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`  // Use Bearer for standard auth format
                },
                body: payload
            });

            const data = await response.json();
            if (data.success) {
                newSuccessMessage(data.message || `Request sent successfully!`);
            } else {
                newErrorMessage(data.error || `Error processing request.`);
            }
        } catch (error) {
            console.error("Error calling cloud function:", error);
            newErrorMessage(`Failed to send request.`);
        }
    };

    const handleAcceptLinkRequest = async () => {
        console.log('Accepting link request!');

        if (!hasLinkRequest) { // Assuming hasLinkRequest state determines if there's a pending request
            newErrorMessage('No link request found.'); // Using custom error popup here
            return;
        }

        try {
            const url = process.env.REACT_APP_ACCEPT_LINK_REQUEST; // Cloud function URL
            const user = auth.currentUser;

            if (!user) {
                newErrorMessage('User not authenticated'); // Using custom error popup here
                return;
            }

            // Get ID token of the user
            const idToken = await user.getIdToken();

            // Here, I assume that the linkRequest object you fetched earlier has a field `id` that represents its document ID in Firestore.
            // This ID is required to identify which linkRequest to accept in the backend.
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    linkRequestId: linkRequestId
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            if (responseData.success) {
                console.log('Link request accepted:', responseData.message);
                newSuccessMessage('Link request successfully accepted.'); // Using custom success popup here
                setHasLinkRequest(false); // Set hasLinkRequest to false as the request is now accepted
            } else {
                console.error('Link request acceptance failed:', responseData.message);
                newErrorMessage(responseData.message); // Using custom error popup here
            }

        } catch (error) {
            newErrorMessage('Failed to accept the link request.'); // Using custom error popup here
        }
    };

    // const saveUserState = async () => {
    //     // data to save
    //     const userData = { avatar: avatar, role: selectedRole, name: name };

    //     const userUID = auth.currentUser.uid;
    //     try {
    //         await setDoc(doc(firestore, "users", userUID), userData);
    //         // This code will run after the document has been successfully set in Firestore
    //         setUserRole(selectedRole);
    //         setAvatar(avatar);
    //         setName(name);
    //     } catch (error) {
    //         // TODO handle errors here!
    //         console.error("Error setting document:", error);
    //     }

    //     try {
    //         if (hasLinkRequest) {
    //             handleAcceptLinkRequest()
    //         }

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleFinalizeAccount = async () => {
        // First, save the user state
        const userData = { avatar: avatar, role: selectedRole, name: name };
        const userUID = auth.currentUser.uid;

        try {
            await setDoc(doc(firestore, "users", userUID), userData);
            setUserRole(selectedRole);
            setAvatar(avatar);
            setName(name);

            // If there's a link request, handle it
            if (hasLinkRequest) {
                await handleAcceptLinkRequest();
            } else {
                // Otherwise, if the user is trying to link to another account
                if (inputEmail) {
                    await handleLinkAccount();
                }
            }
        } catch (error) {
            console.error("Error in handleFinalizeAccount:", error);
            newErrorMessage("Failed to finalize account configuration.");
        }
    }



    if (componentLoading) {
        return <ComponentLoadingScreen />
    }

    return (
        <div className='configure-container'>
            <h1 className="configure-header">Account Configuration</h1>
            {hasLinkRequest && (
                <div className='link-request-notification'>
                    You have a link request from {requestingUserName} to be a {requestedRole}. Your account type has been set accordingly.
                </div>
            )}
            {/* Selection form for choosing parent or child */}
            <div class='checkbox-container'>
                <div class="checkbox-wrapper-35">
                    <input value="private" name="switch" id="switch" type="checkbox" class="switch" onChange={handleSelectionChange} disabled={isRoleLocked} checked={requestedRole === 'student'} />
                    <label for="switch">
                        <span class="switch-x-text">I am a </span>
                        <span class="switch-x-toggletext">
                            <span class="switch-x-unchecked"><span class="switch-x-hiddenlabel">Unchecked: </span>Parent</span>
                            <span class="switch-x-checked"><span class="switch-x-hiddenlabel">Checked: </span>Student</span>
                        </span>
                    </label>
                </div>
            </div>
            <div class="wave-group">
                <input required="" type="text" class="input" onChange={handleNameChange} />
                <span class="bar"></span>
                <label class="label">
                    <span class="label-char" style={{ index: 0 }}>N</span>
                    <span class="label-char" style={{ index: 1 }}>a</span>
                    <span class="label-char" style={{ index: 2 }}>m</span>
                    <span class="label-char" style={{ index: 3 }}>e</span>
                </label>
            </div>
            <div className='avatar-container'>
                <div className="avatar-header">Select your avatar</div>
                <AvatarSelect onSelect={handleAvatarSelect} />
                {!hasLinkRequest &&
                    <>
                        {requestedRole === 'student' ? <p className='la-para'>Enter a parents email to link accounts:</p> : <p className='la-para'>Enter a students email to link accounts:</p>}
                        <input
                            className='la-input'
                            value={inputEmail}
                            onChange={(e) => setInputEmail(e.target.value)}
                            placeholder="Enter Email"
                        />
                        {/* <button className='la-button' onClick={handleLinkAccount}>Send Request</button> */}
                    </>
                }
            </div>
            <button className='confirm-button' onClick={handleFinalizeAccount}>Continue</button>
            <LogoutComponent/>
        </div>
    );
};

export default ConfigureAccount;