import { doc, getDoc } from "@firebase/firestore";
import { React, useContext, useEffect } from 'react';
import { firestore, auth } from '../../firebase_setup/firebase';
import LoggedInContext from '../helpers/LoggedInContext';

const UserCard = ({ onClick }) => {
  const { avatar, setAvatar, name, setName } = useContext(LoggedInContext);

  const loadUser = async () => {
    console.log('loading user');
    try {
      const userUID = auth.currentUser.uid;
      const docRef = doc(firestore, 'users', userUID);
      const docSnap = await getDoc(docRef);
      setAvatar(docSnap.get('avatar'));
      setName(docSnap.get('name'));
    } catch (error) {
      console.error('Error loading avatar: ', error)
    }
  }

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="user-card" onClick={onClick}>
      <img className="user-avatar" src={avatar} alt="User Avatar" />
      <p className="user-name">{name}</p>
    </div>
  );
};

export default UserCard;
