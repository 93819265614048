import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './../../style/Subjects.css';
import EditSubject from './subject/EditSubject';

import { collection, doc, getDocs, setDoc } from "@firebase/firestore";
import { firestore } from '../../firebase_setup/firebase';


const pushSubjectFirestore = async (subjectId, subjectObject) => {
    console.log('attempting to push to firestore: ', subjectObject)
    // updates a single subject in the firestore backend
    try {
        const subjectRef = doc(firestore, "subjects", subjectId);
        await setDoc(subjectRef, subjectObject);
    } catch (error) {
        console.error('Error in EditSubjects.updateSubjectFirestore(): ', error);
    }
};


const EditSubjects = () => {
    const [subjects, setSubjects] = useState([]);

    const changeSubjectValue = (updatedValue) => {
        // updates the value of a subject using an index and newValue
        const index = Object.keys(updatedValue)[0];
        const newValue = updatedValue[Object.keys(updatedValue)[0]];

        // this loops the updated subjects, and checks
        const updatedSubjects = subjects.map((subject, i) => {
            if (Object.keys(subject)[0] === index) {
                return {
                    ...subject,
                    [Object.keys(updatedValue)[0]]: newValue
                  };
            }
            return subject;
          });


        setSubjects(updatedSubjects);
    };

    function updateSubjects(id, newData) {
        // updates the subjects array using an id (index) and new data (a subject)

        // Find the index of the object we want to update
        const subjectIndex = subjects.findIndex(subject => Object.keys(subject)[0] === id);

        if (subjectIndex !== -1) {
            // We found the object, so create a copy of the array
            const newSubjects = [...subjects];
            // Replace the object at the found index with the new data
            newSubjects[subjectIndex] = { [id]: newData };
            // Update the state
            setSubjects(newSubjects);
        } else {
            console.error(`No subject found with id ${id}`);
        }
    }

    const docToJSON = (doc) => {
        // function to convert docs from firestore to JSON
        if (!doc.exists()) return null;
      
        const data = doc.data();
        return {
          [doc.id]: data,
        };
      };


    useEffect(() => {
        // on component mount, we pull in the subject data into our app
        const fetchData = async () => {

            try {
                const querySnapshot = await getDocs(collection(firestore, "subjects"));
                const subjectsList = [];

                querySnapshot.forEach((doc) => {
                  const subjectJSON = docToJSON(doc);
                  if (subjectJSON) {
                    subjectsList.push(subjectJSON);
                  }
                });
                setSubjects(subjectsList);
            } catch (error) {
                console.error('Error fetching subject data: ', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // when subjects change is detected, we push the changes to firestore
        try {
            subjects.forEach((subjectItem) => {
                // TODO would it be good to push this data to BQ
                // it's free and would be disaster recovery
                pushSubjectFirestore(Object.keys(subjectItem)[0], Object.values(subjectItem)[0]);
            });
        } catch (error) {
            console.error('Error pushing subject to firestore: ', error);
        }
    }, [subjects])

    const addNewSubject = () => {
        // when add new subject button is pressed, this adds a new subject to the array
        // TODO check if we need to create a fully nested object, or can we leave modules etc as undefined
        const moduleId = uuidv4();
        const weekId = uuidv4();
        const lessonId = uuidv4();
        const newSubject = {
            [uuidv4()]: { name: 'New Subject', description: 'New Subject', module_order: [moduleId], modules: {[moduleId]:{description: 'a new module', name: 'new module', week_order: [weekId], weeks: {[weekId]:{description: 'a new week', name: 'new week', lesson_order: [lessonId], lessons:{[lessonId]:{description:'a new lesson', name:'new lesson', src:''}}}}}}}
          };
        setSubjects([newSubject, ...subjects]);
    }
    
    return (
        <div>
            <main>
                <section className="subjects">
                    <h1 className='main-header'>Subjects</h1>
                    {subjects && (
                        <div>
                            {Object.entries(subjects) && Object.entries(subjects).map(([id, subject]) => (
                                <EditSubject key={id} subject={subject} updateSubjects={updateSubjects} changeSubjectValue={changeSubjectValue} />
                            ))}
                        </div>
                    )}
                    <button className='add-subject' onClick={addNewSubject}>Add new subject</button>
                </section>
            </main>
        </div>
    );
};

export default EditSubjects;