import { React, useState } from 'react';
import { AddressElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { auth } from '../../firebase_setup/firebase';
import '../../style/PaymentComponent.css';
import { useMessage } from '../helpers/MessageContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PaymentComponent({ basket, setPayment, reload }) {
    // basket contains what we are buying
    // setPayment is a function which we can use to trigger a back button - setPayment(false) will go to previous screen
    console.log('basket: ', basket);
    const basketItems = basket.map(item => ({
        action: item.action,
        price: item.price,
        subjectId: item.subject.id,
        subjectName: item.subject.name,
        accountName: item.account.name,
        accountId: item.account.accountId,
        linkRequest: item.account.linkRequest
    }));
    console.log('basketItems: ', basketItems);
    const stripe = useStripe();
    const elements = useElements();
    const totalAmount = basket.reduce((sum, item) => sum + item.price, 0);
    const { newErrorMessage, newSuccessMessage } = useMessage();
    const [paymentMade, setPaymentMade] = useState(false);
    const [loading, setLoading] = useState(false);

    const [billingDetails, setBillingDetails] = useState({
        name: "",
        address: {
            line1: "",
            city: "",
            state: "",
            postal_code: "",
            country: ""
        }
    });
    const [detailsFilled, setDetailsFilled] = useState(false);


    const handlePayment = async () => {
        setLoading(true);
        const url = process.env.REACT_APP_SUBSCRIBE_ENDPOINT;

        const user = auth.currentUser;
        if (!user) {
            newErrorMessage('User not authenticated');
            setLoading(false);
            return;
        }

        // Skip payment details if the total amount is £0
        if (totalAmount === 0) {
            try {
                // Get ID token of the user
                const idToken = await user.getIdToken();

                // Call the cloud function directly with basketItems and billingDetails
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                    body: JSON.stringify({
                        basket: basketItems,
                        billing_details: billingDetails
                    }),
                });

                // Handle the response from the cloud function
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();

                if (responseData.error) {
                    console.error('Subscription creation failed:', responseData.error);
                    newErrorMessage('Subscription creation error. Please try again later.');
                } else {
                    console.log('Subscription created successfully:', responseData);
                    newSuccessMessage('Subscription created successfully. You are now subscribed.');
                    setPaymentMade(true);
                }
            } catch (error) {
                console.error('There was a problem with the subscription creation:', error);
                newErrorMessage('There was an issue creating your subscription. Please try again later.');
            }
            setLoading(false);
            reload(); // Persist the changes by reloading data
            return; // Exit the function early since no payment is needed
        }

        try {
            // Get ID token of the user
            const idToken = await user.getIdToken();

            if (!stripe || !elements) {
                newErrorMessage('Failed to connect to stripe. Please try again later.');
                setLoading(false);
                return;
            }

            const result = await stripe.createToken(elements.getElement(CardElement));

            if (!result.token) {
                newErrorMessage(result.error ? result.error.message : 'Failed to create token. Please try again.');
                setLoading(false);
                return;
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    token: result.token.id,
                    basket: basketItems,
                    billing_details: billingDetails
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            if (responseData.error) {
                console.error('Payment failed:', responseData.error);
                newErrorMessage('Payment error. Please try again later.');
            } else {
                console.log('Payment successful:', responseData);
                newSuccessMessage('Payment successful. You are now subscribed.');
                setPaymentMade(true);
            }
        } catch (error) {
            console.error('There was a problem with the payment:', error);
            newErrorMessage('There was an issue processing your payment. Please try again later.');
        }
        // reload refreshes the data from the DB to the frontend to persist the changes
        reload();
        setLoading(false);
    };

    const handleBack = () => {
        setPayment(false);
    }

    if (paymentMade) {
        return (
            <div className='payment-box'>
                <button className="back-button" onClick={() => handleBack()}>
                    <ArrowBackIcon fontSize="large" />
                </button>
                <h2 className='main-header-1'>Payment successful.</h2>
                {/* <table>
                    <thead>
                        <tr>
                            <th className='main-text'>Changes:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {finalBasket.map(item => (
                            <tr key={`${item.account.name}-${item.subject.id}`}>
                                <td className='main-text'>{item.action}d {item.account.name} to {item.subject.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}
            </div>
        )

    }
    console.log('totalAmount: ', totalAmount);
    console.log(totalAmount === 0)
    if (totalAmount === 0) {
        console.log('returning early!');
        return (
        <div className='payment-box'>
            <button className="back-button" onClick={() => handleBack()}>
                <ArrowBackIcon fontSize="large" />
            </button>
            <div>
                <h2 className='main-header-1'>Subscription Changes</h2>
                <div className='account-container sub-changes'>
                    <table>
                        <thead>
                            <tr>
                                <th>Change</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {basket.map(item => (
                                <tr key={`${item.account.name}-${item.subject.id}`}>
                                    <td>{item.action} {item.account.name} to {item.subject.name}</td>
                                    <td>£{item.price}/month</td>
                                </tr>
                            ))}
                            <tr>
                                <td><strong>Total</strong></td>
                                <td>£{totalAmount}/month</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {totalAmount > 0 ? (
                detailsFilled ? (
                    loading ? (
                        <button className='pay-button' disabled={true}>Loading...</button>
                    ) : (
                        <button className='pay-button' onClick={handlePayment}>Pay £{totalAmount}</button>
                    )
                ) : (
                    <button className='x-pay-button' disabled={true} style={{ opacity: '0.5', cursor: 'not-allowed' }}>Pay £{totalAmount}</button>
                )
            ) : loading ? (
                <button className='pay-button' disabled={true}>Loading...</button>
            ) : (
                <button className='pay-button' onClick={handlePayment}>Confirm Subscription</button>
            )}
        </div>
        )
    }
    return (
        <div className='payment-box'>
            <button className="back-button" onClick={() => handleBack()}>
                <ArrowBackIcon fontSize="large" />
            </button>
            <div>
                <h2 className='main-header-1'>Subscription Changes</h2>
                <div className='account-container sub-changes'>
                    <table>
                        <thead>
                            <tr>
                                <th>Change</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {basket.map(item => (
                                <tr key={`${item.account.name}-${item.subject.id}`}>
                                    <td>{item.action} {item.account.name} to {item.subject.name}</td>
                                    <td>£{item.price}/month</td>
                                </tr>
                            ))}
                            <tr>
                                <td><strong>Total</strong></td>
                                <td>£{totalAmount}/month</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <form>
                <h3>Billing Address</h3>
                <AddressElement options={{ mode: 'billing' }}
                    onChange={(event) => {
                        console.log(event);
                        if (event.complete) {
                            setDetailsFilled(true);
                            setBillingDetails(prevState => ({
                                address: event.value,
                                name: event.name
                            }));
                        } else {
                            // if the details are filled and they have become unfilled revoke the flag
                            if (detailsFilled === true) {
                                setDetailsFilled(false);
                            }

                            console.log('Form not filled out, missing fields.')
                        }
                    }}
                />
                <h3>Card Details</h3>
                {/* <CardElement /> */}
                <CardElement />
            </form>
            {totalAmount > 0 ? (
                detailsFilled ? (
                    loading ? (
                        <button className='pay-button' disabled={true}>Loading...</button>
                    ) : (
                        <button className='pay-button' onClick={handlePayment}>Pay £{totalAmount}</button>
                    )
                ) : (
                    <button className='x-pay-button' disabled={true} style={{ opacity: '0.5', cursor: 'not-allowed' }}>Pay £{totalAmount}</button>
                )
            ) : loading ? (
                <button className='pay-button' disabled={true}>Loading...</button>
            ) : (
                <button className='pay-button' onClick={handlePayment}>Confirm Subscription</button>
            )}
        </div>
    );
}

export default PaymentComponent;
