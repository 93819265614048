import { React, useState } from 'react';
import Module from './Module';

const Subject = ({ subject }) => {
    const [expandedModules, setExpandedModules] = useState({});
  
    const toggleModule = (moduleId) => {
      setExpandedModules((prevExpandedModules) => ({
        [moduleId]: !prevExpandedModules[moduleId],
      }));
    };
  
    if (subject) {
      return (
        <div className="subject-container">
          <h2 className='subject-header'>{subject[Object.keys(subject)[0]].name}</h2>
          <p className='subject-description'>{subject[Object.keys(subject)[0]].description}</p>
          <ul>
          {Object.entries(subject[Object.keys(subject)[0]].modules) &&
                        subject[Object.keys(subject)[0]].module_order.map((moduleId) => (
                            <Module
                                key={moduleId}
                                module={Object.entries({ [moduleId]: subject[Object.keys(subject)[0]].modules[moduleId] })[0]}
                                expanded={expandedModules[moduleId]}
                                toggleModule={() => toggleModule(moduleId)}
                                subscribed={subject[Object.keys(subject)[0]].subscribed}
                            />
                        ))}
            {/* {Object.entries(subject[Object.keys(subject)[0]].modules) && Object.entries(subject[Object.keys(subject)[0]].modules).map((module) => (
              <Module
                key={module[0]}
                module={module}
                expanded={expandedModules[module[0]]}
                toggleModule={() => toggleModule(module[0])}
                subscribed={subject[Object.keys(subject)[0]].subscribed}
              />
            ))} */}
          </ul>
        </div>
      );
    } else {
      return <div></div>
    }
  
  };

export default Subject;