import React, { useState } from 'react';
import { auth } from '../../firebase_setup/firebase';
import { collection, query, where, getDocs, writeBatch } from "firebase/firestore";
import { firestore } from '../../firebase_setup/firebase';
import '../../style/CSVUploader.css';
import AlertStack from '../helpers/AlertStack';

function CsvUpload({ currentLessonID, lessonName, subjectId, moduleId, weekId }) {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [openQuestions, setOpenQuestions] = useState([]);
  const [multiQuestions, setMultiQuestions] = useState([]);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [isLoadingMulti, setIsLoadingMulti] = useState(false);
  const [isLoadingDownMulti, setIsLoadingDownMulti] = useState(false);
  const [isLoadingDownOpen, setIsLoadingDownOpen] = useState(false);
  const [isLoadingDeleteMulti, setIsLoadingDeleteMulti] = useState(false);
  const [isLoadingDeleteOpen, setIsLoadingDeleteOpen] = useState(false);

  // Error state is handled here
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());

  const triggerNewErrorMessage = (newMessage) => {
    setTimestamp(Date.now());
    setErrorMessage(newMessage);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  const triggerNewSuccessMessage = (newMessage) => {
    setTimestamp(Date.now());
    setSuccessMessage(newMessage);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    setUploading(true);

    // Get auth token
    const user = auth.currentUser;
    if (!user) {
      triggerNewErrorMessage('User not authenticated. Please relogin.');
      throw new Error('User not authenticated');
    }

    const devUrl = process.env.REACT_APP_UPLOAD_CSV;

    // Get ID token of the user
    const idToken = await user.getIdToken();

    // Create a FormData object and append the file
    const formData = new FormData();
    formData.append('file', file);
    formData.append('currentLessonID', currentLessonID);
    formData.append('subjectId', subjectId);
    formData.append('moduleId', moduleId);
    formData.append('weekId', weekId);

    // Send the file to the cloud function
    fetch(devUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
      },
      body: formData,  // This FormData object contains your file
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        triggerNewSuccessMessage('Successfully uploaded questions.');
        setUploading(false);
      })
      .catch((error) => {
        triggerNewErrorMessage('Error uploading. If this error persists, please contact support.');
        console.error('Error:', error);
        setUploading(false);
      });
  };

  // const handleUpload = async () => {
  //   console.log('creating storage ref for: ', `csv-files/${file.name}`)
  //   const storageRef = ref(storage, `csv-files/${file.name}`);

  //   setUploading(true);
  //   const uploadTask = uploadBytesResumable(storageRef, file);
  //   uploadTask.on('state_changed',
  //     (snapshot) => {
  //       // You can add some progress functionality here with snapshot.bytesTransferred and snapshot.totalBytes
  //     },
  //     (error) => {
  //       // Handle unsuccessful uploads
  //       console.error("Error while uploading: ", error);
  //       // print error to screen here
  //       setUploading(false);
  //     },
  //     async () => {
  //       // Upload completed successfully

  //       // get auth token
  //       const user = auth.currentUser;
  //       if (!user) {
  //         triggerNewErrorMessage('User not authenticated. Please relogin.');
  //         throw new Error('User not authenticated');
  //       }

  //       const devUrl = 'http://127.0.0.1:5001/seventh-dryad-391708/us-central1/upload_csv'

  //       // Get ID token of the user
  //       const idToken = await user.getIdToken();

  //       // Get download URL and call the cloud function
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         fetch(devUrl, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': `Bearer ${idToken}`,
  //           },
  //           body: JSON.stringify({ downloadURL, currentLessonID, subjectId, moduleId, weekId }),
  //         })
  //           .then(response => response.json())
  //           .then(data => {
  //             triggerNewSuccessMessage('Successfully uploaded questions.');
  //             setUploading(false);
  //           })
  //           .catch((error) => {
  //             triggerNewErrorMessage('Error uploading. If this error persists, please contact support.')
  //             console.error('Error:', error);
  //             setUploading(false);
  //           });
  //       });
  //     }
  //   );
  // };

  const getOpenQuestions = async () => {
    setIsLoadingOpen(true);

    try {
      const q = query(
        collection(firestore, "questions"),
        where("lesson_id", "==", currentLessonID),
        where("type", "==", "open")
      );

      const querySnapshot = await getDocs(q);

      const fetchedData = querySnapshot.docs.map(doc => doc.data());
      setOpenQuestions(fetchedData);
      triggerNewSuccessMessage('Successfully loaded questions.');
      // setSuccessMessage('Successfully loaded questions.');
    } catch (error) {
      triggerNewErrorMessage('Failed to load questions. Please contact support if this issue persists.');
      console.error("Error:", error);
    } finally {
      setIsLoadingOpen(false);
    }

  }

  const downloadCSV = (data, type) => {
    try {
      const unwantedKeys = ["lesson_id", "type", "subject_id", "module_id", "week_id"];
      const csvRows = [];
      const headers = Object.keys(data[0]).filter(header => !unwantedKeys.includes(header));
      csvRows.push(headers.join(','));


      for (const row of data) {
        csvRows.push(headers.map(header => {
          let value = row[header] || '';
          value = value.toString().replace(/"/g, '""');  // Escape double quotes
          return `"${value}"`;
        }).join(','));
      }

      const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${lessonName}_${type}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      triggerNewErrorMessage('Questions download failed. Please try again.')
    }

  }

  const downloadMultiQuestionsAsCsv = async () => {
    setIsLoadingDownMulti(true);

    try {
      const q = query(
        collection(firestore, "questions"),
        where("lesson_id", "==", currentLessonID),
        where("type", "==", "multi")
      );

      const querySnapshot = await getDocs(q);

      const fetchedData = querySnapshot.docs.map(doc => doc.data());

      // download data as CSV
      downloadCSV(fetchedData, "multi");

    } catch (error) {
      console.error("Error:", error);
      triggerNewErrorMessage('Questions download failed. Please try again.')
    } finally {
      triggerNewSuccessMessage('Questions download successful.')
      setIsLoadingDownMulti(false);
    }
  }

  const downloadOpenQuestionsAsCsv = async () => {
    setIsLoadingDownOpen(true);

    try {
      const q = query(
        collection(firestore, "questions"),
        where("lesson_id", "==", currentLessonID),
        where("type", "==", "open")
      );

      const querySnapshot = await getDocs(q);

      const fetchedData = querySnapshot.docs.map(doc => doc.data());

      // download data as CSV
      downloadCSV(fetchedData, "open");

    } catch (error) {
      console.error("Error:", error);
      triggerNewErrorMessage('Questions download failed. Please try again.')
    } finally {
      triggerNewSuccessMessage('Questions download successful.')
      setIsLoadingDownOpen(false);
    }
  }


  const getMultiQuestions = async () => {
    setIsLoadingMulti(true);

    try {
      const q = query(
        collection(firestore, "questions"),
        where("lesson_id", "==", currentLessonID),
        where("type", "==", "multi")
      );

      const querySnapshot = await getDocs(q);

      const fetchedData = querySnapshot.docs.map(doc => doc.data());

      setMultiQuestions(fetchedData);
    } catch (error) {
      console.error("Error:", error);
      triggerNewErrorMessage('Failed to load questions. Please try again later.')
    } finally {
      triggerNewSuccessMessage('Successfully loaded questions.')
      setIsLoadingMulti(false);
    }
  }


  const deleteMultiQuestions = async () => {
    const userConfirmed = window.confirm("Are you sure you want to delete all multi-choice questions for this lesson? This action cannot be undone.");

    if (!userConfirmed) {
      return;
    }

    setIsLoadingDeleteMulti(true);

    try {
      const q = query(
        collection(firestore, "questions"),
        where("lesson_id", "==", currentLessonID),
        where("type", "==", "multi")
      );

      const querySnapshot = await getDocs(q);

      const batch = writeBatch(firestore);

      querySnapshot.docs.forEach((doc) => {
        const docRef = doc.ref;
        batch.delete(docRef);
      });

      await batch.commit();

    } catch (error) {
      console.error("Error:", error);
      triggerNewErrorMessage('Questions delete failed. Please try again.')
    } finally {
      triggerNewSuccessMessage('Questions delete successful.')
      setIsLoadingDeleteMulti(false);
    }
  };

  const deleteOpenQuestions = async () => {
    const userConfirmed = window.confirm("Are you sure you want to delete all open questions for this lesson? This action cannot be undone.");

    if (!userConfirmed) {
      return;
    }

    setIsLoadingDeleteOpen(true);

    try {
      const q = query(
        collection(firestore, "questions"),
        where("lesson_id", "==", currentLessonID),
        where("type", "==", "open")
      );

      const querySnapshot = await getDocs(q);

      const batch = writeBatch(firestore);

      querySnapshot.docs.forEach((doc) => {
        const docRef = doc.ref;
        batch.delete(docRef);
      });

      await batch.commit();

    } catch (error) {
      console.error("Error:", error);
      triggerNewErrorMessage('Questions delete failed. Please try again.')
    } finally {
      triggerNewSuccessMessage('Questions delete successful.')
      setIsLoadingDeleteOpen(false);
    }
  };

  return (
    <div>
      <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
      <button className='download-button' onClick={getMultiQuestions} disabled={isLoadingMulti}>
        {isLoadingMulti ? 'Loading...' : 'Fetch Multi Questions'}
      </button>
      <button className='download-button' onClick={downloadMultiQuestionsAsCsv} disabled={isLoadingMulti}>
        {isLoadingDownMulti ? 'Loading...' : 'Download as CSV'}
      </button>
      <button className='download-button' onClick={deleteMultiQuestions} disabled={isLoadingMulti}>
        {isLoadingDeleteMulti ? 'Loading...' : 'Delete Multi Questions'}
      </button>
      <div style={{ maxHeight: '300px', height: '300px', overflowY: 'scroll', border: '1px solid #ccc', marginTop: '10px' }}>
        {multiQuestions && multiQuestions.length > 0 &&
          // Please replace 'item.id' and 'item.value' with actual keys from your data
          <table>
            <thead>
              <tr>
                {/* Replace 'Key 1', 'Key 2', 'Key 3' with actual keys from your data */}
                <th>Question</th>
                <th>Correct Answer</th>
                <th>Alt Answer 1</th>
                <th>Alt Answer 2</th>
                <th>Alt Answer 3</th>
              </tr>
            </thead>
            <tbody>
              {multiQuestions.map((item, index) => (
                <tr key={index}>
                  <td>{item.question}</td>
                  <td>{item.correct_answer}</td>
                  <td>{item.alt_answer_1}</td>
                  <td>{item.alt_answer_2}</td>
                  <td>{item.alt_answer_3}</td>
                </tr>
              ))}
            </tbody>
          </table>}
      </div>
      <button className='download-button' onClick={getOpenQuestions} disabled={isLoadingOpen}>
        {isLoadingOpen ? 'Loading...' : 'Fetch Open Questions'}
      </button>
      <button className='download-button' onClick={downloadOpenQuestionsAsCsv} disabled={isLoadingMulti}>
        {isLoadingDownOpen ? 'Loading...' : 'Download as CSV'}
      </button>
      <button className='download-button' onClick={deleteOpenQuestions} disabled={isLoadingMulti}>
        {isLoadingDeleteOpen ? 'Loading...' : 'Delete Open Questions'}
      </button>
      <div style={{ maxHeight: '300px', height: '300px', overflowY: 'scroll', border: '1px solid #ccc', marginTop: '10px' }}>
        {openQuestions && openQuestions.length > 0 &&
          // Please replace 'item.id' and 'item.value' with actual keys from your data
          <table>
            <thead>
              <tr>
                {/* Replace 'Key 1', 'Key 2', 'Key 3' with actual keys from your data */}
                <th>Question</th>
                <th>Correct Answer</th>
              </tr>
            </thead>
            <tbody>
              {openQuestions.map((item, index) => (
                <tr key={index}>
                  <td>{item.question}</td>
                  <td>{item.correct_answer}</td>
                </tr>
              ))}
            </tbody>
          </table>}
      </div>

      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button className='upload-button' onClick={handleUpload} disabled={!file || uploading}>
        {uploading ? 'Uploading...' : 'Upload CSV'}
      </button>
    </div>
  );
}

export default CsvUpload;
