import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import AdminSideNav from '../components/AdminSideNav';
import EditSubjects from '../components/EditSubjects';
import MessagingInterface from '../components/MessagingInterface';
import AccountPage from '../components/AccountPage';
import AdminStudentOverview from '../components/AdminStudentOverview';

const CentralBox = ({ activeItem }) => {
    // TODO move attached accounts to LoginContextProvider
    // TODO ensure that you also reset attached accounts on logout
    // const [attachedAccounts, setAttachedAccounts] = useState([]);

    // useEffect(() => {
    //     const fetchAttachedAccounts = async () => {
    //         try {
    //             const response = await axios.get(`http://127.0.0.1:5000/attached-accounts`, { withCredentials: true });
    //             setAttachedAccounts(response.data);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     fetchAttachedAccounts();
    // }, []);

    useEffect(() => {});

    const renderContent = () => {
        switch (activeItem) {
            case 'studentOverview':
                return <AdminStudentOverview />
            case 'editSubjects':
                return <EditSubjects/>
            case 'manageAdmin':
                return <div>Coming soon!</div>
            case 'messages':
                return <MessagingInterface/>
            case 'account':
                return <AccountPage/>;
            default:
                return <div>Please select an item from the side navigation.</div>;
        }
    };

    return (
        <AnimatePresence>
            <motion.div
                key={activeItem}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                // exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
                className="central-box"
            >
                    {renderContent()}
            </motion.div>
        </AnimatePresence>
    );
};

const AdminPortal = ({ handleLogout }) => {
    const [activeItem, setActiveItem] = useState('overview');

    const handleNavItemClick = (item) => {
        setActiveItem(item);
    };
    return (
        <div className="fill-screen fill-screen-h">
            <AdminSideNav handleNavItemClick={handleNavItemClick} handleLogout={handleLogout} />
            <CentralBox activeItem={activeItem} />
        </div>
    );
};

export default AdminPortal;
