import { useRef, useState } from 'react';
import './../../../style/Subjects.css';
import Week from './Week';

const Module = ({ module }) => {
    const [contentHeight, setContentHeight] = useState(1000);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const contentRef = useRef(null);
  
    const toggleWeek = (weekId) => {
      setExpandedWeeks((prevExpandedWeeks) => ({
        [weekId]: !prevExpandedWeeks[weekId],
      }));
    };
  
    const updateHeight = (height) => {
      setContentHeight(contentHeight + height);
    };
    
    return (
      <li className='module-container'>
        <button className="module-header">
          {module.name}
        </button>
        <div
          className={`module-description expanded`}
          style={{ maxHeight: contentHeight }}
          ref={contentRef}
        >
            {module.description}
          <ul className={`module-content expanded`}>
            {Object.entries(module.weeks) && Object.entries(module.weeks).map((week) => (
              <Week
                key={week[1].name}
                week={week}
                expanded={expandedWeeks[week[1].name]}
                toggleWeek={() => toggleWeek(week[1].name)}
                propagateHeight={updateHeight}
                subscribed={true}
              />
            ))}
          </ul>
        </div>
      </li>
    );
  };

export default Module;