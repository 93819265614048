import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useRef, useState } from 'react';
import './../../../style/Subjects.css';
import Week from './Week';

const Module = ({ module, expanded, toggleModule, subscribed }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const [expandedWeeks, setExpandedWeeks] = useState({});
    const contentRef = useRef(null);
  
    const toggleWeek = (weekId) => {
      setExpandedWeeks((prevExpandedWeeks) => ({
        [weekId]: !prevExpandedWeeks[weekId],
      }));
    };
  
    useEffect(() => {
      if (!expanded) {
        setExpandedWeeks({});
      }
      setContentHeight(expanded ? contentRef.current.scrollHeight : 0);
    }, [expanded]);
  
    const handleToggle = () => {
      toggleModule(module[1].name);
    };
  
    const updateHeight = (height) => {
      setContentHeight(contentHeight + height);
    };
    // console.log('test log:');
    // console.log(Object.entries(module[1].week_order));
    // Object.values(module[1].week_order).map((weekId) => {
    //   console.log(weekId);
    //   console.log(Object.entries(module[1].weeks));
    //   console.log(module[1].weeks[weekId]);
    // });
    return (
      <li className='module-container'>
        <button className="module-header" onClick={handleToggle}>
          {module[1].name}
          {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </button>
        {expanded &&
        <div
          className={`module-description ${expanded ? 'expanded' : ''}`}
          style={{ maxHeight: contentHeight }}
          ref={contentRef}
        >
            {module[1].description}
          <ul className={`module-content ${expanded ? 'expanded' : ''}`}>
          {Object.entries(module[1].weeks) &&
                            // module[1].week_order.map((weekId) => (
                              Object.values(module[1].week_order).map((weekId) => (
                                <Week
                                    key={weekId}
                                    week={[weekId, module[1].weeks[weekId]]}
                                    expanded={expandedWeeks[weekId]}
                                    toggleWeek={() => toggleWeek(weekId)}
                                    propagateHeight={updateHeight}
                                    subscribed={subscribed}
                                />
                            ))}
          {/* {Object.entries(module[1].weeks) && Object.entries(module[1].weeks).map((week) => (
              <Week
                key={week[1].name}
                week={week}
                expanded={expandedWeeks[week[1].name]}
                toggleWeek={() => toggleWeek(week[1].name)}
                propagateHeight={updateHeight}
                subscribed={subscribed}
              />
            ))} */}
          </ul>
        </div>}
      </li>
    );
  };

export default Module;