import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { React, useEffect, useRef, useState } from 'react';
import no_vid from '../../../images/no-video.jpg';


const Lesson = ({ lesson, expanded, toggleLesson, propagateHeight, subscribed }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const handleToggle = () => {
    toggleLesson(lesson[1].name);
  };

  useEffect(() => {
    console.log('detected height change! expanded, contentRef.current.scrollHeight', expanded, expanded ? contentRef.current.scrollHeight : 0)
    const height = expanded ? contentRef.current.scrollHeight : 0;
    setContentHeight(height);
    propagateHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  // if (contentRef.current.scrollHeight != null) {console.log('lesson height ', contentRef.current.scrollHeight);}
  if (subscribed) {
    return (
      <div className='lesson-container'>
        <button className="lesson-header" onClick={handleToggle}>
          {lesson[1].name}
          {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </button>
        {expanded &&

          <div
            className={`lesson-description ${expanded ? 'expanded' : ''}`}
            style={{ maxHeight: contentHeight }}
            ref={contentRef}
          >
            <div className='lesson-display'>
              {lesson[1].description}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <video src={lesson[1].src} controls height='400px' width='320px' style={{ width: '400px', height: '320px', margin: '0px', padding: '0px' }} />
            </div>
          </div>
        }
      </div>
    );
  }
  return (
    <div className='lesson-container'>
      <button className="lesson-header" onClick={handleToggle}>
        {lesson[1].name}
        {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      </button>
      {expanded &&
        <div
          className={`lesson-description ${expanded ? 'expanded' : ''}`}
          style={{ maxHeight: contentHeight }}
          ref={contentRef}
        >
          <div className='lesson-display'>
            {lesson[1].description}
            {/* <img alt='Video cannot be displayed as unsubscribed.' src={no_vid} controls style={{ width: '400px', height: '320px', margin: '0px', padding: '0px' }} /> */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div class="video-wrapper no-access">
              <img alt='Video cannot be displayed as unsubscribed.' src={no_vid} controls style={{ width: '400px', height: '300px', margin: '0px', padding: '0px' }} />
              <div class="overlay" style={{ width: '400px', height: '300px', margin: '0px', padding: '0px' }}></div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Lesson;