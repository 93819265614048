import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase'; // Import your initialized firestore and auth instances
import LessonManager from './LessonManager';
import '../../style/SubjectSelector.css';
import ComponentLoadingScreen from '../helpers/ComponentLoadingScreen';
import { easeInOut, motion } from 'framer-motion';

function SubjectSelector() {
    const [subjects, setSubjects] = useState([]);
    const [currentSubject, setCurrentSubject] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);

    const handleSubjectSelect = (subjectId, isSubscribed) => {
        if (isSubscribed) {
            setCurrentSubject(subjectId);
        } else {
            setShowModal(true);
        }
    };


    useEffect(() => {
        const fetchSubjectsAndSubscriptions = async () => {
            try {
                // Fetching subjects
                const querySnapshot = await getDocs(collection(firestore, "subjects"));
                let subjectsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Fetching active subscriptions
                const userUID = auth.currentUser?.uid;
                const subscriptionsQuery = query(
                    collection(firestore, 'subscriptions'),
                    where('student_id', '==', userUID),
                    where('active', '==', true)
                );
                const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
                const activeSubscriptionsSet = new Set(subscriptionsSnapshot.docs.map(doc => doc.data().subject_id));

                // Adding 'subscribed' flag to subjects
                subjectsList = subjectsList.map(subject => ({
                    ...subject,
                    subscribed: activeSubscriptionsSet.has(subject.id)
                }));

                setSubjects(subjectsList);
            } catch (error) {
                console.error('Error combining subject data and subscription status: ', error);
            } finally {
                setComponentLoading(false);
            }
        };

        fetchSubjectsAndSubscriptions();
    }, []);

    if (componentLoading) {
        return (<ComponentLoadingScreen />)
    }

    if (currentSubject) {
        return <LessonManager subjectId={currentSubject} />;
    }




    return (
        <motion.div
            key='your-prog'
            initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
            style={{ position: 'relative' }}
        >
            <div className='quiz-container'>
                <h2 className='main-header'>Select a Subject</h2>
                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                            <p>Please contact your parent to subscribe to this subject.</p>
                        </div>
                    </div>
                )}
                {subjects.map(subject => (
                    <button className='register-button' key={subject.id} onClick={() => handleSubjectSelect(subject.id, subject.subscribed)}>
                        {subject.name} {subject.subscribed ? "(Subscribed)" : ""}
                    </button>
                ))}

            </div>
        </motion.div>
    );
}

export default SubjectSelector;
