import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { LoggedInProvider } from './components/helpers/LoggedInContext';
import { MessageProvider } from './components/helpers/MessageContext';
import './index.css';
// import reportWebVitals from './utils/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <LoggedInProvider>
    <MessageProvider>
      <App />
    </MessageProvider>
  </LoggedInProvider>
  // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// update this to an actual logging endpoint
// reportWebVitals(console.log);
