import { collection, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase_setup/firebase';

export const getLessonDataFromId = async (eventId) => {
    const user = auth.currentUser;

    if (user) {
        const subCollection = collection(firestore, 'subjects');
        const subjectDocs = await getDocs(subCollection);

        for (const doc of subjectDocs.docs) {
            const subject = doc.data();

            for (const moduleId in subject.modules) {
                const module = subject.modules[moduleId];

                for (const weekId in module.weeks) {
                    const week = module.weeks[weekId];

                    for (const lessonId in week.lessons) {
                        const lesson = week.lessons[lessonId];

                        if (lessonId === eventId) {
                            return { name: lesson.name, description: lesson.description };
                        }
                    }
                }
            }
        }
    }
    return null;
};


export const getWeekDataFromId = async (eventId) => {
    const user = auth.currentUser;

    if (user) {
        const subCollection = collection(firestore, 'subjects');
        const subjectDocs = await getDocs(subCollection);

        for (const doc of subjectDocs.docs) {
            const subject = doc.data();

            for (const moduleId in subject.modules) {
                const module = subject.modules[moduleId];

                for (const weekId in module.weeks) {
                    const week = module.weeks[weekId];

                    if (weekId === eventId) {
                        return { name: week.name, description: week.description };
                    }
                }
            }
        }
    }
    return null;
};


export const getModuleDataFromId = async (eventId) => {
    const user = auth.currentUser;

    if (user) {
        const subCollection = collection(firestore, 'subjects');
        const subjectDocs = await getDocs(subCollection);

        for (const doc of subjectDocs.docs) {
            const subject = doc.data();

            for (const moduleId in subject.modules) {
                const module = subject.modules[moduleId];

                if (moduleId === eventId) {
                    return { name: module.name, description: module.description };
                }
            }
        }
    }
    return null;
};