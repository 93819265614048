import React from 'react';
import Joyride from 'react-joyride';
import './../../style/Tour.css';
import mascot from '../../images/mascot.png';

const JoyrideAvatarContent = ({ children }) => (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'}}>
        <div className='main-text' style={{
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            padding: '10px',
            color: '#333',
            position: 'relative',
            maxWidth: '500px',
            marginRight: '20px'
        }}>
            {children}
            <div style={{
                position: 'absolute',
                right: '-10px',
                top: '10px',
                width: '0',
                height: '0',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: `10px solid #f9f9f9`,
            }}></div>
        </div>
        <img src={mascot} alt="Mascot" style={{ width: '200px', height: '200px', marginRight: '10px' }} />
    </div>
);

const StudentTour = ({ run, callback }) => {
    const steps = [
        {
            target: '.user-card',  // assuming you add this class to UserCard's root element
            content: 
            <JoyrideAvatarContent>
                This is your user profile. Click here to view and change your account details.
            </JoyrideAvatarContent>,
            placement: 'right',
            disableBeacon: true
        },
        {
            target: '.your-progress-button',
            content: 
            <JoyrideAvatarContent>
                View your learning progress for your subjects here.
            </JoyrideAvatarContent>,
            placement: 'right',
        },
        {
            target: '.learn-button',
            content: 
            <JoyrideAvatarContent>
                Dive into learning materials, progress is saved so jump back in where you left off.
            </JoyrideAvatarContent>,
            placement: 'right',
        },
        {
            target: '.your-subjects-button',
            content: 
            <JoyrideAvatarContent>
                See all of our subjects. Subjects you are enrolled in will have video lessons available.
            </JoyrideAvatarContent>,
            placement: 'right',
        },
        {
            target: '.link-account-button',
            content: 
            <JoyrideAvatarContent>
                Here you can link your account to a parent if you haven't already!
            </JoyrideAvatarContent>,
            placement: 'right',
        },
        {
            target: '.support-button',
            content: 
            <JoyrideAvatarContent>
                Need help? Message us here.
            </JoyrideAvatarContent>,
            placement: 'right',

        },
        // ... add more steps as needed
    ];

    return <Joyride
        steps={steps} 
        run={run}
        callback={callback} 
        continuous={true} 
        disableBeacon={true}
        styles={{
            tooltip: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // centers items vertically in the tooltip
                background: 'none',
                width: '500px'
            }
        }} />;
};

export default StudentTour;
