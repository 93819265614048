import UserCard from "./UserCard";
import InfoIcon from '@mui/icons-material/Info';

const infoStyle = {
    position: 'absolute',
    top: '10px',      /* Adjust based on your design */
    right: '10px',
    cursor: 'pointer',
    color: 'lightBlue'
}

const SideNav = ({ handleNavItemClick, handleLogout, setIsTourActive }) => {

    return (
        <div className="parent-portal">
            <InfoIcon className="info-icon" onClick={() => setIsTourActive(true)} style={infoStyle}/>
            <UserCard className="user-card" onClick={() => handleNavItemClick('account')} />
            <button className="linked-accounts-button" onClick={() => handleNavItemClick('linkedAccount')}>
                Linked Accounts
            </button>
            <button className="our-subjects-button" onClick={() => handleNavItemClick('subjects')}>
                Our Subjects
            </button>
            <button className="our-support-button" onClick={() => handleNavItemClick('support')}>
                Our Support
            </button>
            <button className="link-account-button" onClick={() => handleNavItemClick('linkAccount')}>
                Link Account
            </button>
            <button className="contact-us-button" onClick={() => handleNavItemClick('contact')}>
                Contact Us
            </button>
            <button className="payment-button" onClick={() => handleNavItemClick('payment')}>
                Payment
            </button>
            <button className="logout-button" onClick={() => handleLogout()}>
                Logout
            </button>
        </div>
    );
};

export default SideNav;