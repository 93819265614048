import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { React, useEffect, useRef, useState } from 'react';
import CsvUpload from '../CSVUploader';
import VideoUploader from '../VideoUploader';
// import { useMessage } from '../../helpers/MessageContext';


const EditLesson = ({ lesson, expanded, toggleLesson, propagateHeight, deleteLesson, changeLessonValue, subjectId, moduleId, weekId }) => {
    // for height adjustments
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    // for editing
    const [isEditing, setIsEditing] = useState(false);
    const [editedLesson, setEditedLesson] = useState(lesson);
    useEffect(() => {
        const height = expanded ? contentRef.current.scrollHeight : 0;
        setContentHeight(height);
        propagateHeight(height);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded, isEditing]);

    if (lesson[1] === undefined) {
        return null;
    }

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedLesson(lesson);
    };

    const handleLessonSubmit = (editedLesson) => {
        // change week value to new value and set editing to false
        changeLessonValue(Object.values(editedLesson));
        setIsEditing(false);
    };

    const handleToggle = () => {
        toggleLesson(lesson[1].name);
    };

    if (isEditing) {
        return <div className='lesson-container'>
            <div>
                <input
                    className='lesson-input'
                    type="text"
                    // value={editedModule[1].name}
                    placeholder={editedLesson[1].name}
                    onChange={(e) => setEditedLesson({ ...editedLesson, "1": { ...editedLesson["1"], name: e.target.value } })}
                    disabled={!isEditing} // Disable the input field when not in editing mode
                />
                <button className="module-button" onClick={handleToggle}>
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <button className="lesson-edit" onClick={() => handleLessonSubmit(editedLesson)}>Save</button>
            </div>
            <div
                className={`lesson-description ${expanded ? 'expanded' : ''}`}
                style={{ maxHeight: contentHeight }}
                ref={contentRef}
            >
                {expanded && (
                    <div className='lesson-display'>
                        <input
                            className='week-input'
                            type="text"
                            // value={editedModule[1].description}
                            placeholder={editedLesson[1].description}
                            onChange={(e) => setEditedLesson({ ...editedLesson, "1": { ...editedLesson["1"], description: e.target.value } })}
                            disabled={!isEditing} // Disable the input field when not in editing mode
                        />
                        <video src={lesson[1].src} controls style={{ width: '400px', height: '320px', margin: '0px', padding: '0px' }} />
                        <button className='register-button'>Upload Video</button>
                        <CsvUpload currentLessonID={lesson[0]} lessonName={lesson[1].name} subjectId={subjectId} moduleId={moduleId} weekId={weekId} />
                    </div>


                )}</div></div>;
    }
    return (
        <div className='lesson-container'>
            <div>
                <button className="lesson-header" onClick={handleToggle}>
                    {lesson[1].name}
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </button>
                <button className="lesson-edit" onClick={handleEditClick}>Edit</button>
                <button className="lesson-edit" onClick={() => deleteLesson(lesson[0])}>Delete</button>
            </div>
            {expanded &&
                <div
                    className={`lesson-description ${expanded ? 'expanded' : ''}`}
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    {expanded && (
                        <div className='lesson-display'>
                            <p>{lesson[1].description}</p>
                            <video src={lesson[1].src} controls style={{ width: '400px', height: '320px', margin: '0px', padding: '0px' }} />
                            <VideoUploader lesson={lesson} changeLessonValue={changeLessonValue}/>
                            <CsvUpload currentLessonID={lesson[0]} lessonName={lesson[1].name} subjectId={subjectId} moduleId={moduleId} weekId={weekId} />
                        </div>
                    )}</div>}</div>
    );
};


export default EditLesson;