
import { React, useRef, useState, useEffect, useContext } from 'react';
import '../../style/YourProgress.css';
import Subjects from './Subjects';
import { collection, query, doc, getDoc, getDocs, where } from 'firebase/firestore';
import { firestore, auth } from '../../firebase_setup/firebase';
import EventHistory from "./EventHistory";
import { getLessonDataFromId, getModuleDataFromId, getWeekDataFromId } from "../helpers/FirestoreHelpers";
import LoggedInContext from "../helpers/LoggedInContext";
import ComponentLoadingScreen from "../helpers/ComponentLoadingScreen";
import { easeInOut, motion } from 'framer-motion';
import LinkAccount from "./LinkAccount";
import ProgressBarsRow from './ProgressBarsRow';


const YourProgress = ({ setActiveItemWithContext }) => {
    const { name } = useContext(LoggedInContext);
    const [contentType, setContentType] = useState('default')
    const [events, setEvents] = useState([]);
    const [recentEvents, setRecentEvents] = useState([]);
    const [totalEvents, setTotalEvents] = useState({});
    const [completedLessons, setCompletedLessons] = useState({});
    const [completedWeeks, setCompletedWeeks] = useState({});
    const [completedModules, setCompletedModules] = useState({});
    const [weekScores, setWeekScores] = useState({});
    const [moduleScores, setModuleScores] = useState({});
    const [subjects, setSubjects] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [accountInfo, setAccountInfo] = useState(null);
    const [componentLoading, setComponentLoading] = useState(true);

    useEffect(() => {

        const fetchSubjects = async () => {
            const subjectsRef = collection(firestore, 'subjects');
            const querySnapshot = await getDocs(subjectsRef);
            let tempSubjects = [];
            let totalEventsPerSubject = [];

            querySnapshot.forEach(doc => {
                const subject = doc.data();
                const modules = Object.values(subject.modules);

                let newTotalEvents = 0;
                modules.forEach(module => {
                    const weeks = Object.values(module.weeks);
                    weeks.forEach(week => {
                        newTotalEvents += week.lesson_order.length;
                        newTotalEvents += 1;  // for end of week quiz
                    });
                    newTotalEvents += 1;  // for end of module quiz
                });

                totalEventsPerSubject.push({ count: newTotalEvents, subjectId: doc.id });
                subject.id = doc.id;
                tempSubjects.push(subject);
            });

            return { subjects: tempSubjects, totalEvents: totalEventsPerSubject };
        };


        const fetchEvents = async () => {
            const user = auth.currentUser;

            if (!user) throw new Error("User not logged in");

            const eventsDoc = doc(firestore, 'user_events', user.uid);
            const eventsDocData = await getDoc(eventsDoc);

            if (!eventsDocData.exists()) return [];

            const eventData = eventsDocData.data();
            return Object.values(eventData).sort((a, b) => {
                return (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0);
            });
        };

        const fetchLinkedAccounts = async () => {
            const userUID = auth.currentUser?.uid;
            if (!userUID) return;

            // First, retrieve the currently selected user's document.
            const userDocRef = doc(firestore, 'users', userUID);
            const userDocSnapshot = await getDoc(userDocRef);

            // Check if the linkedAccount field exists and retrieve its value.
            const linkedAccountUID = userDocSnapshot.data().linkedAccount;

            if (!linkedAccountUID) {
                console.error('No linked account found for the user.');
                return;
            }

            // Now, retrieve the linkedAccount details.
            const linkedAccountDocRef = doc(firestore, 'users', linkedAccountUID);
            const linkedAccountDocSnapshot = await getDoc(linkedAccountDocRef);

            if (!linkedAccountDocSnapshot.exists()) {
                console.error('Linked account document not found.');
                return;
            }

            // Process the linked account details and update state or whatever you want.
            const linkedAccountData = {
                accountId: linkedAccountDocSnapshot.id,
                name: linkedAccountDocSnapshot.data().name,
                subscriptions: linkedAccountDocSnapshot.data().subscriptions || []
            };

            setLinkedAccounts([linkedAccountData]);
        };

        const fetchSubscriptions = async () => {
            try {
                const user = auth.currentUser;
                const subscriptionsSnapshot = await getDocs(query(
                    collection(firestore, 'subscriptions'),
                    where('student_id', '==', user.uid),
                    where('active', '==', true)
                ));
                let fetchedSubscriptions = [];
                subscriptionsSnapshot.forEach(doc => {
                    fetchedSubscriptions.push(doc.data());
                });
                return fetchedSubscriptions;
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
            }
        };


        const getRecentEvents = async (localEvents) => {
            // Assuming each event is a field in the document, and not a subcollection.
            // Sorting by timestamp and picking the latest 5.
            const sortedEventHistory = localEvents
                .slice(0, 5)
                .map(async event => {
                    let additionalData;
                    switch (event.type) {
                        case 'learningExperience':
                            additionalData = await getLessonDataFromId(event.event_id);
                            break;
                        case 'endOfWeek':
                            additionalData = await getWeekDataFromId(event.event_id);
                            break;
                        case 'endOfModule':
                            additionalData = await getModuleDataFromId(event.event_id);
                            break;
                        default:
                            additionalData = {}; // Default to an empty object
                            break;
                    }
                    return {
                        ...event,
                        ...additionalData
                    };
                });
            return sortedEventHistory;
        };

        const getCompletedEvents = async (localEvents) => {
            let lessonsCompletedMap = {};
            let weeksCompletedMap = {};
            let modulesCompletedMap = {};
            let endOfWeekScoresMap = {};
            let endOfModuleScoresMap = {};

            for (let i = localEvents.length - 1; i >= 0; i--) {
                const event = localEvents[i];
                const subjectId = event.subject_id;

                if (!lessonsCompletedMap[subjectId]) {
                    lessonsCompletedMap[subjectId] = 0;
                    weeksCompletedMap[subjectId] = 0;
                    modulesCompletedMap[subjectId] = 0;
                    endOfWeekScoresMap[subjectId] = [];
                    endOfModuleScoresMap[subjectId] = [];
                }

                switch (event.type) {
                    case 'learningExperience':
                        lessonsCompletedMap[subjectId]++;
                        break;
                    case 'endOfWeek':
                        console.log('endOfWeek detected');
                        weeksCompletedMap[subjectId]++;
                        endOfWeekScoresMap[subjectId].push(event.percentage_correct);
                        break;
                    case 'endOfModule':
                        modulesCompletedMap[subjectId]++;
                        endOfModuleScoresMap[subjectId].push(event.percentage_correct);
                        break;
                    default:
                        break;
                }
            };

            const weekScoresArray = Object.entries(endOfWeekScoresMap).map(([subjectId, scores]) => {
                const avgScore = scores.reduce((a, b) => a + b, 0) / scores.length;
                return { subjectId, avgScore };
            });

            // Example for scores: (you can do similar conversions for other metrics)
            const moduleScoresArray = Object.entries(endOfModuleScoresMap).map(([subjectId, scores]) => {
                const avgScore = scores.reduce((a, b) => a + b, 0) / scores.length;
                return { subjectId, avgScore };
            });

            setCompletedLessons(lessonsCompletedMap);
            setCompletedWeeks(weeksCompletedMap);
            setCompletedModules(modulesCompletedMap);
            setWeekScores(weekScoresArray);
            setModuleScores(moduleScoresArray);
        };

        const fetchData = async () => {
            try {
                const { subjects: fetchedSubjects, totalEvents: fetchedTotalEvents } = await fetchSubjects();
                const fetchedEvents = await fetchEvents();
                const fetchedSubscriptions = await fetchSubscriptions();
                await fetchLinkedAccounts();

                const recentEventsData = await Promise.all(await getRecentEvents(fetchedEvents));
                await getCompletedEvents(fetchedEvents);

                // Set all states together:
                setSubjects(fetchedSubjects);
                setEvents(fetchedEvents);
                setTotalEvents(fetchedTotalEvents);  // This will only set the last totalEvents from the fetched list.
                setRecentEvents(recentEventsData);
                setSubscriptions(fetchedSubscriptions);

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setComponentLoading(false);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const buildAccountInfo = async () => {
            const user = auth.currentUser;

            const account = {
                id: user.uid,
                name: name,
                subjects: [],
            };
            for (const subscription of subscriptions) {
                const subject = subjects.find(s => s.id === subscription.subject_id);
                if (!subject) continue;
                const totalEventsForSubject = totalEvents.find(te => te.subjectId === subject.id).count;
                const eventsForSubject = events.filter(e => e.subject_id === subject.id);
                // this subjectData looks good
                const subjectData = {
                    id: subject.id,
                    name: subject.name,
                    progress: (eventsForSubject.length / totalEventsForSubject) * 100,
                    averageModuleScore: moduleScores.find(item => item.subjectId === subject.id).avgScore,
                    averageWeekScore: weekScores.find(item => item.subjectId === subject.id).avgScore,
                };
                // TODO fix the scores - currently showing NaN

                account.subjects.push(subjectData);
            }

            return account;
        };

        // If buildAccountInfo is an async function or returns a promise
        const generateAccountInfo = async () => {
            if (subjects && events && totalEvents && recentEvents && subscriptions) {
                try {
                    const updatedAccountInfo = await buildAccountInfo();
                    setAccountInfo(updatedAccountInfo);
                } catch (error) {
                    console.error("Error generating account info. This may be due to no progress having been made:", error);
                }
            }
        };

        generateAccountInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjects, events, totalEvents, recentEvents, subscriptions]);



    const containerRef = useRef(null);



    const NextProgress = ({ subject }) => {
        // We want 2 cards here - one for next suject, and one for previous lesson or subject overview?
        return (
            <div className="progress-bars-row" >
                <div className='progress-button-card' onClick={() => setActiveItemWithContext('subjects')}>
                    <div className="progress-bar-container">
                        <div>
                            <strong>{subject.name} Overview</strong><br />
                        </div>
                    </div>
                </div>
                <div className='progress-button-card' onClick={() => setActiveItemWithContext('learn', subject.id)}>
                    <div className="progress-bar-container">
                        <div>
                            <strong>Keep Learning</strong><br />
                        </div>
                    </div>
                </div>
            </div>

        );
    };

    // TODO add this button to go to learn and subject
    function updateProgressPage(content, type) {
        if (type === 'subject') {
            // sets the content to be a subject overview
            setContentType('subject');
            // setContent(content);
        } else if (type === 'lesson') {
            setContentType('lesson');
            // setContent(content);
        }
    }

    if (false) {
        updateProgressPage('a', 'b');
    }

    if (componentLoading) {
        return (<ComponentLoadingScreen />)
    }

    if (linkedAccounts.length === 0) {
        return <LinkAccount />
    }

    if (subscriptions.length === 0) {
        return (
            <motion.div
                key='not-subscribed'
                initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
                style={{ position: 'relative' }}
            >
                <h1>Not subscribed to a course yet...</h1>
                <p>Please contact your linked parent to begin a course.</p>
            </motion.div>
        )
    }
    console.log(recentEvents);
    if (recentEvents.length === 0) {
        return (
            <motion.div
                key='no-data'
                initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
                style={{ position: 'relative' }}
            >
                <h1>Not completed any lessons yet...</h1>
                <p>Please press learn to begin seeing your progress.</p>
            </motion.div>)
    }
    console.log(accountInfo);
    // TODO make this such that if there are no lesson they are told to start doing lessons
    if (!accountInfo) {
        <div className="App">
            <header className="App-header">
                <h1>Not subscribed to a course yet...</h1>
                <p>Please contact your parent to begin a course.</p>
            </header>
        </div>
    }

    if (contentType === 'default') {
        // TODO wrap this in the motion divs from StudentPortal
        return (
            <motion.div
                key='your-prog'
                initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
                className='progress-container'
            >
                <div ref={containerRef} className='main-container'>
                    <div key={accountInfo.id}>
                        <h2 className='main-header'>Welcome back, {accountInfo.name}</h2>
                        {accountInfo.subjects.map((subject) => (
                            <div>
                                <div className='main-header'>{subject.name}</div>
                                <ProgressBarsRow subject={subject} completedLessons={completedLessons} completedWeeks={completedWeeks} completedModules={completedModules} />
                                <NextProgress subject={subject} />
                                {/* <div className='sub-header'>Module Scores</div> */}
                                {/* <ScoreChart scores={subject.scores} containerRef={containerRef}></ScoreChart> */}
                            </div>
                        ))}
                    </div>
                </div>
                <EventHistory className='right-child' events={recentEvents} />
            </motion.div>
        );
    } else if (contentType === 'subject') {
        return <Subjects />
    } else if (contentType === 'lesson') {
        // use content to retrieve specific lesson info here
        return <div>Coming soon!</div>
    } else {
        return <div>Error - no content selected</div>
    }


};

export default YourProgress;
