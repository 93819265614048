// LoggedInContext.js
import { createContext, useState } from 'react';

const LoggedInContext = createContext();

export const LoggedInProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userUID, setUserUID] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState(null);

  
  return (
    <LoggedInContext.Provider value={{ isLoggedIn, setIsLoggedIn, userRole, setUserRole, userUID, setUserUID, avatar, setAvatar, name, setName }}>
      {children}
    </LoggedInContext.Provider>
  );
};

export default LoggedInContext;