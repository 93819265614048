import React from 'react';
import PropTypes from 'prop-types';
import './../../style/VideoLesson.css';

const VideoLesson = ({ videoUrl, name, description, handleNextActivity }) => {
  console.log('name: ', name);
  return (
    <div className="videoLesson">
      <h1 className='videoLesson-header'>{name}</h1>
      <div className="videoLesson-body">
        <video controls src={videoUrl} className="videoLesson-video"></video>
      </div>
      <button className='next-button' onClick={handleNextActivity}>Continue</button>
    </div>
  );
};

VideoLesson.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleNextActivity: PropTypes.func.isRequired
};

export default VideoLesson;
