import UserCard from "./UserCard";
import InfoIcon from '@mui/icons-material/Info';

const infoStyle = {
    position: 'absolute',
    top: '10px',      /* Adjust based on your design */
    right: '10px',
    cursor: 'pointer',
    color: 'lightBlue'
}

const StudentSideNav = ({ handleNavItemClick, handleLogout, setIsTourActive }) => {
    return (
        <div className="student-portal">
            <InfoIcon className="info-icon" onClick={() => setIsTourActive(true)} style={infoStyle}/>
            <UserCard onClick={() => handleNavItemClick('account')}/>
            <button className='your-progress-button' onClick={() => handleNavItemClick('yourProgress')}>
                Your Progress
            </button>
            <button className='learn-button' onClick={() => handleNavItemClick('learn')}>
                Learn
            </button>
            <button className='your-subjects-button' onClick={() => handleNavItemClick('subjects')}>
                Your Subjects
            </button>
            <button className='link-account-button' onClick={() => handleNavItemClick('linkAccount')}>
                Link Account
            </button>
            <button className="support-button" onClick={() => handleNavItemClick('support')}>
                Contact
            </button>
            <button onClick={() => handleLogout()}>
                Logout
            </button>
        </div>
    );
};

export default StudentSideNav;