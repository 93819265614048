import React from 'react';
import './../../style/OurSupport.css';
import { easeInOut, motion } from 'framer-motion';

function optionCard(backgroundColor, priceColor, buttonColor, price, isMonthly, requiresContact, title, description, info1, info2, info3, setPageContent) {
    const innnerDivStyle = {
        backgroundColor: backgroundColor,
      };
    const priceDivStyle = {
        backgroundColor: priceColor,
      };
      const buttonStyle = {
        backgroundColor: buttonColor,
      };
    return (
        <div class="plan">
            <div class="inner" style={innnerDivStyle}>
                <span class="pricing" style={priceDivStyle}>
                    <span>
                        £{price} {isMonthly && <small>/ m</small>}
                    </span>
                </span>
                <p class="title">{title}</p>
                <p class="info">{description}</p>
                <ul class="features">
                    <li>
                        <span class="icon">
                            <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path fill='currentColor' d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: info1 }}></span>
                    </li>
                    <li>
                        <span class="icon">
                            <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: info2 }}></span>
                    </li>
                    <li>
                        <span class="icon">
                            <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: info3 }}></span>
                    </li>
                </ul>
                <div class="action">
                    <div class="button" onClick={() => setPageContent(requiresContact ? 'contact' : 'payment')} style={buttonStyle}>
                        {requiresContact ? 'Contact Us' : 'Subscribe'}
                    </div>
                </div>
            </div>
        </div>)
}

const OurSupport = ({setPageContent}) => {
    return (
        <motion.div
        key='your-prog'
        initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
        style={{ position: 'relative' }}
    >
            <div className='support-container'>
                <div className='support-header'>
                    Our Support
                </div>
                <div className='support-text support-card'>
                    Welcome to our Online Course platform! We offer a tailored online course designed for effective learning using spaced repetitive learning techniques. With three lessons delivered per week, you'll benefit from a structured approach that reinforces concepts and improves long-term retention. Monitor your progress through our intuitive dashboard and subscribe today for just £49.
                    <br />
                    <br />
                    For personalized attention, our One-to-One sessions target your weaknesses. Highly skilled tutors provide one-hour sessions and review your progress after one week. Alternatively, our Tutoring option offers weekly sessions with a personal tutor, ensuring comprehensive support and access to our online platform. Contact us to explore your learning options and invest in your education for a brighter future. </div>
            </div>
            <div style={{ 'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'justify-content': 'center' }}>
                {optionCard("#d9c6e6", "#c488ee", "#C492E6", '49', true, false, 'Online Course', 'Tailored online course using spaced repetitive learning.', '<strong>3 lessons</strong> per week', 'Spaced repetitive learning', 'Progress dashboard', setPageContent, 'payment')}
                {optionCard("#bee0c9", "#61d587", "#328b4e", '60', false, true, 'One-to-One', 'Individual session to target any weaknesses.', '<strong>Highly skilled</strong> tutor', '<strong>1 hour</strong> session', 'Review after <strong>1 week</strong>', setPageContent, 'contact')}
                {optionCard("#afc2f6", "#678cf9", "#4574FF", '249', true, true, 'Tutoring', 'Weekly tutoring and full support with learning.', '<strong>1 session</strong> per week', '<strong>Personal tutor<strong/>', 'Access to online platform', setPageContent, 'contact')}
            </div>
        </motion.div>
    );
};

export default OurSupport;


