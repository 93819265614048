import React from 'react';
import Joyride from 'react-joyride';
import './../../style/Tour.css';

const ParentTour = ({ run, callback, setActiveItem }) => {
    const steps = [
        {
            target: '.user-card',
            content: 'This is your user profile. Click here to view and change your account details.',
            placement: 'right',
            disableBeacon: true,
            args: ['account']

        },
        {
            target: '.linked-accounts-button',
            content: 'View and manage your linked accounts here.',
            placement: 'right',
            disableBeacon: true,
            args: ['linkedAccount']
        },
        {
            target: '.our-subjects-button',
            content: 'Explore the subjects available to you.',
            placement: 'right',
            disableBeacon: true,
            args: ['subjects']
        },
        {
            target: '.our-support-button',
            content: 'Need help? Get support here.',
            placement: 'right',
            disableBeacon: true,
            args: ['support']
        },
        {
            target: '.link-account-button',
            content: 'Here you can link your account to a parent if you haven\'t already.',
            placement: 'right',
            disableBeacon: true,
            args: ['linkAccount']
        },
        {
            target: '.contact-us-button',
            content: 'Have questions? Contact us for more information.',
            placement: 'right',
            disableBeacon: true,
            args: ['contact']
        },
        {
            target: '.payment-button',
            content: 'Click this button to subscribe your child so they\'re ready to get started when they login!',
            placement: 'right',
            disableBeacon: true,
            args: ['payment']
        }
    ];

    const handleStepAction = (args) => {
        console.log('Handling action for args:', args[0]);
        setActiveItem(args[0])
    };


    const modifiedCallback = (data) => {
        callback(data);

        // Call handleStepAction when the step changes
        if (data.action === 'update' || data.action === 'next' || data.action === 'prev') {
            const currentStep = steps[data.index];
            if (currentStep && currentStep.args) {
                handleStepAction(currentStep.args);
            }
        }
    }

    return <Joyride
        steps={steps}
        run={run}
        callback={modifiedCallback}
        continuous={true}
        styles={{
            tooltip: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // centers items vertically in the tooltip
            }
        }} />;
};

export default ParentTour;
