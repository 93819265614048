import React, { useContext, useState, useEffect } from 'react';
import LoggedInContext from '../helpers/LoggedInContext';
import { doc, getDoc, updateDoc, query, getDocs, collection, where } from "firebase/firestore";
import { firestore, auth } from './../../firebase_setup/firebase';
import '../../style/LinkAccount.css';
import ComponentLoadingScreen from '../helpers/ComponentLoadingScreen';
import { useMessage } from '../helpers/MessageContext';
import {motion, easeInOut} from 'framer-motion';

const LinkAccount = ({ activeItem, changeContent }) => {
    const { userRole } = useContext(LoggedInContext);
    const [inputEmail, setInputEmail] = useState("");
    const [linkRequests, setLinkRequests] = useState([]);
    const [linkedAccountNames, setLinkedAccountNames] = useState([]);
    const [componentLoading, setComponentLoading] = useState(true);
    const { newErrorMessage, newSuccessMessage } = useMessage();
    const [isLinkingAccount, setIsLinkingAccount] = useState(false);


    const fetchLinkedAccountNames = async () => {
        const userUID = auth.currentUser.uid;

        let accountUIDs = [];
        if (userRole === "student") {
            const userDoc = await getDoc(doc(firestore, "users", userUID));
            if (userDoc.data().linkedAccount) {
                accountUIDs.push(userDoc.data().linkedAccount);
            }
        } else if (userRole === "parent") {
            console.log('looking for linked account with userUID: ', userUID);
            const requestQuery = query(
                collection(firestore, "users"),
                where("linkedAccount", "==", userUID)
            );
            const requestSnapshot = await getDocs(requestQuery);

            requestSnapshot.docs.forEach(doc => {
                console.log(doc.data())
                if (doc.data().linkedAccount) {
                    accountUIDs.push(doc.id);
                }
            })
        }

        if (accountUIDs) {
            const namesPromises = accountUIDs.map(async uid => {
                console.log('uid');
                console.log(uid);
                const accountDoc = await getDoc(doc(firestore, "users", uid));
                return accountDoc.data().name;
            });

            const names = await Promise.all(namesPromises);
            setLinkedAccountNames(names);
        } else {
            setLinkedAccountNames([]);
        }

    };

    useEffect(() => {
        const userUID = auth.currentUser.uid;

        const fetchLinkRequests = async () => {
            const requestQuery = query(
                collection(firestore, "linkRequests"),
                where("to", "==", userUID),
                where("status", "==", "pending")
            );
            const requestSnapshot = await getDocs(requestQuery);
            if (requestSnapshot.docs && requestSnapshot.docs.length > 0) {
                setLinkRequests(requestSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            }
        };

        try {
            fetchLinkRequests();
    
            fetchLinkedAccountNames();
        } catch {
            newErrorMessage('Error Loading account linking information - please try again later.')
        } finally {
            setComponentLoading(false);
        }



        // TODO this whole logic needs changing, but put this at the end
        // setComponentLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleRequest = async (requestId, request, action) => {
        if (action === "accept") {
            // Handle the logic to link the accounts when accepting a request

            // Here we link the child with the parent
            if (request.fromRole === "student" && request.toRole === "parent") {
                // Update the student's record with the parent's UID
                await updateDoc(doc(firestore, "users", request.from), {
                    linkedAccount: request.to
                });
            }
            // Here we have a request from a parent, so we need to edit the student role
            else if (request.fromRole === "parent" && request.toRole === "student") {
                await updateDoc(doc(firestore, "users", request.to), {
                    linkedAccount: request.from
                });
            }

            // Update the status of the link request to 'accepted'
            await updateDoc(doc(firestore, "linkRequests", requestId), { status: "accepted" });

        } else if (action === "deny") {
            // Update the status of the link request to 'denied'
            await updateDoc(doc(firestore, "linkRequests", requestId), { status: "denied" });
        }

        setLinkRequests(prevRequests => prevRequests.filter(req => req.id !== requestId));
        fetchLinkedAccountNames();
    };


    const handleLinkAccount = async () => {
        setIsLinkingAccount(true);
        const user = auth.currentUser;
    
        if (!user) {
            throw new Error('User not authenticated');
        }
    
        // Get ID token of the user
        const idToken = await user.getIdToken();
    
        // Define your request payload
        const payload = JSON.stringify({
            from: user.uid,
            email: inputEmail
        });
    
        // Define your cloud function endpoint
        const endpoint = process.env.REACT_APP_GENERATE_LINK_REQUEST;
    
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`  // Use Bearer for standard auth format
                },
                body: payload
            });
    
            const data = await response.json();
            if (data.success) {
                newSuccessMessage(`${data.message || 'Request sent successfully.'} Don't forget to check your junk mail!`);
            } else {
                newErrorMessage(data.error || `Error processing request.`);
            }
        } catch (error) {
            console.error("Error calling cloud function:", error);
            newErrorMessage(`Failed to send request.`);
        } finally {
            setIsLinkingAccount(false);
        }
    };

    if (componentLoading) {
        return <ComponentLoadingScreen/>
    }

    return (
        <motion.div
        key='your-prog'
        initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
        >
        <div className='la-card'>
            <h2 className='la-header'>Link Account</h2>

            {/* If no linked accounts, display the link account form */}
            {(linkedAccountNames.length === 0 || userRole === 'parent') && (
                <>
                    {userRole === 'student' ? <p className='la-para'>You must link with a parent before you can access a course. Enter a parents email to link accounts:</p> :  <p className='la-para'>You must link with a student before they can access a course. Enter a students email to link accounts:</p>}
                    <input
                        className='la-input'
                        value={inputEmail}
                        onChange={(e) => setInputEmail(e.target.value)}
                        placeholder="Enter Email"
                    />
                    <button className='la-button' onClick={handleLinkAccount}>{isLinkingAccount ? "Sending..." : "Send Request"}</button>
                    {linkRequests && linkRequests.length > 0 &&
                        <>
                             <h3 className='la-subheader'>Pending Link Requests</h3>
                        {linkRequests.map(request => (
                            <div key={request.id}>
                                <p>Request from {request.fromRole}: {request.fromName}</p>
                                <button className='la-button' onClick={() => handleRequest(request.id, request, "accept")}>Accept</button>
                                <button className='la-button' onClick={() => handleRequest(request.id, request, "deny")}>Deny</button>
                            </div>
                            ))}
                        </>
                    }

                </>
            )}

            {/* Display linked accounts based on user role */}
            {userRole === "student" && linkedAccountNames.length > 0 && (
                <div>
                    <h3 className='la-subheader'>Linked Parent Account</h3>
                    {linkedAccountNames.map(account => <p className='la-para' key={account}>{account}</p>)}
                </div>
            )}

            {userRole === "parent" && linkedAccountNames.length > 0 && (
                <div>
                    <h3 className='la-subheader'>Linked Student Accounts</h3>
                    <div className='la-account-card'>
                        {linkedAccountNames.map(account => <p className='la-para' key={account}>{account}</p>)}
                    </div>
                    
                </div>
            )}

        </div>
        </motion.div>

    );
}

export default LinkAccount;

