import UserCard from "./UserCard";

const AdminSideNav = ({ handleNavItemClick, handleLogout }) => {
    return (
        <div className="student-portal">
            <UserCard onClick={() => handleNavItemClick('account')}/>
            <button onClick={() => handleNavItemClick('studentOverview')}>
                Student Overview
            </button>
            <button onClick={() => handleNavItemClick('editSubjects')}>
                Edit Subjects
            </button>
            {/* TODO make this work using the same flow as the link account flow. This will effectively link accounts to Joel. */}
            <button onClick={() => handleNavItemClick('manageAdmin')}>
                Manage Admin
            </button>
            <button onClick={() => handleNavItemClick('messages')}>
                Messages
            </button>
            <button onClick={() => handleLogout()}>
                Logout
            </button>
        </div>
    );
};

export default AdminSideNav;