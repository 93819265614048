import React, { useState, useEffect } from 'react';
import './../../style/ContactUs.css';
import { firestore, auth } from '../../firebase_setup/firebase';  // Adjust the path
import { addDoc, collection, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { motion, easeInOut } from 'framer-motion';
import ComponentLoadingScreen from '../helpers/ComponentLoadingScreen';
import { useMessage } from '../helpers/MessageContext';

const ContactUs = () => {
    const [message, setMessage] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const [componentLoading, setComponentLoading] = useState(true);
    const { newSuccessMessage, newErrorMessage } = useMessage();
    

    // Subscribe to auth changes and fetch user details
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            try {
                if (user) {
                    const userRef = doc(firestore, 'users', user.uid);
                    const userSnapshot = await getDoc(userRef);
                    if (userSnapshot.exists()) {
                        setUserDetails({
                            email: user.email, // Fetch email from Firebase Auth
                            name: userSnapshot.data().name, // Fetch name from Firestore
                            role: userSnapshot.data().role, // Fetch name from Firestore
                        });
                    }
                }
            } catch {
                newErrorMessage('Issue loading user data. Please refresh page.')
            } finally {
                setComponentLoading(false);
            }
            
        });

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!message || !userDetails) {
            return;
        }

        try {
            await addDoc(collection(firestore, 'messages'), {
                content: message,
                userEmail: userDetails.email,
                userName: userDetails.name,
                userRole: userDetails.role,
                timestamp: serverTimestamp(), // Add server timestamp
            });
            newSuccessMessage('Message sent!');
        } catch (error) {
            console.error("Error adding message: ", error);
            newErrorMessage('Message failed to send. Please try again later, or send us an email.')
            alert('Failed to submit the message');
        }
    };

    if (componentLoading) {
        return <ComponentLoadingScreen/>
    }

    return (
        <motion.div
            key='student-support'
            initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
            style={{ position: 'relative' }}
        >
            <div className="contact-container">
                <form class="form">
                    <div class="title">Contact Us</div>
                    <p>
                        Drop us a message if you need help, and we'll get back to you ASAP. <br /><br />
                        Contact us using the form below or drop us an email at:&nbsp;
                        <a href="mailto:info@ilscoaching.co.uk">info@ilscoaching.co.uk</a>
                    </p>
                    <textarea
                        placeholder="Your message"
                        value={message} // Controlled component
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button onClick={handleSubmit}>Submit</button>
                </form>
            </div>
        </motion.div>
    );
};

export default ContactUs;
