import React, { useContext } from 'react';
import LoggedInContext from '../helpers/LoggedInContext';
import { getAuth, signOut } from 'firebase/auth';

const LogoutComponent = () => {
    const { setIsLoggedIn, setUserRole, setUserUID, setAvatar, setName } = useContext(LoggedInContext);

    const handleLogout = async () => {
        try {
            const auth = getAuth();
            await signOut(auth);
            // Set all of the shared user context here to default values
            setIsLoggedIn(false);
            setUserRole(null);
            setUserUID('');
            setAvatar(null);
            setName(null);
        } catch (error) {
            console.error('Error while logging out:', error);
        }
    };

    // You can include a button or any other elements to trigger the logout
    return (
        <div>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default LogoutComponent;
