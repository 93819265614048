import { easeQuadInOut } from "d3-ease";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import AnimatedProgressProvider from '../helpers/AnimatedProgressProvider';

const ProgressBarsRow = ({ subject, completedLessons, completedWeeks, completedModules }) => {
    return (
        <>
            <div className="progress-bars-row">
                <div className='progress-bar-card-small'>
                    <div className="counter-title">Lessons Completed: </div>
                    <div className='counter-value'><strong>{completedLessons[subject.id]}</strong></div>
                </div>
                <div className='progress-bar-card-small'>
                    <div className="counter-title">Weeks Completed: </div>
                    <div className='counter-value'><strong>{completedWeeks[subject.id]}</strong></div>
                </div>
                <div className='progress-bar-card-small'>
                    <div className="counter-title">Modules Completed: <br /></div>
                    <div className='counter-value'><strong>{completedModules[subject.id]}</strong></div>
                </div>
            </div>
            <div className="progress-bars-row">
                <div className='progress-bar-card'>
                    <div className="progress-bar-container">
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={subject.progress}
                            duration={1.4}
                            easingFunction={easeQuadInOut}
                        >
                            {value => {
                                const roundedValue = isNaN(value) ? 0 : Math.round(value);
                                return (
                                    <CircularProgressbar value={value} text={`${roundedValue}%`} strokeWidth={12.5} styles={buildStyles({
                                        textColor: "#B345FF",
                                        pathColor: "#B345FF",
                                        trailColor: "#C492E6",
                                        strokeLinecap: 'round',
                                        pathTransition: "none"
                                    })} />
                                );
                            }}
                        </AnimatedProgressProvider>
                    </div>
                    <div className="progress-bar-title">Progress</div>
                </div>
                {subject.averageWeekScore === null ? (
                <div className="progress-bar-card main-text">
                    Complete more lessons to see score
                </div>
                ) : (
                <div className='progress-bar-card'>
                    <div className="progress-bar-container">
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={subject.averageWeekScore * 100}
                            duration={1.4}
                            easingFunction={easeQuadInOut}
                        >
                            {value => {
                                const roundedValue = isNaN(value) ? 0 : Math.round(value);
                                return (
                                    <CircularProgressbar value={value} text={`${roundedValue}%`} strokeWidth={12.5} styles={buildStyles({
                                        textColor: "#0C6D2D",
                                        pathColor: "#0C6D2D",
                                        trailColor: "#74D896",
                                        strokeLinecap: 'round',
                                        pathTransition: "none"
                                    })} />
                                );
                            }}
                        </AnimatedProgressProvider>
                    </div>
                    <div className="progress-bar-title">Score</div>
                </div>
                )}
            </div>
        </>

    );
};

export default ProgressBarsRow;