import React, { useEffect, useState } from 'react';
import { doc, getDoc } from "@firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase';
import VideoLesson from '../components/VideoLesson';
import QuizManager from '../components/QuizManager';
import '../../style/LessonManager.css';

const LessonManager = ({subjectId}) => {
    console.log('lessonManager.subjectId: ', subjectId);
    const [currentActivity, setCurrentActivity] = useState({ index: 0, type: '', lessonId: '', name: '', description: '', weekIndex: null, moduleIndex: null });
    const [activities, setActivities] = useState([]);
    const [modulesMetadata, setModulesMetadata] = useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [saved, setSaved] = useState(false);

    // TODO do we need this windowSize?
    console.log(windowSize);

    const traverseLessons = (courseStructure) => {
        let flatActivities = [];
        console.log('courseStructure: ', courseStructure);
        courseStructure.forEach((module, moduleIndex) => {
            module.forEach((week, weekIndex) => {
                week.forEach((lesson, lessonIndex) => {
                    // TODO we cannot pull the name through here.. can we add it to the metadata?
                    flatActivities.push({ type: 'lesson', id: lesson, name: lesson.name, description: lesson.description, moduleIndex: moduleIndex, weekIndex: weekIndex, lessonIndex: lessonIndex });
                    flatActivities.push({ type: 'learningExperience', id: lesson, name: lesson.name, description: lesson.description, moduleIndex: moduleIndex, weekIndex: weekIndex, lessonIndex: lessonIndex });
                });
                flatActivities.push({ type: 'EndOfWeek', id: week[0], name: week.name, description: week.description, weekIndex, moduleIndex });
            });
            flatActivities.push({ type: 'EndOfModule', id: module[0][0], name: module.name, description: module.description, moduleIndex });
        });
        console.log('flatActivities: ', flatActivities);
        return flatActivities;
    };

    useEffect(() => {
        const fetchDataAndProcess = async () => {
            // 1. Fetch subjects
            console.log('subjectId: ', subjectId);
            const subjectDocRef = doc(firestore, "subjects", subjectId);
            const subjectStructure = [];
            let modulesMetadataTemp = [];

            try {
                // Fetch the document
                const docSnapshot = await getDoc(subjectDocRef);

                // Check if the document exists
                if (docSnapshot.exists()) {
                    const subjectData = docSnapshot.data();

                    // subjectData.module_order.forEach(moduleId => {
                    //     let moduleMetadataTemp = {};
                    //     const moduleData = subjectData.modules[moduleId];
                    //     const moduleStructure = [];

                    //     moduleMetadataTemp.name = moduleData.name;
                    //     moduleMetadataTemp.description = moduleData.description;
                    //     moduleMetadataTemp.moduleId = moduleId;

                    //     let weeksMetadataTemp = [];
                    //     moduleData.week_order.forEach(weekId => {
                    //         let weekMetadataTemp = {};
                    //         const weekData = moduleData.weeks[weekId];
                    //         const weekStructure = weekData.lesson_order;

                    //         weekMetadataTemp.name = weekData.name;
                    //         weekMetadataTemp.description = weekData.description;
                    //         weekMetadataTemp.weekId = weekId;

                    //         weeksMetadataTemp.push(weekMetadataTemp);
                    //         moduleStructure.push(weekStructure);
                    //     });

                    //     moduleMetadataTemp.weeks = weeksMetadataTemp;
                    //     modulesMetadataTemp.push(moduleMetadataTemp);
                    //     subjectStructure.push(moduleStructure);
                    // });

                    subjectData.module_order.forEach(moduleId => {
                        let moduleMetadataTemp = {};
                        const moduleData = subjectData.modules[moduleId];
                        const moduleStructure = [];
                    
                        moduleMetadataTemp.name = moduleData.name;
                        moduleMetadataTemp.description = moduleData.description;
                        moduleMetadataTemp.moduleId = moduleId;
                    
                        let weeksMetadataTemp = [];
                        moduleData.week_order.forEach(weekId => {
                            let weekMetadataTemp = {};
                            const weekData = moduleData.weeks[weekId];
                            const weekStructure = [];
                    
                            weekMetadataTemp.name = weekData.name;
                            weekMetadataTemp.description = weekData.description;
                            weekMetadataTemp.weekId = weekId;
                    
                            let lessonsMetadataTemp = [];
                            weekData.lesson_order.forEach(lessonId => {
                                let lessonMetadataTemp = {};
                                const lessonData = weekData.lessons[lessonId];
                    
                                lessonMetadataTemp.name = lessonData.name;
                                lessonMetadataTemp.description = lessonData.description;
                                lessonMetadataTemp.src = lessonData.src;
                                lessonMetadataTemp.lessonId = lessonId;
                    
                                lessonsMetadataTemp.push(lessonMetadataTemp);
                                weekStructure.push(lessonId); // Assuming you want to keep the lesson IDs in weekStructure
                            });
                    
                            weekMetadataTemp.lessons = lessonsMetadataTemp;
                            weeksMetadataTemp.push(weekMetadataTemp);
                            moduleStructure.push(weekStructure);
                        });
                    
                        moduleMetadataTemp.weeks = weeksMetadataTemp;
                        modulesMetadataTemp.push(moduleMetadataTemp);
                        subjectStructure.push(moduleStructure);
                    });
                    

                    console.log(subjectStructure);  // Here's the structure of the current subject
                    console.log(modulesMetadataTemp); // Here's the metadata of the modules for the current subject
                    setModulesMetadata(modulesMetadataTemp);
                } else {
                    console.log("No such subject!");
                }



                // 2. Fetch most recent event
                const user = auth.currentUser;
                let mostRecentEvent = { event_id: null };
                if (user) {
                    const userEventsDoc = doc(firestore, 'user_events', user.uid);
                    const userEventsDocData = await getDoc(userEventsDoc);

                    if (userEventsDocData.exists()) {
                        const userData = userEventsDocData.data();

                        const filteredEvents = Object.values(userData).filter(event => !event.failed);

                        const sortedEvents = filteredEvents
                            .sort((a, b) => b.timestamp?.seconds - a.timestamp?.seconds);
                        if (sortedEvents.length > 0) {
                            mostRecentEvent = sortedEvents[0];
                        }
                    }
                }

                // 3. Now process both fetched data
                const flatActivities = traverseLessons(subjectStructure);
                setActivities(flatActivities);

                let foundActivity = null;
                let foundIndex = 0;

                for (let i = 0; i < flatActivities.length; i++) {
                    const activity = flatActivities[i];
                    if (activity.type === 'learningExperience' && activity.id === mostRecentEvent.event_id) {
                        foundActivity = activity;
                        foundIndex = i;
                        break;
                    } else if (activity.type === 'EndOfWeek' && modulesMetadataTemp[activity.moduleIndex] &&
                        mostRecentEvent.event_id === modulesMetadataTemp[activity.moduleIndex].weeks[activity.weekIndex].weekId) {
                        foundActivity = activity;
                        foundIndex = i;
                        break;
                    } else if (activity.type === 'EndOfModule' && modulesMetadataTemp[activity.moduleIndex] &&
                        mostRecentEvent.event_id === modulesMetadataTemp[activity.moduleIndex].moduleId) {
                        foundActivity = activity;
                        foundIndex = i;
                        break;
                    }
                }

                if (foundActivity) {
                    setCurrentActivity({ index: foundIndex + 1, ...flatActivities[foundIndex + 1] });
                } else {
                    setCurrentActivity({ index: 0, ...flatActivities[0] });
                }
            } catch (error) {
                console.error("Error fetching the subject:", error);
            }
        };

        fetchDataAndProcess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextActivity = () => {
        const nextIndex = currentActivity.index + 1;
        if (nextIndex < activities.length) {
            console.log('setting current activity to:')
            console.log({ index: nextIndex, ...activities[nextIndex] })
            setCurrentActivity({ index: nextIndex, ...activities[nextIndex] });
        } else {
            console.log("Course completed!");
        }
        setSaved(false);
    };

    const handleWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.onresize = () => handleWindowSize();
    }, []);

    const currentActivityType = currentActivity.type;

    console.log('currentActivity.lessonIndex: ', currentActivity.lessonIndex);
    // console.log('modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].lessons[currentActivity.lessonIndex]: ', modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].lessons[currentActivity.lessonIndex]);
    return (
        <div className='le-container'>
            {currentActivityType === 'lesson' && (
                // here we want to access the video attached to the lesson_id
                // we can just use currentActivity.id and retrieve it from the DB
                <>
                    {/* <div>{JSON.stringify(currentActivity)}</div> */}
                    <h1 className='le-header'>{modulesMetadata[currentActivity.moduleIndex].name} - {modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].name}</h1>
                    <h2 className='le-header'>{modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].lessons[currentActivity.lessonIndex].name}</h2>
                    {/* TODO update this to use the url from the lesson object */}
                    <VideoLesson videoUrl={modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].lessons[currentActivity.lessonIndex].src} name={currentActivity.name} handleNextActivity={handleNextActivity} />

                </>
            )}
            {currentActivityType === 'learningExperience' && (
                // here we want to render a quiz, we will need to have a call to generate_learning_experience here
                // it will provide the current lesson, and the rest of the data will be stored in the Leitner system backend
                <>
                    {/* Render the learning experience here */}
                    {/* <div>{JSON.stringify(currentActivity)}</div> */}
                    <h1 className='le-header'>{modulesMetadata[currentActivity.moduleIndex].name} - {modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].name}</h1>
                    <QuizManager subjectId={subjectId} lessonId={currentActivity.id} weekId={modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].weekId} moduleId={modulesMetadata[currentActivity.moduleIndex].moduleId} type='learningExperience' handleNextActivity={handleNextActivity} saved={saved} setSaved={setSaved}></QuizManager>
                </>
            )}
            {currentActivityType === 'EndOfWeek' && (
                <>
                    {/* Render the end-of-week summary and quiz here */}
                    {/* <div>{JSON.stringify(currentActivity)}</div> */}
                    <h1 className='le-header'>{modulesMetadata[currentActivity.moduleIndex].name} - {modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].name}</h1>
                    <h2 className='le-subheader'>End of Week Quiz</h2>
                    <QuizManager subjectId={subjectId} lessonId={currentActivity.id} weekId={modulesMetadata[currentActivity.moduleIndex].weeks[currentActivity.weekIndex].weekId} moduleId={modulesMetadata[currentActivity.moduleIndex].moduleId} type='endOfWeek' handleNextActivity={handleNextActivity} saved={saved} setSaved={setSaved}></QuizManager>
                </>
            )}
            {currentActivityType === 'EndOfModule' && (
                <>
                    {/* Render the end-of-module summary and quiz here */}
                    {/* <div>{JSON.stringify(currentActivity)}</div> */}
                    <h1 className='le-header'>{modulesMetadata[currentActivity.moduleIndex].name}</h1>
                    <h2 className='le-subheader'>End of Module Quiz</h2>
                    <QuizManager subjectId={subjectId} lessonId={currentActivity.id} weekId={null} moduleId={modulesMetadata[currentActivity.moduleIndex].moduleId} type='endOfModule' handleNextActivity={handleNextActivity} saved={saved} setSaved={setSaved}></QuizManager>
                </>
            )}
        </div>
    );
};

export default LessonManager;
