import React, { useEffect, useState } from 'react';
import './../../style/Subjects.css';
import Subject from './subject/Subject';
import ComponentLoadingScreen from '../helpers/ComponentLoadingScreen';

import { collection, getDocs, query, where } from "@firebase/firestore";
import { firestore, auth } from '../../firebase_setup/firebase';

import { easeInOut, motion } from 'framer-motion';

const Subjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);

  const docToJSON = (doc) => {
    // function to convert docs from firestore to JSON
    if (!doc.exists()) return null;

    const data = doc.data();
    return {
      [doc.id]: data,
    };
  };

  useEffect(() => {
    // on component mount, we pull in the subject data into our app
    const fetchSubjectsAndSubscriptions = async () => {
      try {
        // Fetching subjects
        const querySnapshot = await getDocs(collection(firestore, "subjects"));
        let subjectsList = [];

        querySnapshot.forEach((doc) => {
          const subjectJSON = docToJSON(doc);
          if (subjectJSON) {
            subjectsList.push(subjectJSON);
          }
        });

        // Fetching active subscriptions
        const userUID = auth.currentUser?.uid;
        const subscriptionsQuery = query(
          collection(firestore, 'subscriptions'),
          where('student_id', '==', userUID),
          where('active', '==', true)
        );
        const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
        const activeSubscriptionsSet = new Set(subscriptionsSnapshot.docs.map(doc => doc.data().subject_id));
        // Adding 'subscribed' flag to subjects
        subjectsList = subjectsList.map(subject => {
          const subjectKey = Object.keys(subject)[0];  // Get the unique subject key
          return {
            [subjectKey]: {
              ...subject[subjectKey],
              subscribed: activeSubscriptionsSet.has(subjectKey)
            }
          };
        });


        setSubjects(subjectsList);
      } catch (error) {
        console.error('Error combining subject data and subscription status: ', error);
      } finally {
        setComponentLoading(false);
      }
    };

    fetchSubjectsAndSubscriptions();
  }, []);
  console.log('subjects:', subjects);

  if (componentLoading) {
    return (<ComponentLoadingScreen />)
  }

  // TODO we need to collapse all of child elements when we collapse the parent element
  // or we end up with some weird stuff going on
  return (
    <motion.div
      key='subjects'
      initial={{ opacity: 0, y: -50, transition: { duration: 2, ease: easeInOut } }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50, transition: { duration: 0.1, ease: easeInOut } }}
      style={{ position: 'relative' }}
    >
      <div>
        <main>
          <section >
            <h1 className='main-header'>Our Subjects</h1>
            {subjects && (
              <div>
                {Object.entries(subjects).map(([id, subject]) => (
                  <Subject key={id} subject={subject} subscribed={subject} />
                ))}
              </div>
            )}
          </section>
        </main>
      </div>
    </motion.div>
  );
};

export default Subjects;
