// import { maxHeight } from '@mui/system';
// import { React, useRef, useState, useEffect } from 'react';
// import Lesson from './Lesson';


// const StandaloneWeek = ({ week }) => {
//     const [contentHeight, setContentHeight] = useState(0);
//     const contentRef = useRef(null);
//     const [expandedLessons, setExpandedLessons] = useState({});

//     // const updateHeight = (lessonId, height) => {
//     //     console.log('updating lesson ', lessonId, ' to height of ', height);
//     //     lessonHeights.current[lessonId] = height; // Update the height for the specific lesson

//     //     // Sum up the heights of all lessons
//     //     const totalHeight = Object.values(lessonHeights.current).reduce((sum, h) => sum + h, 0);

//     //     console.log('changing total height from ', contentHeight, ' to ', totalHeight);

//     //     // Update the state with the new total height
//     //     setContentHeight(totalHeight);
//     // };

//     const updateHeight = (height) => {
//         console.log('lesson height is now ', height);
//         console.log('content height is incresing from  ', contentHeight, ' to ', contentHeight + height);
//         const newContentHeight = contentHeight + height;
        
//         setContentHeight(newContentHeight);
//     };

//     const toggleLesson = (lessonId) => {
//         console.log('lesson toggled: ', lessonId);
//         setExpandedLessons((prevExpandedLessons) => ({
//             [lessonId]: !prevExpandedLessons[lessonId],
//         }));
//     };

//     useEffect(() => {
//         // Update the content height state
//         setContentHeight(contentRef.current ? contentRef.current.scrollHeight : 0);
//     }, [week, contentRef, expandedLessons]); // Dependencies

//     console.log('contentHeight: ', contentHeight);
//     return (
//         <li className='standalone-week-container'>
//             <button className="week-header">
//                 {week.name}
//             </button>
//                 <div
//                     className={`week-description expanded`}
//                     style={{ maxHeight: contentHeight }}
//                     ref={contentRef}
//                 >
//                     {week.description}
//                     <ul className={`module-content expanded`}>
//                         {Object.entries(week.lessons) && Object.entries(week.lessons).map((lesson) => (
//                             <Lesson
//                                 key={lesson[1].name}
//                                 lesson={lesson}
//                                 expanded={expandedLessons[lesson[1].name]}
//                                 toggleLesson={() => toggleLesson(lesson[1].name)}
//                                 propagateHeight={updateHeight}
//                                 subscribed={true}
//                             />
//                         ))}
//                     </ul>
//                 </div>
//         </li>
//     );
// };

// export default StandaloneWeek;

import React, { useState, useRef, useEffect } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Lesson from './Lesson';

const StandaloneWeek = ({ week }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const [expandedLessons, setExpandedLessons] = useState({});

    const toggleLesson = (lessonId) => {
        console.log(lessonId);
        setExpandedLessons((prevExpandedLessons) => ({
            ...prevExpandedLessons,
            [lessonId]: !prevExpandedLessons[lessonId],
        }));
    };

    useEffect(() => {
        if (!isExpanded) {
            setExpandedLessons({});
        }
        const height = isExpanded ? contentRef.current.scrollHeight : 0;
        setContentHeight(height);
    }, [isExpanded]);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const updateHeight = (height) => {
        const newContentHeight = contentHeight + height;
        setContentHeight(newContentHeight);
    };

    return (
        <li className='week-container standalone-week-container'>
            <button className="week-header" onClick={handleToggle}>
                {week.name}
                {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </button>
            {isExpanded &&
                <div
                    className="week-description expanded"
                    style={{ maxHeight: contentHeight }}
                    ref={contentRef}
                >
                    {week.description}
                    <ul className="module-content expanded">
                        {Object.entries(week.lessons) && Object.entries(week.lessons).map((lesson) => (
                            <Lesson
                                key={lesson[0]}
                                lesson={lesson}
                                expanded={expandedLessons[lesson[0]]}
                                toggleLesson={() => toggleLesson(lesson[0])}
                                propagateHeight={updateHeight}
                                subscribed={true}
                            />
                        ))}
                    </ul>
                </div>}
        </li>
    );
};

export default StandaloneWeek;
