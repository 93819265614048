
import { React, useEffect, useState } from 'react';
import EditModule from './EditModule.js';
import { v4 as uuidv4 } from 'uuid';
import { collection, query, where, limit, orderBy, getDocs } from "@firebase/firestore";
import { firestore, auth } from '../../../firebase_setup/firebase';
import { useMessage } from '../../helpers/MessageContext';

const EditSubject = ({ subject, updateSubjects, changeSubjectValue }) => {
    const [expandedModules, setExpandedModules] = useState({});
    const [editingModules, setEditingModules] = useState({});
    const [subjectState, setSubjectState] = useState({
        subject: subject,
        modules: subject[Object.keys(subject)[0]].modules,
        isEditing: false,
        editedSubject: subject,
        module_order: subject[Object.keys(subject)[0]].module_order
    });

    const { newSuccessMessage, newErrorMessage } = useMessage();

    const [price, setPrice] = useState(null);

    const upsertSubjectPlan = async (subjectId, subjectName, price = 2500) => {
        const token = await auth.currentUser.getIdToken(true);  // Assumes user is already authenticated
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': token 
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                subjectId: subjectId,
                subjectName: subjectName,
                amount: price
            })
        };
    
        try {
            const response = await fetch(process.env.REACT_APP_UPSERT_PRODUCT_PLAN_ENDPOINT, requestOptions);
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.error || "Unknown error occurred");
            }
            return data;  // Process the returned data as needed
        } catch (error) {
            console.error("Error calling upsertProductPlan:", error);
            throw error;
        }
    }

    useEffect(() => {
        // on component mount, we pull in the subject data into our app
        async function fetchSubjectPlan(subjectId) {
            // const snapshot = await collection(firestore, 'subject_plans')
            //     .where('subject_id', '==', subject_id)
            //     .orderBy('timestamp', 'desc')
            //     .limit(1)
            //     .get();

            const q = query(
                collection(firestore, "subject_plans"),
                where("subject_id", "==", subjectId),
                orderBy('timestamp', 'desc'),
                limit(1)
              );

            const querySnapshot = await getDocs(q);

            const docs = [];
            querySnapshot.forEach(doc => {
                docs.push({
                    id: doc.id,
                    ...doc.data()
                });
            });

            return docs[0]; // Since we're limiting the query to 1, we can just return the first item.
        }



        // Another asynchronous function to handle the call and state setting
        async function loadSubjectPlan() {
            const subject_id = Object.keys(subject)[0];
            const plan = await fetchSubjectPlan(subject_id);
            // if plan is undefined, we want to create a plan and then rerun!
            // this may seem illogical, but this code will only be triggered once!
            if (plan === undefined) {
                await upsertSubjectPlan(Object.keys(subject)[0], Object.values(subject)[0].name);
            }
            setPrice(plan.amount);
        }

        loadSubjectPlan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleEditClick = () => {
        // when edit button is clicked, set editing to true, and copy subject into editedSubject
        setSubjectState({
            ...subjectState,
            isEditing: true,
            editedSubject: subjectState.subject
        });

    };

    const changeModuleValue = (updatedValue) => {
        // update module value from child
        const newModules = { ...subjectState.modules };
        newModules[updatedValue[0]] = updatedValue[1];
        setSubjectState({
            ...subjectState,
            modules: newModules
        });
    };

    const deleteModule = (moduleId) => {
        const newModules = { ...subjectState.modules };
        const newModuleOrder = subjectState.module_order;

        if (window.confirm(`Are you sure you want to delete this module? \n ${newModules[moduleId].name}. \n This action cannot be undone, and will delete all weeks AND lessons inside the module.`)) {
            delete newModules[moduleId]; // This will remove the module from the copied object.

            var index = newModuleOrder.indexOf(moduleId);
            if (index > -1) {
                newModuleOrder.splice(index, 1); // Removes the item at index
            }

            setSubjectState(prevState => ({
                ...prevState,
                modules: newModules,
                module_order: newModuleOrder
            }));
            newSuccessMessage('Module deleted successfully.');
        } else {
            // If the user cancels, do nothing
            newErrorMessage('Module deletion cancelled.');
        }
    }

    const handleSubjectSubmit = () => {
        // update subject from editedSubject obj and turn editing off
        changeSubjectValue(subjectState.editedSubject);
        upsertSubjectPlan(Object.keys(subjectState.editedSubject)[0], Object.values(subjectState.editedSubject)[0].name, price);
        setSubjectState({
            ...subjectState,
            isEditing: false
        });
    };

    // this handles the changes being passed down into the object, when we add a new subject
    useEffect(() => {
        setSubjectState({
            ...subjectState,
            modules: subject[Object.keys(subject)[0]].modules,
            module_order: subject[Object.keys(subject)[0]].module_order,
            editedSubject: subject,
            subject: subject
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject]);

    // the subject is being changed to undefined
    // we have somehow got the week_order being changed to undefined, and back to it's correct value endlessly
    // this is what is causing the endless loop

    useEffect(() => {
        let newSubject = JSON.parse(JSON.stringify(subjectState.subject)); // here we need to make a deep copy
        newSubject[Object.keys(subjectState.subject)[0]].modules = subjectState.modules;
        newSubject[Object.keys(subjectState.subject)[0]].module_order = subjectState.module_order;
        // if changes then propagate them
        if (JSON.stringify(newSubject) !== JSON.stringify(subjectState.subject)) {
            updateSubjects(Object.keys(newSubject)[0], newSubject[Object.keys(newSubject)[0]]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjectState.modules, subjectState.module_order, subjectState.subject]);

    const toggleModuleEdit = (moduleId) => {
        setEditingModules((prevExpandedModules) => ({
            ...prevExpandedModules,
            [moduleId]: !prevExpandedModules[moduleId],
        }));
    };

    // TODO make this toggle module into a smooth animation?
    const toggleModule = (moduleId) => {
        // sets the expanded modules
        setExpandedModules((prevExpandedModules) => ({
            [moduleId]: !prevExpandedModules[moduleId],
        }));
    };

    const addNewModule = () => {
        const moduleId = uuidv4();
        const weekId = uuidv4();
        const lessonId = uuidv4();
        const newModule = {
            [moduleId]: { description: 'a new module', name: 'new module', week_order: [weekId], weeks: { [weekId]: { description: 'a new week', name: 'new week', lesson_order: [lessonId], lessons: { [lessonId]: { description: 'a new lesson', name: 'new lesson', src: '' } } } } }
        };
        setSubjectState({
            ...subjectState,
            modules: { ...newModule, ...subjectState.modules },
            module_order: [...subjectState.module_order, moduleId],
        });
    }

    if (subject && !subjectState.isEditing) {
        return (
            <div className="subject-container">
                <div className='subject-sub-container'>
                    <h2 className='subject-header'>{subject[Object.keys(subject)[0]].name}</h2>
                    <button className="module-edit" onClick={handleEditClick}>Edit</button>
                </div>
                <div className='subject-description'>Price: £{price/100}/month</div>
                <div>
                    <p className='subject-description'>{subject[Object.keys(subject)[0]].description}</p>
                </div>
                <ul>
                    {Object.entries(subject[Object.keys(subject)[0]].modules) &&
                        subjectState.module_order.map((moduleId) => (
                            <EditModule
                                key={moduleId}
                                module={Object.entries({ [moduleId]: subjectState.modules[moduleId] })[0]}
                                expanded={expandedModules[moduleId]}
                                editing={editingModules[moduleId]}
                                toggleModule={() => toggleModule(moduleId)}
                                toggleModuleEdit={() => toggleModuleEdit(moduleId)}
                                changeModuleValue={changeModuleValue}
                                deleteModule={deleteModule}
                                subjectId={Object.keys(subject)[0]}
                            />
                        ))}
                </ul>
                <button className='add-subject' onClick={addNewModule}>Add new module</button>
            </div>
        );
    } else if (subject && subjectState.isEditing) {
        return (
            <div className="subject-container">
                <div className='subject-sub-container'>
                    <input
                        className='subject-input'
                        type="text"
                        placeholder={subjectState.editedSubject[Object.keys(subjectState.editedSubject)[0]].name}
                        onChange={(e) => setSubjectState({
                            ...subjectState,
                            editedSubject: {
                                [Object.keys(subjectState.editedSubject)[0]]: {
                                    ...subjectState.editedSubject[Object.keys(subjectState.editedSubject)[0]],
                                    name: e.target.value
                                }
                            }
                        })}
                        disabled={!subjectState.isEditing} // Disable the input field when not in editing mode
                    />
                    <button className="module-edit" onClick={handleSubjectSubmit}>Save</button>
                </div>
                <div>
                    <input
                        className='subject-input'
                        type="text"
                        placeholder={price/100}
                        onChange={(e) => {
                            // some magic to check if number, and round to 2DP
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value)) {
                              setPrice(Math.round(value * 100 * 100) / 100);
                            } else {
                                newErrorMessage('Cannot set price to a non-number.')
                            }
                          }}
                        disabled={!subjectState.isEditing} // Disable the input field when not in editing mode
                    />
                </div>
                <div>
                    <input
                        className='subject-input'
                        type="text"
                        placeholder={subjectState.editedSubject[Object.keys(subjectState.editedSubject)[0]].description}
                        onChange={(e) => setSubjectState({
                            ...subjectState,
                            editedSubject: {
                                [Object.keys(subjectState.editedSubject)[0]]: {
                                    ...subjectState.editedSubject[Object.keys(subjectState.editedSubject)[0]],
                                    description: e.target.value
                                }
                            }
                        })}
                        disabled={!subjectState.isEditing} // Disable the input field when not in editing mode
                    />
                </div>
                <ul>
                    {Object.entries(subject[Object.keys(subject)[0]].modules) &&
                        subjectState.module_order.map((moduleId) => (
                            <EditModule
                                key={moduleId}
                                module={Object.entries({ [moduleId]: subjectState.modules[moduleId] })[0]}
                                expanded={expandedModules[moduleId]}
                                editing={editingModules[moduleId]}
                                toggleModule={() => toggleModule(moduleId)}
                                toggleModuleEdit={() => toggleModuleEdit(moduleId)}
                                changeModuleValue={changeModuleValue}
                                deleteModule={deleteModule}
                                subjectId={Object.keys(subject)[0]}
                            />
                        ))}
                </ul>
                <button className='add-subject' onClick={addNewModule}>Add new module</button>
            </div>
        );
    }
    else {
        // return error message if else triggered
        return <div>Error occured, please contact support.</div>
    }

};

export default EditSubject;